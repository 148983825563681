import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_UpdateInsightsLastViewedTimestamp as UpdateIdeaInsights,
	jira_polaris_UpdateInsightsLastViewedTimestampVariables as UpdateIdeaInsightsVariables,
} from './__generated_apollo__/jira_polaris_UpdateInsightsLastViewedTimestamp';
import type { UpdateIdeaRequest } from './types';

const UPDATE_IDEA_INSIGHTS_TIMESTAMP = gqlTagPolaris`
mutation jira_polaris_UpdateInsightsLastViewedTimestamp(
    $projectAri: ID!
    $issueAri: ID!
    $lastInsightsViewedTimestamp: String
  ) {
    updatePolarisIdea(
      project: $projectAri
      idea: $issueAri
      update: {
        lastInsightsViewedTimestamp: $lastInsightsViewedTimestamp
      }
    ) {
      success
      errors {
        message
      }
      node {
        id
      }
    }
  }
`;

export const updateIssueInsightsLastViewed = async (
	apolloClient: PolarisApolloClient,
	request: UpdateIdeaRequest,
): Promise<boolean> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId: request.cloudId,
		resourceType: 'project',
		resourceId: request.projectId,
	});
	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId: request.cloudId,
		resourceType: 'issue',
		resourceId: request.ideaId,
	});

	const result = await apolloClient.mutate<UpdateIdeaInsights, UpdateIdeaInsightsVariables>({
		mutation: UPDATE_IDEA_INSIGHTS_TIMESTAMP,
		variables: {
			projectAri,
			issueAri,
			lastInsightsViewedTimestamp: request.lastInsightsViewedTimestamp,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.update-idea-comments-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
	return true;
};
