import { useCallback } from 'react';
import { useJiraIssueIdByLocalIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';

export const useMoveIssue = (localIssueId: string) => {
	const issueId = useJiraIssueIdByLocalIssueId(localIssueId);
	const href = `/secure/MoveIssue!default.jspa?id=${issueId || ''}`;

	return useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(href);
	}, [href]);
};
