import React from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort,
	useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort,
} from '@atlassian/jira-polaris-common/src/controllers/idea-ranking';
import messages from './messages';

type FieldSortContentProps = {
	disabledButtonMessage?: string;
	disabledButtonMessageWithCanEditIssues?: string;
};

type ConvertToProjectRankItemProps = {
	disabledButtonMessageWithCanEditIssues?: string;
};

type ConvertToViewRankItemProps = FieldSortContentProps;

export const ConvertToViewRankItem = ({ disabledButtonMessage }: ConvertToViewRankItemProps) => {
	const setSortModeOfCurrentViewToViewRankAndAddCurrentSort =
		useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort();

	const { formatMessage } = useIntl();
	return (
		<Tooltip content={disabledButtonMessage}>
			<DropdownItem
				testId="polaris-ideas.ui.right-sidebar.sort.advanced.field-sort.convert-to-view-rank"
				description={
					<Box xcss={descriptionStyles}>{formatMessage(messages.convertToViewRankDescription)}</Box>
				}
				isDisabled={!!disabledButtonMessage}
				onClick={setSortModeOfCurrentViewToViewRankAndAddCurrentSort}
			>
				{formatMessage(messages.convertToViewRank)}
			</DropdownItem>
		</Tooltip>
	);
};

export const ConvertToProjectRankItem = ({
	disabledButtonMessageWithCanEditIssues,
}: ConvertToProjectRankItemProps) => {
	const setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort =
		useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort();

	const { formatMessage } = useIntl();
	return (
		<Tooltip content={disabledButtonMessageWithCanEditIssues}>
			<DropdownItem
				testId="polaris-ideas.ui.right-sidebar.sort.advanced.field-sort.convert-to-project-rank"
				description={
					<Box xcss={descriptionStyles}>
						{formatMessage(messages.convertToProjectRankDescription)}
					</Box>
				}
				isDisabled={!!disabledButtonMessageWithCanEditIssues}
				onClick={setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort}
			>
				{formatMessage(messages.convertToProjectRank)}
			</DropdownItem>
		</Tooltip>
	);
};

export const FieldSortContent = ({
	disabledButtonMessage,
	disabledButtonMessageWithCanEditIssues,
}: FieldSortContentProps) => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlockEnd="space.300">
			<Box paddingBlockStart="space.075" paddingBlockEnd="space.150">
				<Text>{formatMessage(messages.fieldSortDescription)}</Text>
			</Box>
			<Flex alignItems="center">
				<DropdownMenu
					testId="polaris-ideas.ui.right-sidebar.sort.advanced.field-sort.change-to-ranked-mode-trigger"
					trigger={formatMessage(messages.changeToRankedModeTrigger)}
				>
					<Box xcss={dropdownMenuWrapperStyles}>
						<DropdownItemGroup>
							<ConvertToViewRankItem disabledButtonMessage={disabledButtonMessage} />
							<ConvertToProjectRankItem
								disabledButtonMessageWithCanEditIssues={disabledButtonMessageWithCanEditIssues}
							/>
						</DropdownItemGroup>
					</Box>
				</DropdownMenu>
				<Box paddingInlineStart="space.050">
					<Tooltip content={formatMessage(messages.changeToRankedModeTooltip)}>
						<EditorInfoIcon label="info" />
					</Tooltip>
				</Box>
			</Flex>
		</Box>
	);
};

const descriptionStyles = xcss({
	whiteSpace: 'normal',
});

const dropdownMenuWrapperStyles = xcss({
	maxWidth: '320px',
});
