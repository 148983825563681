import React, { memo } from 'react';
import { ButtonItem, type CSSFn } from '@atlaskit/menu';
import { Inline, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui';
import { messages as commonMessages } from '../../../../../common/messages';
import { FieldTitleWithIcon } from '../../../../../common/ui/field-title-with-icon';
import { TooltipComponent } from '../../../../../common/ui/rollup-tooltip';
import {
	RollupItemFormulaHelpTextComponent,
	RollupItemFormulaTypeComponent,
} from '../../../../../common/ui/rollup/field-help-text';
import { RollupItemFieldIconComponent } from '../../../../../common/ui/rollup/field-icon';
import { fieldTypeNameForPolarisFieldType } from '../../../../../common/utils/field-type-name/index.tsx';
import {
	useFieldEmoji,
	useFieldType,
	useFieldTypeIcon,
	useHasDefaultFieldOptionWeights,
} from '../../../../../controllers/selectors/formula-hooks';
import { messages } from './messages';
import type { FieldOptionsProps, NumericalFieldProps } from './types';

const cssFn: CSSFn = (currentStyles) => ({
	...currentStyles,
	padding: `${token('space.050', '4px')} ${token('space.200', '16px')}`,
});

const NumericalField = memo<NumericalFieldProps>(({ field, onClick }: NumericalFieldProps) => {
	const { formatMessage } = useIntl();

	const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(field.key);

	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);
	const icon = useFieldTypeIcon(field.key);

	const fieldType = useFieldType(field.key);
	const fieldTypeName = fieldTypeNameForPolarisFieldType(fieldType);
	const title = formatMessage(commonMessages.fieldTypeLabel, {
		fieldTypeName: fieldTypeName ? formatMessage(fieldTypeName) : '',
	});

	return (
		<TooltipComponent
			key={field.key}
			header={<FieldTitleWithIcon emoji={emoji} icon={icon} title={title} />}
			content={
				<RollupItemFormulaHelpTextComponent
					fieldKey={field.key}
					fieldType={field.type}
					helpText={formatMessage(messages.valueTypeHelpText)}
					hasDefaultFieldOptionWeights={hasDefaultFieldOptionWeights}
				/>
			}
		>
			<ButtonItem
				// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
				cssFn={cssFn}
				key={field.key}
				testId="polaris-component-field-configuration.ui.configuration.formula.rollup.field-options.field-option"
				iconBefore={<RollupItemFieldIconComponent emoji={emoji} fieldTypeIcon={icon} />}
				iconAfter={
					<RollupItemFormulaTypeComponent
						fieldKey={field.key}
						typeName={formatMessage(commonMessages.valueType)}
						hasDefaultFieldOptionWeights={hasDefaultFieldOptionWeights}
					/>
				}
				onClick={() => {
					onClick({
						kind: 'field',
						value: field,
					});
				}}
			>
				<Inline>
					<Text>{field.label}</Text>
					{field.global && <GlobalFieldMarker />}
				</Inline>
			</ButtonItem>
		</TooltipComponent>
	);
});

export const FieldOptions = memo<FieldOptionsProps>(
	({ fields, onClick, isFiltered }: FieldOptionsProps) => {
		const numericalFields = fields.filter(
			({ type, label }) =>
				(type === FIELD_TYPES.VOTES ||
					type === FIELD_TYPES.RATING ||
					type === FIELD_TYPES.SLIDER ||
					type === FIELD_TYPES.INSIGHTS ||
					type === FIELD_TYPES.NUMBER ||
					type === FIELD_TYPES.CHECKBOX ||
					type === FIELD_TYPES.FORMULA ||
					type === FIELD_TYPES.LINKED_ISSUES) &&
				isFiltered(label),
		);
		if (numericalFields.length === 0) return null;
		return (
			<>
				{numericalFields.map((field) => (
					<NumericalField key={field.key} field={field} onClick={onClick} />
				))}
			</>
		);
	},
);
