import React, { useCallback, useState } from 'react';
import { LoadingButton } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { Flex, Stack, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import {
	useAvailableGlobalFields,
	useGlobalFieldData,
	useGlobalFieldFormula,
} from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks.tsx';
import { FormulaContent } from '@atlassian/jira-polaris-common/src/ui/config/fields/field-item/formula-content/index.tsx';
import { FieldConfiguration } from '@atlassian/jira-polaris-component-field-configuration/src/main.tsx';
import { FormulaDependencyFooterNotice } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/dependency-info/footer/index.tsx';
import { getFormulaDependencies } from '@atlassian/jira-polaris-domain-field/src/global-field/utils.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { RightSidebarBody } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/body/index.tsx';
import { RightSidebarFooter } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/footer/index.tsx';
import { RightSidebarHeader } from '@atlassian/jira-polaris-lib-right-sidebar/src/ui/header/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	fieldKey: string;
	onBack: () => void;
	onClose: () => void;
};

export const GlobalFieldDetails = ({ fieldKey, onBack, onClose }: Props) => {
	const { formatMessage } = useIntl();
	const [addingInProgress, setAddingInProgress] = useState<boolean>(false);
	const { associateGlobalFields, setHighlightedFields } = useFieldActions();
	const { error } = useNotifications();
	const globalFields = useAvailableGlobalFields();

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			onBack();
		},
	});

	const fieldData = useGlobalFieldData(fieldKey);
	const formula = useGlobalFieldFormula(fieldKey);

	const addToProject = useCallback(
		async (_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'addToProject');
			setAddingInProgress(true);
			const dependencies = getFormulaDependencies(fieldKey, globalFields);

			try {
				await associateGlobalFields([fieldKey], dependencies);
				setHighlightedFields([fieldKey]);

				onClose();
			} catch (err) {
				setAddingInProgress(false);
				error({
					title: formatMessage(messages.addErrorTitle),
					description: formatMessage(messages.addErrorDescription),
				});
			}
		},
		[
			fieldKey,
			globalFields,
			associateGlobalFields,
			setHighlightedFields,
			onClose,
			error,
			formatMessage,
		],
	);

	if (!fieldData) {
		return null;
	}

	const displayFormulaContent = Boolean(formula);
	const displayDependencyNotice = Boolean(formula);

	return (
		<Stack xcss={sidebarContainerStyles}>
			<RightSidebarHeader
				title={formatMessage(messages.sidebarHeader)}
				onClose={onClose}
				onBack={onBack}
				backLabel={formatMessage(messages.sidebarBackLabel)}
			/>
			<RightSidebarBody>
				<Flex
					direction="column"
					xcss={[fieldConfigStyles, displayFormulaContent && formulaConfigStyles]}
				>
					<FieldConfiguration
						fieldData={fieldData}
						canEditField
						readonly
						formulaContent={
							displayFormulaContent && (
								<FormulaContent fieldKey={fieldKey} formula={formula} readonly isPreview />
							)
						}
					/>
				</Flex>
			</RightSidebarBody>
			<RightSidebarFooter>
				<Stack space="space.100">
					{displayDependencyNotice && <FormulaDependencyFooterNotice />}
					{fg('polaris_global_fields_button_migration') ? (
						<Button
							testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-details.add-field"
							isLoading={addingInProgress}
							appearance="primary"
							shouldFitContainer
							onClick={addToProject}
						>
							{formatMessage(messages.addField)}
						</Button>
					) : (
						<LoadingButton
							testId="polaris-ideas.ui.right-sidebar.global-fields.global-field-details.add-field"
							isLoading={addingInProgress}
							appearance="primary"
							shouldFitContainer
							onClick={addToProject}
						>
							{formatMessage(messages.addField)}
						</LoadingButton>
					)}
				</Stack>
			</RightSidebarFooter>
		</Stack>
	);
};

const sidebarContainerStyles = xcss({
	height: '100%',
	paddingBlockStart: 'space.300',
});

const fieldConfigStyles = xcss({
	flexGrow: 1,
	overflowY: 'auto',
});

const formulaConfigStyles = xcss({
	overflowY: 'unset',
});
