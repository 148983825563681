import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	createPlayContributionCollectionRemote,
	createPlayContributionRemote,
	createPlayContributionSharingRemote,
} from './controllers';
import type { PlayContributionRemote } from './controllers/play-contribution/types.tsx';

const { RemoteContextProvider, useRemoteFromContext } =
	createRemoteContext<PlayContributionRemote>('play-contribution');

export const createPlayContributionProvider =
	(projectId: ProjectId): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const playContributionRemote = useMemo(
			() => createPlayContributionRemote(apolloClient, createAnalyticsEvent, cloudId, projectId),
			[cloudId, apolloClient, createAnalyticsEvent],
		);

		return (
			<RemoteContextProvider remote={playContributionRemote}>{children}</RemoteContextProvider>
		);
	};

export const createPlayContributionSharingProvider =
	(): ChainableContextProvider =>
	({ children }) => {
		const playContributionRemote = useMemo(() => createPlayContributionSharingRemote(), []);

		return (
			<RemoteContextProvider remote={playContributionRemote}>{children}</RemoteContextProvider>
		);
	};

export const createPlayContributionCollectionProvider =
	(): ChainableContextProvider =>
	({ children }) => {
		const playContributionRemote = useMemo(() => createPlayContributionCollectionRemote(), []);

		return (
			<RemoteContextProvider remote={playContributionRemote}>{children}</RemoteContextProvider>
		);
	};

export const usePlayContributionRemote = useRemoteFromContext;
