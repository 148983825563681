import { useState, useMemo, useEffect } from 'react';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { COUNT_ROLLUP, PROPERTY_ROLLUP } from '../../../constants';
import { conjunctiveLabelFilter } from '../conjuctive-label-filter';
import { useRollupFormulaParser, emptyState } from '../formula-parser';
import type { RollupFormulaOperations, RollupFormulaStateExt } from './types';

const createFormulaState = (
	rollupFormulaParserFn: ReturnType<typeof useRollupFormulaParser>,
	formula?: DynamicFieldFormula,
	isInitAsWeightedScore?: boolean,
) =>
	formula
		? { ...rollupFormulaParserFn(formula), isUpdated: false }
		: { ...emptyState(isInitAsWeightedScore === true), isUpdated: false };

export const useFormulaState = (
	snippetProviders: SnippetProvider[],
	fieldsByKey: FieldsByKey,
	formula?: DynamicFieldFormula,
	initAsWeightedScore?: boolean,
): [RollupFormulaStateExt, RollupFormulaOperations] => {
	const rollupFormulaParser = useRollupFormulaParser(snippetProviders, fieldsByKey);

	const [state, setState] = useState(
		createFormulaState(rollupFormulaParser, formula, initAsWeightedScore),
	);

	useEffect(() => {
		setState(createFormulaState(rollupFormulaParser, formula, initAsWeightedScore));
	}, [rollupFormulaParser, formula, initAsWeightedScore]);

	return useMemo(
		() => [
			state,
			{
				addField: (rollupField) =>
					setState({
						rollupFields: [...state.rollupFields, rollupField],
						percentages: [...state.percentages, undefined],
						weightedScore: state.weightedScore,
						isUpdated: true,
					}),
				removeField: (index) =>
					setState({
						rollupFields: state.rollupFields.filter((_, i) => index !== i),
						percentages: state.percentages.filter((_, i) => index !== i),
						weightedScore: state.weightedScore,
						isUpdated: true,
					}),
				setPercentageValue: (index, value) => {
					state.percentages.splice(index, 1, value);
					setState({
						...state,
						percentages: [...state.percentages],
						isUpdated: true,
					});
				},
				setLabelFilter: (index, values) => {
					const rollupField = state.rollupFields[index];
					if (
						rollupField !== undefined &&
						(rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP)
					) {
						const newRollupFields = [...state.rollupFields];
						// @ts-expect-error - TS2345 - Argument of type '{ value: { filter: Conjunction; id: string; key: string; label: string; kind: string; appName: string; oauthClientId: string; avatarUrl: string; } | { filter: Conjunction; ... 4 more ...; avatarUrl?: string | undefined; }; kind: "property"; } | { ...; }' is not assignable to parameter of type 'RollupField'.
						newRollupFields.splice(index, 1, {
							...rollupField,
							value: {
								...rollupField.value,
								filter: conjunctiveLabelFilter(values || []),
							},
						});
						setState({
							...state,
							rollupFields: newRollupFields,
							isUpdated: true,
						});
					}
				},
				setWeightedScore: (weightedScore) =>
					setState({
						...state,
						weightedScore,
						isUpdated: true,
					}),
			},
		],
		[state],
	);
};
