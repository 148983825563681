import React, { type SyntheticEvent, useEffect } from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { ACCESS_PAGE_URL } from '../../common/constants';
import messages from './messages';

export const DisabledScreen = () => {
	const { isSiteAdmin } = useTenantContext();
	const { formatMessage } = useIntl();

	useEffect(() => {
		experience.sharing.dialogLoad.abort();
	}, []);

	const handleEnableClick = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		event.preventDefault();
		fireUIAnalytics(analyticsEvent, 'enableOnSite');

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(ACCESS_PAGE_URL, '_blank');
	};

	return (
		<SectionMessage
			title={formatMessage(messages.disabledOnSiteTitle)}
			appearance="information"
			testId="polaris-lib-control-sharing.ui.disabled-screen"
			actions={
				isSiteAdmin ? (
					<SectionMessageAction
						href={ACCESS_PAGE_URL}
						testId="polaris-lib-control-sharing.ui.disabled-screen.enable-button"
						linkComponent={(props) => (
							<a id="pendo.publish-modal.enable-publishing-on-site" {...props}>
								{props.children}
							</a>
						)}
						onClick={handleEnableClick}
					>
						{formatMessage(messages.enablePublishingButtonLabel)}
					</SectionMessageAction>
				) : (
					<SectionMessageAction>
						{formatMessage(messages.contactSiteAdminMessage)}
					</SectionMessageAction>
				)
			}
		>
			{formatMessage(messages.disabledOnSiteDescription)}
		</SectionMessage>
	);
};
