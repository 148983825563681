import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	helpText: {
		id: 'polaris-ideas.view-controls.config-sort.help-text',
		defaultMessage: 'Select the fields you want to use to sort ideas in this view',
		description: 'Help text',
	},
	saveButton: {
		id: 'polaris-ideas.view-controls.config-sort.save-button',
		defaultMessage: 'Keep sorted',
		description: '',
	},
	resetButton: {
		id: 'polaris-ideas.view-controls.config-sort.reset-button',
		defaultMessage: 'Clear sort',
		description: '',
	},
	sortFirstLabel: {
		id: 'polaris-ideas.view-controls.config-sort.sort-first-label',
		defaultMessage: 'Sort by',
		description: '',
	},
	sortThenLabel: {
		id: 'polaris-ideas.view-controls.config-sort.sort-then-label',
		defaultMessage: 'then by',
		description: '',
	},
	addButton: {
		id: 'polaris-ideas.view-controls.config-sort.add-button',
		defaultMessage: 'Add field',
		description: '',
	},
	sortDisabledText: {
		id: 'polaris-ideas.view-controls.config-sort.sort-disabled-text',
		defaultMessage: 'You need the "can edit" permission to make changes to this view.',
		description: "Message displayed when user doesn't have manage view permission",
	},
	justForYouHeading: {
		id: 'polaris-ideas.view-controls.config-sort.just-for-you-heading',
		defaultMessage: 'Just for you',
		description: 'Just for you section header',
	},
	justForYouDescription: {
		id: 'polaris-ideas.view-controls.config-sort.just-for-you-description',
		defaultMessage: 'Add ephemeral sort just for you. Sort will reset when you reload the view.',
		description: 'Description of just for you sort functionality in view sort sidebar',
	},
});
