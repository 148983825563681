import gqlTagPolaris from 'graphql-tag';
import type { CustomErrorHandlerRespose } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	AtlasProjectsByAriQuery,
	AtlasProjectsSearchQuery,
	AtlasProjectTqlQuery,
	AtlasProjectsByAri,
	AtlasProjectsSearch,
	AtlasProjectTql,
} from './types';

export const ProjectFragment = gqlTagPolaris`
fragment AtlasProjectDetails on TownsquareProject {
    id
    uuid
    name
    url
    owner {
      accountStatus
      name
    }
    iconData
    key
    archived
    state {
      label
      value
    }
    dueDate {
      dateRange {
        start
        end
      }
      confidence
      label
    }
  }
    `;

export const ProjectWithUsedFieldsFragment = gqlTagPolaris`
    fragment AtlasProjectDetailstWithUsedFields on TownsquareProject {
      id
      name
      url
      state {
        label
        value
      }
      dueDate {
        dateRange {
          start
          end
        }
        confidence
        label
      }
    }
    `;

const ATLAS_PROJECTS_BY_ARI_QUERY = gqlTagPolaris`
query jira_atlas_AtlasProjectsByAri($aris: [String!]) {
  townsquare {
    projectsByAri(aris: $aris) {
      ...AtlasProjectDetailstWithUsedFields
    }
  }
}
${ProjectWithUsedFieldsFragment}
`;

const ATLAS_PROJECTS_SEARCH_QUERY = gqlTagPolaris`
query jira_atlas_AtlasProjectsSearch($cloudId: String!, $search: String) {
  townsquare {
    projectSearch(cloudId: $cloudId, q: $search, first: 10) {
      edges {
        node {
          ...AtlasProjectDetailstWithUsedFields
        }
      }
    }
  }
}
${ProjectWithUsedFieldsFragment}
`;

const ATLAS_PROJECT_TQL = gqlTagPolaris`
query jira_atlas_AtlasProjectTql($q: String!, $cloudId: String!) {
  townsquare {
   projectTql(q: $q, cloudId: $cloudId) @optIn(to: "Townsquare") {
      edges {
        node {
          ...AtlasProjectDetailstWithUsedFields
        }
      }
    }
  }
}
${ProjectWithUsedFieldsFragment}
`;

const handleByAriResult = (result: { data: AtlasProjectsByAriQuery } | null) => {
	if (result?.data.townsquare?.projectsByAri === undefined) {
		throw new Error('polaris-ideas.atlas-projects-by-ari-error');
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (result.data.townsquare?.projectsByAri || []) as AtlasProjectsByAri;
};

const handleSearchResult = (
	result: { data: AtlasProjectsSearchQuery } | null,
): AtlasProjectsSearch => {
	if (result?.data.townsquare?.projectSearch === undefined) {
		throw new Error('polaris-ideas.atlas-projects-search-error');
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (result.data.townsquare?.projectSearch?.edges?.map((edge) => edge?.node).filter(Boolean) ||
		[]) as AtlasProjectsSearch;
};

const handleByKeyResult = (result: { data: AtlasProjectTqlQuery } | null) => {
	if (
		result?.data.townsquare?.projectTql === undefined ||
		result?.data.townsquare?.projectTql === null ||
		!result.data.townsquare?.projectTql.edges
	) {
		throw new Error('polaris-ideas.atlas-project-by-key-error');
	}

	if (!result.data.townsquare?.projectTql.edges[0]?.node) {
		return null;
	}

	return result.data.townsquare?.projectTql.edges[0].node;
};

export const getAtlasProjectsByAriBatch = (
	client: PolarisApolloClient,
	aris: string[],
): Promise<AtlasProjectsByAri> =>
	client
		.query<AtlasProjectsByAriQuery>({
			query: ATLAS_PROJECTS_BY_ARI_QUERY,
			variables: {
				aris,
			},
			context: {
				errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
					graphQLErrors?.length === 1 && graphQLErrors[0].extensions?.statusCode === 401,
			},
			fetchPolicy: 'no-cache',
		})
		.then(handleByAriResult);

export const getAtlasProjectsByAri = async (
	client: PolarisApolloClient,
	aris: string[],
): Promise<AtlasProjectsByAri> => {
	const chunkArray = (array: string[], chunkSize: number) => {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	};

	const queryProjectsWithRateLimiting = async (
		chunks: string[][],
		processChunk: (chunk: string[]) => Promise<AtlasProjectsByAri>,
	): Promise<AtlasProjectsByAri[]> => {
		const poolLimit = 5;
		const allPromises: Promise<AtlasProjectsByAri>[] = [];
		const executingPromises: Promise<void>[] = [];

		for (const chunk of chunks) {
			const promise: Promise<AtlasProjectsByAri> = Promise.resolve().then(() =>
				processChunk(chunk),
			);
			allPromises.push(promise);

			if (poolLimit <= chunks.length) {
				const executingPromise: Promise<void> = promise.then(() => {
					executingPromises.splice(executingPromises.indexOf(executingPromise), 1);
				});
				executingPromises.push(executingPromise);
				if (executingPromises.length >= poolLimit) {
					// eslint-disable-next-line no-await-in-loop
					await Promise.race(executingPromises);
				}
			}
		}
		return Promise.all(allPromises);
	};

	const chunks = chunkArray(aris, 200);
	const projectPromises = await queryProjectsWithRateLimiting(chunks, (chunk: string[]) =>
		getAtlasProjectsByAriBatch(client, chunk),
	);

	return projectPromises.flat();
};

export const getAtlasProjectsSearch = (
	client: PolarisApolloClient,
	cloudId: string,
	search = '',
): Promise<AtlasProjectsSearch> =>
	client
		.query<AtlasProjectsSearchQuery>({
			query: ATLAS_PROJECTS_SEARCH_QUERY,
			variables: {
				cloudId,
				search,
			},
			context: {
				errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
					graphQLErrors?.length === 1 && graphQLErrors[0].extensions?.statusCode === 401,
			},
			fetchPolicy: 'no-cache',
		})
		.then(handleSearchResult);

export const getAtlasProjectByKey = (
	client: PolarisApolloClient,
	cloudId: string,
	key: string,
): Promise<AtlasProjectTql | null> =>
	client
		.query<AtlasProjectTqlQuery>({
			query: ATLAS_PROJECT_TQL,
			variables: {
				q: `key = ${key}`,
				cloudId,
			},
			context: {
				errorHandler: ({ graphQLErrors }: CustomErrorHandlerRespose) =>
					graphQLErrors?.length === 1 &&
					[401, 404].includes(graphQLErrors[0].extensions?.statusCode),
			},
			fetchPolicy: 'no-cache',
		})
		.then(handleByKeyResult);
