import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji/src';
import { JiraProductDiscoveryLogo } from '@atlaskit/logo';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { PAGE_MARGIN_X } from '@atlassian/jira-polaris-common/src/common/constants';
import {
	useIsCollectionView,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useIsSharedViewUnavailable } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks.tsx';
import {
	useIsInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { useIsRightSidebarOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	useIsIssueOpenInSidebar,
	useIsIssueOpenInFullscreen,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks';
import {
	useCurrentViewIsEditingTitle,
	useCurrentViewTitle,
	useCurrentViewContainsArchived,
	useCurrentViewKind,
	useCurrentViewEmoji,
	useCanManageCurrentView,
	useCurrentViewId,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import FullscreenButton from '@atlassian/jira-polaris-component-fullscreen-button/src';
import {
	useHasNoProjectPermissions,
	useCanManageViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ViewEmojiSpotlightProvider } from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/view-emoji-spotlight-provider/index.tsx';
import { EditableViewTitle } from '@atlassian/jira-polaris-lib-editable-view-title/src/ui/index.tsx';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
	SCREEN,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { AccessButton } from './access';
import { AvatarControl } from './avatar';
import { CommentsButton } from './comments-button';
import { ExportDialog } from './export-dialog';
import { FullscreenToggle } from './fullscreen';
import { PublishButton } from './publish';
import { ShareDialog } from './share-dialog';
import { Subtitle } from './view-subtitle';
import { Visitors } from './visitors';

type Props = {
	isFullscreen: boolean;
	onToggleFullscreen: () => void;
};

const WAC_LINK = 'https://www.atlassian.com/software/jira/product-discovery';

const getJpdProjectsListLink = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const siteUrl = `${window.location.protocol}//${window.location.host}`;
	return `${siteUrl}/jira/projects?types=${PRODUCT_DISCOVERY_PROJECT}`;
};

export const ViewHeader = memo(({ isFullscreen, onToggleFullscreen }: Props) => {
	const title = useCurrentViewTitle();
	const isEditingTitle = useCurrentViewIsEditingTitle();
	const canManageViews = useCanManageViews();
	const canManageCurrentView = useCanManageCurrentView();
	const { renameCurrentView, setEditCurrentViewTitle, addCurrentViewEmoji } = useViewActions();
	const isArchiveView = useCurrentViewContainsArchived();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCollectionView = useIsCollectionView();
	const isSharedView = useIsSharedView();
	const isSharedViewUnavailable = useIsSharedViewUnavailable();
	const isViewsLoading = useIsViewsLoading();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const viewKind = useCurrentViewKind();
	const emojiId = useCurrentViewEmoji();
	const isIssuesLoading = useIsIssuesLoading();
	const isInitialized = useIsInitialized();
	const currentViewId = useCurrentViewId();

	const onCancelEditTitle = useCallback(() => {
		setEditCurrentViewTitle(false);
		experience.listView.changeViewName.abort();
	}, [setEditCurrentViewTitle]);

	const onEditTitle = useCallback(() => {
		setEditCurrentViewTitle(true);
		experience.listView.changeViewName.start();
	}, [setEditCurrentViewTitle]);

	const onRenameView = useCallback(
		(newTitle: string) => {
			renameCurrentView(newTitle, (view): void => {
				if (isClientFetchError(view?.saveError)) {
					experience.listView.changeViewName.abort(view?.saveError);
				} else if (view && view.saveError) {
					experience.listView.changeViewName.failure(view.saveError);
				} else if (view && !view.saveError) {
					experience.listView.changeViewName.success();
				}
			});
		},
		[renameCurrentView],
	);

	const sharedViewLogo = (
		<LogoContainer>
			<JiraProductDiscoveryLogo size="small" appearance="brand" />
		</LogoContainer>
	);

	const sharedViewLogoLink = hasNoProjectPermissions ? WAC_LINK : getJpdProjectsListLink();

	const renderHeaderContent = (props?: {
		setLoadedEmoji: (emoji: EmojiDescription | null) => void;
	}) => (
		<>
			<EditableViewTitle
				title={title}
				emojiId={emojiId}
				viewKind={viewKind}
				isEditingTitle={isEditingTitle}
				onEmojiChange={addCurrentViewEmoji}
				onEmojiLoaded={props?.setLoadedEmoji}
				onTitleEdit={onEditTitle}
				onTitleCancel={onCancelEditTitle}
				onTitleRename={onRenameView}
				isReadOnly={
					(ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews) ||
					isArchiveView ||
					isRightSidebarOpen
				}
				isLoading={isIssuesLoading || !isInitialized}
			/>
			{isRightSidebarOpen ||
			isIssueOpenInSidebar ||
			isIssueOpenInFullscreen ||
			isSharedView ? null : (
				<MenuContainer>
					{!isCollectionView && <Visitors testId="polaris-ideas.ui.view-header.visitors" />}
					{!isArchiveView && (
						<>
							<CommentsButton />
							<PublishButton />
							<AccessButton />
							<ShareDialog />
							<ExportDialog />
						</>
					)}
					{getWillShowNav4() ? (
						<FullscreenButton
							testId="polaris-ideas.ui.view-header.fullscreen-button"
							onToggle={onToggleFullscreen}
						/>
					) : (
						<FullscreenToggle isFullscreen={isFullscreen} onToggle={onToggleFullscreen} />
					)}
				</MenuContainer>
			)}
		</>
	);

	return (
		<ContextualAnalyticsData sourceName="viewHeader" sourceType={SCREEN}>
			{whyDidYouRenderPlaceholder('ViewHeader')}
			<Wrapper isSharedView={isSharedView}>
				<Flex direction="column" gap="space.075" xcss={headerContentContainerStyles}>
					<Flex justifyContent="space-between" alignItems="center" gap="space.150">
						{ff('polaris.publish.replace-view-icon-when-publishing') ? (
							<ViewEmojiSpotlightProvider key={currentViewId}>
								{renderHeaderContent}
							</ViewEmojiSpotlightProvider>
						) : (
							renderHeaderContent()
						)}
					</Flex>
					<Subtitle
						showViewDescriptionButton={
							!(isArchiveView || (isSharedView && (isViewsLoading || isSharedViewUnavailable)))
						}
					/>
				</Flex>
				{isSharedView && (
					<MenuContainer>
						{!isArchiveView && (
							<>
								<CommentsButton />
								<PublishButton />
							</>
						)}

						<SharedViewLogoContainer
							data-component-selector="shared-view-logo-container-Aw12"
							data-testid="polaris-ideas.ui.view-header.shared-view-logo-container"
						>
							<a
								data-testid="polaris-ideas.ui.view-header.shared-view-logo"
								href={sharedViewLogoLink}
								id="pendo.shared-view.logo-link"
								onClick={() => {
									fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'jpdLogo');
								}}
							>
								{sharedViewLogo}
							</a>
							<AvatarControl />
						</SharedViewLogoContainer>
					</MenuContainer>
				)}
			</Wrapper>
		</ContextualAnalyticsData>
	);
});

ViewHeader.displayName = 'ViewHeader';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'* + [data-component-selector="shared-view-logo-container-Aw12"]::before': {
		marginRight: token('space.200', '16px'),
		content: '',
		position: 'absolute',
		left: 0,
		top: '3px',
		bottom: '3px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderLeft: `1px solid ${token('color.border', colors.N40)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isSharedView: boolean }>(
	{
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		padding: `0 ${PAGE_MARGIN_X}px`,
		boxSizing: 'border-box',
		marginTop: token('space.250', '20px'),
		marginBottom: token('space.050', '4px'),
		gap: token('space.075', '6px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isSharedView }) =>
		isSharedView && {
			marginBottom: token('space.150', '12px'),
			marginTop: token('space.negative.050', '-4px'),
			paddingTop: token('space.100', '8px'),
			paddingBottom: token('space.100', '8px'),
			boxShadow: token(
				'elevation.shadow.overflow',
				'0px 0px 8px rgba(9, 30, 66, 0.16),0px 0px 1px rgba(9, 30, 66, 0.12)',
			),
			minHeight: '70px',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LogoContainer = styled.div({
	height: '24px',
	margin: `0 ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SharedViewLogoContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	marginLeft: token('space.050', '4px'),
});

const headerContentContainerStyles = xcss({ flexGrow: 1, width: '100%' });
