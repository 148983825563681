import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project';
import { ConfigurePropertiesDialogContainer } from '@atlassian/jira-polaris-common/src/controllers/configure-properties-dialog';
import {
	useIsCollectionView,
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useSnippetProviders } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/snippet-providers-hooks.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useInsightsForIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/insights/hooks';
import { useGetIdeaPlaysIdsWithContributions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/plays/hooks';
import { MediaContainer } from '@atlassian/jira-polaris-common/src/controllers/media';
import { usePlayIdsToAris } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/plays-hooks';
import { ConfigurePropertiesDialog } from '@atlassian/jira-polaris-common/src/ui/configure-properties-dialog';
import {
	useContainerAri,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/store';
import { PolarisEnvironmentContainerTypes } from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import { PermissionsContainer } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useHasNoProjectPermissions,
	useCanCreateInsights,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useFieldRemote } from '@atlassian/jira-polaris-remote-field/src/main.tsx';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import type { IssueId, IssueKey, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { InsightsContainer } from '../../../controllers/insights';
import {
	useInsightIds,
	useInsightsAttachments,
	useInsightsMediaIds,
	usePlayInsights,
	useInsightsCount,
	useSelectedInsightsCount,
} from '../../../controllers/insights/selectors/insights-hooks';
import {
	useIsInsightsLoading,
	useInsightsLoadingError,
	useIsInsightsLoadingRateLimitError,
} from '../../../controllers/insights/selectors/meta-hooks';
import { InsightsActions } from './actions';
import { CreateInsight } from './add-insight';
import { EmptyInsights } from './empty-state';
import { ErrorState } from './error-state';
import { InsightsList } from './list';
import { InsightsUnavailableSharedViewSection } from './shared-view-warning';
import { InsightsSkeleton } from './skeleton';

type LoadingWrapperProps = {
	children: ReactNode;
	isInsightsLoading: boolean;
};

const LoadingWrapper = ({ children, isInsightsLoading }: LoadingWrapperProps) => {
	if (isInsightsLoading) {
		return <InsightsSkeleton />;
	}
	return <>{children}</>;
};

export const InsightsActionsWrapper = () => {
	const selectedInsightsCount = useSelectedInsightsCount();
	return selectedInsightsCount > 0 ? <InsightsActions /> : null;
};

type Props = {
	isCompact?: boolean;
	issueId: IssueId;
	localIssueId: LocalIssueId;
	scrollableRef: React.RefObject<HTMLElement>;
};

export const InsightsUI = ({ issueId, localIssueId, isCompact, scrollableRef }: Props) => {
	const isInsightsLoading = useIsInsightsLoading();
	const insightsLoadingError = useInsightsLoadingError();
	const isInsightsLoadingRateLimitError = useIsInsightsLoadingRateLimitError();
	const insightIds = useInsightIds();
	const playInsights = usePlayInsights();
	const attachmentsFromSnippets = useInsightsAttachments();
	const insightsMediaIds = useInsightsMediaIds();
	const canCreateInsights = useCanCreateInsights();
	const isSharedView = useIsSharedView();
	const insightsCountFromProject = useInsightsCount();
	const ideaPlaysIdsWithContributions = useGetIdeaPlaysIdsWithContributions(localIssueId);
	const isIdeaArchived = useIsSelectedIssueArchived();
	const [isEditMode, setIsEditMode] = useState(false);

	const selectedInsightsCount = useSelectedInsightsCount();
	const isActionsEnabled = selectedInsightsCount > 0;

	const { setTimestampIssueInsightsSeen } = useIssueActions();
	const onInsightsShown = useCallback(() => {
		experience.ideaView.insightsSegmentLoad.success();
		setTimestampIssueInsightsSeen(localIssueId, new Date().valueOf());
	}, [setTimestampIssueInsightsSeen, localIssueId]);

	useEffect(() => {
		// Mark insights as read
		!isInsightsLoading && insightIds.length && onInsightsShown();
	}, [insightIds.length, isInsightsLoading, onInsightsShown]);

	const playInsightIds = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			playInsights.reduce<Record<string, any>>((result, playInsight) => {
				Object.assign(result, {
					[playInsight.id]: true,
				});
				return result;
			}, {}),
		[playInsights],
	);

	const playInsightIdsWithContributions = useMemo(
		() =>
			playInsights
				.filter((playInsight) => {
					const playId = playInsight.play?.id;
					if (!playId) {
						return false;
					}
					const { resourceId } = getAriConfig(playId);
					return ideaPlaysIdsWithContributions[resourceId]?.length > 0;
				}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.reduce<Record<string, any>>((result, playInsight) => {
					Object.assign(result, {
						[playInsight.id]: true,
					});
					return result;
				}, {}),
		[playInsights, ideaPlaysIdsWithContributions],
	);

	const handleCloseEditMode = useCallback(() => {
		setIsEditMode(false);
	}, []);

	if (isSharedView) {
		return <InsightsUnavailableSharedViewSection isCompact={isCompact} />;
	}

	if (insightsLoadingError) {
		return (
			<ErrorState error={insightsLoadingError} isRateLimitError={isInsightsLoadingRateLimitError} />
		);
	}

	if (!isInsightsLoading && insightIds.length === 0 && !isEditMode) {
		return <EmptyInsights onEnterEditMode={() => setIsEditMode(true)} isCompact={isCompact} />;
	}

	const displayCreateInsight =
		canCreateInsights &&
		!isActionsEnabled &&
		insightsCountFromProject &&
		(!isIdeaArchived || !!insightIds.length);

	return (
		<Container isActionsEnabled={isActionsEnabled}>
			<MediaContainer
				issueId={issueId}
				mediaIds={insightsMediaIds}
				attachmentsFromSnippets={attachmentsFromSnippets}
			>
				<ConfigurePropertiesDialog />
				{(!!displayCreateInsight || isEditMode) && (
					<CreateInsight
						initialValues={{ isEditing: isEditMode }}
						onCloseEditMode={handleCloseEditMode}
						isCompact={isCompact}
					/>
				)}
				<LoadingWrapper isInsightsLoading={isInsightsLoading}>
					<InsightsList
						issueId={issueId}
						insightIds={insightIds}
						scrollableRef={scrollableRef}
						playInsightIds={playInsightIds}
						playInsightIdsWithContributions={playInsightIdsWithContributions}
					/>
				</LoadingWrapper>
			</MediaContainer>
		</Container>
	);
};

type InsightsProps = {
	isVisible: boolean;
	localIssueId: LocalIssueId;
	issueId: IssueId;
	issueKey: IssueKey;
	projectId: ProjectId;
	onOpenIdeaView?: (issueKey: IssueKey) => void;
};

type InsightsDataContainerProps = InsightsProps & {
	children: ReactNode;
};

export const InsightsDataContainer = ({
	children,
	isVisible,
	...rest
}: InsightsDataContainerProps) => {
	const [localSnippetProviders, setLocalSnippetProviders] = useState<SnippetProvider[] | undefined>(
		undefined,
	);
	const { issueId, issueKey, projectId, localIssueId, onOpenIdeaView } = rest;
	const cloudId = useCloudId();

	const insights = useInsightsForIssue(localIssueId);
	const playContributions = useGetIdeaPlaysIdsWithContributions(localIssueId);
	const playIdsToAris = usePlayIdsToAris();

	const { generalDataUpdateFailedError } = useErrorHandlers();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();

	const insightsRemote = useInsightRemote();
	const isSharedView = useIsSharedView();
	const isEmbeddedView = useIsEmbedded();

	const issuesRemote = useIssueRemote();
	const [snippetProviders] = useSnippetProviders();

	const container = useEnvironmentContainer();
	const containerAri = useContainerAri();
	const isCollectionView = useIsCollectionView();
	const fieldsRemote = useFieldRemote();

	useEffect(() => {
		// in case of collections, snippets were not fetched via fields container (projectARI is required)
		// fetch snippets for selected issue's project id here then
		if (container?.type === PolarisEnvironmentContainerTypes.COLLECTION) {
			fieldsRemote.fetchSnippetProviders('ACTIVE_ONLY', projectId).then(setLocalSnippetProviders);
		}
	}, [fieldsRemote, container, projectId]);

	return (
		<PermissionsContainer
			containerAri={containerAri}
			scope={getWillShowNav4() ? getProjectContainerScope(projectId) : 'permissions-singleton'}
			isSharedView={isSharedView}
			isCollectionView={isCollectionView}
			isEmbeddedView={isEmbeddedView}
			onLoadStart={jpdProjectPageLoadPermissionsStart}
			onLoadEnd={jpdProjectPageLoadPermissionsEnd}
		>
			<InsightsContainer
				snippetProviders={localSnippetProviders ?? snippetProviders}
				isVisible={isVisible}
				isCollectionView={isCollectionView}
				insights={insights}
				playIdsToAris={playIdsToAris}
				playContributions={playContributions}
				cloudId={cloudId}
				projectId={projectId}
				issueId={issueId}
				issueKey={issueKey}
				isSharedView={isSharedView}
				issuesRemote={issuesRemote}
				insightsRemote={insightsRemote}
				hasNoProjectPermissions={hasNoProjectPermissions}
				onOpenIdeaView={onOpenIdeaView}
				onCreateFailed={generalDataUpdateFailedError}
				onUpdateFailed={generalDataUpdateFailedError}
				onDeleteFailed={generalDataUpdateFailedError}
				onCopyInsightsFailed={generalDataUpdateFailedError}
			>
				<ConfigurePropertiesDialogContainer
					insightsRemote={insightsRemote}
					cloudId={cloudId}
					projectId={projectId}
					issueId={issueId}
				>
					{children}
				</ConfigurePropertiesDialogContainer>
			</InsightsContainer>
		</PermissionsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isActionsEnabled: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ isActionsEnabled }) => (isActionsEnabled ? '0px' : token('space.300', '24px')),
});
