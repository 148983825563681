import gqlTagPolaris from 'graphql-tag';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { PLAY_CONTRIBUTION_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/play/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { transformPlayContribution } from '../utils';
import type {
	GetPlayContribution,
	GetIssueContributions,
	GetIssueContributionsVariables,
	TransformablePolarisPlayContribution,
} from './types';

const GET_PLAY_CONTRIBUTION_QUERY = gqlTagPolaris`
query jira_polaris_GetPlayContribution($projectId: ID!, $playId: ID!, $contribId: ID!, $skipComment: Boolean!) {
  polarisProject(id: $projectId, skipRefresh: true) {
    id
    play(id: $playId) {
      contribution(id: $contribId) {
        ...PlayContribution
      }
    }
  }
}
${PLAY_CONTRIBUTION_FRAGMENT}
`;

export const getPlayContribution = (
	client: PolarisApolloClient,
	projectAri: string,
	playId: number,
	contribId: number,
): Promise<TransformablePolarisPlayContribution> =>
	client
		.query<GetPlayContribution>({
			query: GET_PLAY_CONTRIBUTION_QUERY,
			variables: {
				projectId: projectAri,
				playId,
				contribId,
				skipComment: false,
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (!result.data.polarisProject?.play?.contribution) {
				throw new Error('project-config.fetch-play-contribution-error: no data or no data node');
			}

			return result.data.polarisProject.play.contribution;
		});

const GET_ISSUE_CONTRIBUTIONS_QUERY = gqlTagPolaris`
query jira_polaris_GetIssueContributions($projectId: ID!, $playId: ID!, $subjectId: ID!, $skipComment: Boolean!) {
  polarisProject(id: $projectId) {
    id
    play(id: $playId) {
	  id
      contributionsBySubject(subject: $subjectId) {
        ...PlayContribution
      }
    }
  }
}
${PLAY_CONTRIBUTION_FRAGMENT}
`;

export const getIssueContributions = (
	client: PolarisApolloClient,
	projectId: string | undefined,
	playId: string,
	subjectId: string,
): Promise<PolarisPlayContribution[]> => {
	const variables: GetIssueContributionsVariables = {
		projectId: String(projectId),
		playId,
		subjectId,
		skipComment: false,
	};
	return client
		.query<GetIssueContributions>({
			query: GET_ISSUE_CONTRIBUTIONS_QUERY,
			variables,
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			const { polarisProject } = result.data;
			if (!polarisProject?.play?.contributionsBySubject) {
				throw new Error('project-config.fetch-issue-contributions-error: no data or no data node');
			}

			return polarisProject.play.contributionsBySubject?.map(transformPlayContribution);
		});
};
