import React, { type ReactNode, useEffect } from 'react';
import Link from '@atlaskit/link';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import messages from './messages';

export const ErrorScreen = () => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		experience.viewPermissions.openDialog.failure();
	}, []);

	return (
		<SectionMessage
			title={formatMessage(messages.errorTitle)}
			appearance="error"
			testId="polaris-component-view-access.ui.error-screen.error-screen"
		>
			{formatMessage(messages.errorDescription, {
				a: (chunks: ReactNode[]) => (
					<Link href="https://support.atlassian.com/" target="_blank">
						{chunks}
					</Link>
				),
			})}
		</SectionMessage>
	);
};
