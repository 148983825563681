import React, { memo, useCallback, useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	IdeaViewStartMark,
	IdeaViewEndMark,
	MARKS,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/idea-view';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	useArchivedFieldsConfig,
	useAllFieldsArray,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useIsInitializedPromise as useIsFieldsInitializedPromise } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks';
import { IdeaViewContainer } from '@atlassian/jira-polaris-common/src/controllers/idea';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useIsInitialized,
	useIsInitializedPromise,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { useTimestampCurrentUserSeenComments } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/comments/hooks';
import { useSelectedIssueLinkedIssueData } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useTimestampCurrentUserSeenInsights } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/insights/hooks';
import { useJiraRankFieldKey } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import {
	useIssueViewLayout,
	useSelectedIssueQueryParameter,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import {
	useProjectIdUnsafe,
	useProjectAri,
} from '@atlassian/jira-polaris-component-environment-container';
import {
	useDataPointIssueLinkType,
	useDeliveryIssueLinkType,
	useArjConfiguration,
} from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { useHasNoProjectPermissions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { DeliveryIssue } from '@atlassian/jira-polaris-domain-delivery/src/types.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { PolarisIdeaViewDataContainerProps } from './types';

export const IdeaViewDataContainer = memo<PolarisIdeaViewDataContainerProps>(
	({ children, ...rest }: PolarisIdeaViewDataContainerProps) => {
		const projectId = useProjectIdUnsafe();
		const {
			addPropertiesForLoadedIssue,
			updateFieldValueForSelectedIssue,
			setIssueLinksForSelectedIssue,
			setCommentsForSelectedIssue,
			loadDeliveryProgress,
			setIsSingleIssueLoaded,
			scheduleDynamicFieldCalculation,
		} = useIssueActions();
		const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
		const polarisDataPointLinkType = useDataPointIssueLinkType();

		const {
			generalDataLoadingFailedError,
			generalDataUpdateFailedError,
			generalActionFailedError,
		} = useErrorHandlers();
		const [archivedFieldsConfig] = useArchivedFieldsConfig();
		const linkedIssueData = useSelectedIssueLinkedIssueData();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const initialDeliveryTickets: DeliveryIssue[] = useMemo(
			() =>
				linkedIssueData && linkedIssueData.length
					? linkedIssueData.reduce(
							(result, { childIssues, isDeliveryIssue, priority, ...deliveryTicketData }) => {
								if (isDeliveryIssue) {
									result.push({
										...deliveryTicketData,
										issueId: String(deliveryTicketData.issueId),
									});
								}
								return result;
							},
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							[] as DeliveryIssue[],
						)
					: [],
			[linkedIssueData],
		);

		const issuesContainerInitialized = useIsInitializedPromise();
		const isIssuesInitialized = useIsInitialized();
		const fieldsContainerInitialized = useIsFieldsInitializedPromise();
		const issueViewLayout = useIssueViewLayout();

		const onIssueLoadedCallback = useCallback(
			(response: RemoteIssue, isFirstIssueLoad: boolean) => {
				issuesContainerInitialized().then(() => {
					setIssueLinksForSelectedIssue(response);
				});

				const isIdeaOpenInFullscreen = issueViewLayout === undefined;
				const isSingleIssueLoad =
					isFirstIssueLoad && isIdeaOpenInFullscreen && !isIssuesInitialized;
				// when it's a single issue load (when opening an idea in a new tab), we
				// do not want to update the description after the issues container is initialized
				// because it's already loaded (and maybe it changed since the response is available)
				if (!isSingleIssueLoad) {
					issuesContainerInitialized().then(() => {
						// set description also in issues store only after all issues have been loaded (all the properties are replaced)
						const value: DocumentFieldValue = response.fields.description;
						updateFieldValueForSelectedIssue({
							fieldKey: 'description',
							newValue: value,
							performSideEffects: false,
						});
					});
				}

				return fieldsContainerInitialized().then(() => {
					// only add properties and load delivery progress if issues container has not been initialized yet
					// this can happen when opening an idea in a new tab: in this case we just load a single idea and
					// we don't initialize the whole project
					if (!isIssuesInitialized) {
						addPropertiesForLoadedIssue(response);
						loadDeliveryProgress();
						scheduleDynamicFieldCalculation();
					}

					setIsSingleIssueLoaded(createAnalyticsEvent);
				});
			},
			[
				addPropertiesForLoadedIssue,
				fieldsContainerInitialized,
				isIssuesInitialized,
				issuesContainerInitialized,
				loadDeliveryProgress,
				setIssueLinksForSelectedIssue,
				updateFieldValueForSelectedIssue,
				setIsSingleIssueLoaded,
				scheduleDynamicFieldCalculation,
				createAnalyticsEvent,
				issueViewLayout,
			],
		);
		const [hasNoProjectPermissions] = useHasNoProjectPermissions();

		const issuesRemote = useIssueRemote();

		const arjConfiguration = useArjConfiguration();

		const onDeliveryIssuesUpdated = useCallback(
			(response?: RemoteIssue) => {
				if (response) {
					setIssueLinksForSelectedIssue(response);
				}
			},
			[setIssueLinksForSelectedIssue],
		);

		const timestampCurrentUserSeenComments = useTimestampCurrentUserSeenComments();
		const timestampCurrentUserSeenInsights = useTimestampCurrentUserSeenInsights();
		const [fields] = useAllFieldsArray();
		const [jiraRankFieldKey] = useJiraRankFieldKey();

		return (
			<>
				<IdeaViewStartMark mark={MARKS.IDEA_VIEW_DATA_CONTAINER} />
				<IdeaViewContainer
					issuesRemote={issuesRemote}
					timestampCurrentUserSeenComments={timestampCurrentUserSeenComments}
					timestampCurrentUserSeenInsights={timestampCurrentUserSeenInsights}
					onIssuesContainerInitialized={issuesContainerInitialized}
					hasNoProjectPermissions={hasNoProjectPermissions}
					initialDeliveryTickets={initialDeliveryTickets}
					projectId={projectId}
					archivedFieldsConfig={archivedFieldsConfig}
					onIssueLoaded={onIssueLoadedCallback}
					onDataLoadingFailed={generalDataLoadingFailedError}
					onDataUpdateFailed={generalDataUpdateFailedError}
					onCommentsUpdated={setCommentsForSelectedIssue}
					onActionFailed={generalActionFailedError}
					fields={fields}
					rankField={jiraRankFieldKey}
					polarisIssueLinkTypes={useMemo(
						() => ({
							polarisDeliveryIssueLinkType,
							polarisDataPointLinkType,
						}),
						[polarisDeliveryIssueLinkType, polarisDataPointLinkType],
					)}
					arjConfiguration={arjConfiguration}
					onDeliveryIssuesUpdated={onDeliveryIssuesUpdated}
					{...rest}
				>
					<IdeaViewEndMark mark={MARKS.IDEA_VIEW_DATA_CONTAINER} />
					{children}
				</IdeaViewContainer>
			</>
		);
	},
);

/**
 * new container, only dealing with idea-specific things and assuming all the other data
 * containers are provided by the ideas app
 */
export const PolarisIdeaViewDataContainer = (props: PolarisIdeaViewDataContainerProps) => {
	const projectAri = useProjectAri();
	const { atlassianAccountId } = useTenantContext();
	const selectedIssue = useSelectedIssueQueryParameter();
	const { isSidebarPreview, children, ...rest } = props;
	const isCollectionView = useIsCollectionView();

	if (!isCollectionView) {
		if (projectAri === undefined || projectAri === null) {
			return null;
		}
	}

	if (atlassianAccountId === null) {
		return null;
	}

	return (
		<>
			<IdeaViewStartMark mark={MARKS.MAIN_DATA_CONTAINER} />
			<IdeaViewDataContainer {...rest} issueKey={selectedIssue}>
				<IdeaViewEndMark mark={MARKS.MAIN_DATA_CONTAINER} />
				<IdeaViewEndMark mark={MARKS.MAIN_DATA_CONTAINER_X} />
				{children}
			</IdeaViewDataContainer>
		</>
	);
};
