import gqlTagPolaris from 'graphql-tag';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { UnarchivePolarisInsights, UnarchivePolarisInsightsVariables } from './types';

export const UNARCHIVE_INSIGHTS_MUTATION = gqlTagPolaris`
mutation jira_polaris_UnarchivePolarisInsights($project: ID!, $containers: [ID!]) {
  unarchivePolarisInsights(project: $project, containers: $containers) {
    success
    errors {
      message
    }
  }
}
`;

export const unarchiveInsights = (
	client: PolarisApolloClient,
	siteId: string,
	projectId: string,
	issueIds: string[],
): Promise<void> =>
	client
		.mutate<UnarchivePolarisInsights, UnarchivePolarisInsightsVariables>({
			mutation: UNARCHIVE_INSIGHTS_MUTATION,
			variables: {
				containers: issueIds.map((issueId) => JiraIssueAri.create({ siteId, issueId }).toString()),
				project: JiraProjectAri.create({ projectId, siteId }).toString(),
			},
		})
		.then((result) => {
			if (!result.data?.unarchivePolarisInsights?.success) {
				throw new Error('polaris-insights.unarchive-insights-error: no data or no data node');
			}
		});
