import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { Stack, Text, Inline, xcss } from '@atlaskit/primitives';
import Select, { createFilter } from '@atlaskit/select';
import HelpPanelButton from '@atlassian/jira-help-panel-button';
import { useIntl } from '@atlassian/jira-intl';
import { FieldNameAndEmojiEditor } from '@atlassian/jira-polaris-component-field-configuration/src/common/ui/field-name-and-emoji-editor/index.tsx';
import { fieldTypeNameForPolarisFieldType } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/field-type-name/index.tsx';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { eligibleCopyTargetFilterPredicate } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { useGlobalFields, useSourceField } from '../../../controllers';
import { OptionsMappingContextProvider } from '../../../controllers/options-mapping';
import { GlobalFieldOption } from './global-field-option';
import { GlobalFieldPrompt } from './global-field-prompt';
import messages from './messages';
import { OptionsMapping } from './options-mapping';
import { convertOptionValueMappingToPlainMap } from './options-mapping/options-mapping-modal';
import type { OptionsValueMapping } from './options-mapping/options-mapping-modal/field-options-mapper';
import type { FieldOptionData } from './options-mapping/options-mapping-modal/field-options-mapper/field-option-selector';

export const COPY_VALUES_FORM_SUBMIT_BUTTON_PORTAL_ID = 'jpd-copy-values-form-submit-button';

const HELP_ARTICLE_ID = '4uANNCVcmNzvlm4WtcDL6G';
const HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/manage-project-and-global-fields-from-your-project/#Migrate-values-from-a-project-field-to-a-global-field';

const useFieldOptionsValuesMapping = (
	fromSelectField: Field | undefined,
	toSelectField: Field | undefined,
) => {
	const [userProvidedOptionsValuesMapping, setUserProvidedOptionsValuesMapping] = useState<
		Record<string, string | undefined>
	>({});

	const onSetUserProvidedOptionValueMapping = useCallback(
		(mapping: Record<string, string | undefined>) => {
			setUserProvidedOptionsValuesMapping((userProvidedMapping) => ({
				...userProvidedMapping,
				...mapping,
			}));
		},
		[setUserProvidedOptionsValuesMapping],
	);

	useEffect(() => {
		setUserProvidedOptionsValuesMapping({});
	}, [fromSelectField, toSelectField, setUserProvidedOptionsValuesMapping]);

	return useMemo(() => {
		if (!fromSelectField || !toSelectField) {
			return {
				unmappedOptionsCount: 0,
				automappedOptionsCount: 0,
				mapping: {},
				setOptionValueMapping: onSetUserProvidedOptionValueMapping,
			};
		}

		const mapping: OptionsValueMapping = {};

		const toOptionsByValue: Record<string, FieldOptionData> = {};

		for (const toOption of toSelectField.options ?? []) {
			toOptionsByValue[toOption.value] = toOption;
		}

		let unmappedOptionsCount = 0;
		let automappedOptionsCount = 0;

		for (const fromOption of fromSelectField.options ?? []) {
			let mappedOption;

			if (userProvidedOptionsValuesMapping[fromOption.id]) {
				mappedOption = {
					optionId: userProvidedOptionsValuesMapping[fromOption.id],
					automapped: false,
				};
			} else if (toOptionsByValue[fromOption.value]?.id) {
				mappedOption = {
					optionId: toOptionsByValue[fromOption.value].id,
					automapped: true,
				};
			}

			mapping[fromOption.id] = mappedOption;

			if (!mappedOption) {
				unmappedOptionsCount++;
			} else if (mappedOption.automapped) {
				automappedOptionsCount++;
			}
		}

		return {
			mapping,
			unmappedOptionsCount,
			automappedOptionsCount,
			setOptionValueMapping: onSetUserProvidedOptionValueMapping,
		};
	}, [
		fromSelectField,
		toSelectField,
		onSetUserProvidedOptionValueMapping,
		userProvidedOptionsValuesMapping,
	]);
};

type CopyValuesFormProps = {
	onCopyValues: (
		fromFieldKey: FieldKey,
		toFieldKey: FieldKey,
		optionsValueMapping: Record<string, string | undefined>,
	) => Promise<void>;
};

export const CopyValuesForm = ({ onCopyValues }: CopyValuesFormProps) => {
	const { formatMessage } = useIntl();
	const field = useSourceField();
	const globalFields = useGlobalFields();

	const fieldIcon = useMemo(() => iconForPolarisFieldType(field?.type), [field]);
	const fieldTypeNameMessageDescription = fieldTypeNameForPolarisFieldType(field?.type);
	const fieldTypeNameWithSuffix = formatMessage(messages.fieldTypeLabel, {
		fieldTypeName: fieldTypeNameMessageDescription
			? formatMessage(fieldTypeNameMessageDescription)
			: '',
	});

	const [targetFieldKey, setTargetFieldKey] = useState<string | undefined>();

	const selectedTargetField = useMemo(
		() => globalFields.find(({ key }) => key === targetFieldKey),
		[globalFields, targetFieldKey],
	);

	const { mapping, unmappedOptionsCount, automappedOptionsCount, setOptionValueMapping } =
		useFieldOptionsValuesMapping(field, selectedTargetField);

	const sourceFieldType = field?.type ?? undefined;

	const targetFieldOptions = useMemo(
		() =>
			globalFields
				.filter(({ type }) =>
					eligibleCopyTargetFilterPredicate({ from: sourceFieldType, to: type }),
				)
				.map((globalField) => ({
					value: globalField.key,
					field: globalField,
				})),
		[sourceFieldType, globalFields],
	);

	const handleCopyValuesClick = useCallback(() => {
		if (field && targetFieldKey) {
			onCopyValues(field.key, targetFieldKey, convertOptionValueMappingToPlainMap(mapping));
		}
	}, [onCopyValues, field, targetFieldKey, mapping]);

	const [portalIsMounted, setPortalIsMounted] = useState(false);

	useEffect(() => {
		setPortalIsMounted(true);
	}, [setPortalIsMounted]);

	if (!field) {
		return null;
	}

	const isSelectField =
		field.type === FIELD_TYPES.SINGLE_SELECT ||
		field.type === FIELD_TYPES.MULTI_SELECT ||
		field.type === FIELD_TYPES.JSW_MULTI_SELECT;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const submitButtonPortalEl = document.getElementById(COPY_VALUES_FORM_SUBMIT_BUTTON_PORTAL_ID);

	return (
		<Stack space="space.300">
			<Text>
				{formatMessage(messages.explanation, {
					a: (text: React.ReactNode) => (
						<HelpPanelButton
							articleId={HELP_ARTICLE_ID}
							appearance="link"
							spacing="none"
							fallbackComponent={
								<Link href={HELP_FALLBACK_URL} rel="noopener noreferrer" target="_blank">
									{text}
								</Link>
							}
						>
							{text}
						</HelpPanelButton>
					),
				})}
			</Text>
			<Stack space="space.150">
				<Heading size="xsmall">{formatMessage(messages.copyFrom)}</Heading>
				<Stack space="space.075">
					<Inline space="space.050" xcss={sublabelStyles}>
						{fieldIcon}
						<Text size="small" weight="medium" color="color.text.subtle">
							{fieldTypeNameWithSuffix}
						</Text>
					</Inline>
					<FieldNameAndEmojiEditor isReadOnly emojiId={field.emoji} value={field.label} />
				</Stack>
			</Stack>
			<Stack space="space.150">
				<Heading size="xsmall">{formatMessage(messages.copyTo)}</Heading>
				{targetFieldOptions.length ? (
					<>
						<Stack
							space="space.050"
							testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.select-global-field"
						>
							<Text size="small" weight="medium" color="color.text.subtle">
								{formatMessage(messages.selectGlobalFieldLabel)}
							</Text>
							<Select
								placeholder={formatMessage(messages.selectField)}
								classNamePrefix="copy-values-field-select"
								options={targetFieldOptions}
								hideSelectedOptions
								onChange={(globalField) => {
									if (globalField) {
										setTargetFieldKey(globalField.value);
									}
								}}
								// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
								styles={{
									option: (styles) => ({
										...styles,
										// options' inline padding closes field preview when the cursor is near the edges
										// we render the padding inside GlobalFieldOption to fix this
										paddingBlock: '0',
									}),
								}}
								isSearchable
								filterOption={createFilter({
									ignoreCase: true,
									ignoreAccents: true,
									trim: true,
									stringify: (option) => option.data.field.label,
								})}
								formatOptionLabel={(option) => <GlobalFieldOption field={option.field} />}
							/>
						</Stack>
						{isSelectField && selectedTargetField && (
							<OptionsMappingContextProvider
								toField={selectedTargetField}
								optionsValueMapping={mapping}
								unmappedOptionsCount={unmappedOptionsCount}
								automappedOptionsCount={automappedOptionsCount}
								onSetOptionValueMapping={setOptionValueMapping}
							>
								<OptionsMapping />
							</OptionsMappingContextProvider>
						)}
					</>
				) : (
					<GlobalFieldPrompt fieldType={field.type} />
				)}
			</Stack>
			{portalIsMounted &&
				submitButtonPortalEl &&
				createPortal(
					<Button
						testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.copy-values"
						shouldFitContainer
						appearance="primary"
						onClick={handleCopyValuesClick}
						isDisabled={!targetFieldKey || !isFieldOptionsValueMappingValid(mapping)}
					>
						{formatMessage(messages.copyValues)}
					</Button>,
					submitButtonPortalEl,
				)}
		</Stack>
	);
};

const isFieldOptionsValueMappingValid = (fieldOptionsValueMapping: OptionsValueMapping = {}) =>
	Object.values(fieldOptionsValueMapping).every((value) => value?.optionId !== undefined);

const sublabelStyles = xcss({
	color: 'color.text.subtle',
});
