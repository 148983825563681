import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main';
import { useSortedSelectedFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks';
import { useCurrentViewSortBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { Draggable, useDroppableEvents } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { SortFieldComponent } from './field';
import { messages } from './messages';

// prevents infinite loop in useEffect, `sortFields = []` would result in the array reference changing on each render
const DEFAULT_SORT_FIELDS: SortField[] = [];

export const ConfigSort = () => {
	const { formatMessage } = useIntl();

	const { setPermanentSortFieldsOfCurrentView } = useViewActions();
	const fields = useSortedSelectedFields();
	const sortFields = useCurrentViewSortBy() ?? DEFAULT_SORT_FIELDS;

	const [sortFieldsLocal, setSortFieldsLocal] = useState<SortField[]>([]);
	const [fieldsLocal, setfieldsLocal] = useState<Field[]>([]);

	// Optimistically save sortFields.
	useEffect(() => {
		setSortFieldsLocal(sortFields);
	}, [sortFields]);

	// Optimistically save fields.
	useEffect(() => {
		setfieldsLocal(fields);
	}, [fields]);

	const unusedFields = useMemo(() => {
		const usedFieldKeys = new Set(sortFieldsLocal.map((sf) => sf.fieldKey));
		const ret = fieldsLocal.filter((f: Field) => !usedFieldKeys.has(f.key));

		ret.sort((a, b) => a.label.localeCompare(b.label));
		return ret;
	}, [fieldsLocal, sortFieldsLocal]);

	const onAddNewField = (field: Field, asc = true) => {
		const newFields = [...sortFieldsLocal, { fieldKey: field.key, asc }];
		onSave(newFields);
		fireCompoundAnalyticsEvent.RightSidebarViewSortFieldAdded();
	};

	const onSave = useCallback(
		(newSortFields: SortField[]) => {
			experience.headerView.viewSort.start();
			setPermanentSortFieldsOfCurrentView(
				newSortFields,
				() => {
					experience.headerView.viewSort.success();
				},
				(error?: Error) => {
					experience.headerView.viewSort.failure(error);
				},
			);
		},
		[setPermanentSortFieldsOfCurrentView],
	);

	const onReset = useCallback(() => {
		experience.headerView.viewSort.start();
		setPermanentSortFieldsOfCurrentView(
			undefined,
			() => {
				experience.headerView.viewSort.success();
			},
			(error?: Error) => {
				experience.headerView.viewSort.failure(error);
			},
		);
	}, [setPermanentSortFieldsOfCurrentView]);

	const onToggleDirection = (fieldKey: FieldKey) => {
		const newFields = sortFieldsLocal.map((sortField) => {
			if (fieldKey === sortField.fieldKey) {
				return {
					...sortField,
					asc: !sortField.asc,
				};
			}

			return sortField;
		});
		// Optimistic save
		setSortFieldsLocal(newFields);
		onSave(newFields);
		fireCompoundAnalyticsEvent.RightSidebarViewSortDirectionChanged();
	};

	const onChangeField = (field: Field, fieldKey: FieldKey) => {
		const newFields = sortFieldsLocal.map((sortField) => {
			if (fieldKey === sortField.fieldKey) {
				return {
					fieldKey: field.key,
					asc: sortField.asc,
				};
			}
			return sortField;
		});
		// Optimistic save
		setSortFieldsLocal(newFields);
		onSave(newFields);
		fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsChanged();
	};

	const onClearSort = (fieldKey: FieldKey) => {
		const newFields = sortFieldsLocal.filter(({ fieldKey: key }) => key !== fieldKey);
		// Clear view sort if no sort fields left update otherwise
		if (newFields.length) {
			onSave(newFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldRemoved();
		} else {
			onReset();
			fireCompoundAnalyticsEvent.RightSidebarViewSortReset();
		}
	};

	const handleSort = useCallback(
		({ srcIdx, dstIdx }: { srcIdx: number; dstIdx: number }) => {
			// outside the sortFieldsLocal list
			if (dstIdx < 0 || dstIdx >= sortFieldsLocal.length + 1) {
				return;
			}

			const newSortFields = [...sortFieldsLocal];
			const [removed] = newSortFields.splice(srcIdx, 1);
			newSortFields.splice(dstIdx, 0, removed);

			// Optimistic save
			setSortFieldsLocal(newSortFields);
			// Save to the server
			onSave(newSortFields);
			fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsReordered();
		},
		[onSave, sortFieldsLocal],
	);

	const onSort = useCallback(
		({ srcId, dstId, edge }: { srcId: string; dstId: string; edge: Edge }) => {
			const srcIdx = sortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === srcId);
			const dstIdx = sortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === dstId);

			let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
			dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;

			handleSort({ srcIdx, dstIdx: dstIdx + dstAdj });
		},
		[handleSort, sortFieldsLocal],
	);

	useDroppableEvents({
		onSort,
	});

	return (
		<Container>
			<ConfigSortHelpText>{formatMessage(messages.helpText)}</ConfigSortHelpText>
			<Controls>
				{sortFieldsLocal.map(({ asc, fieldKey }, idx) => {
					const selectedField = fieldsLocal.find(({ key }) => fieldKey === key);
					const labelMessage = idx ? messages.sortThenLabel : messages.sortFirstLabel;
					return (
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						<Draggable key={fieldKey} id={fieldKey as string}>
							<SortFieldComponent
								key={fieldKey}
								selectedField={selectedField}
								asc={asc}
								fields={unusedFields}
								label={formatMessage(labelMessage)}
								onClearSort={() => onClearSort(fieldKey)}
								onChangeField={(newField) => onChangeField(newField, fieldKey)}
								onClickDirection={() => onToggleDirection(fieldKey)}
							/>
						</Draggable>
					);
				})}
				<AddNewSortFieldComponentWrapper>
					{!!unusedFields.length && (
						<SortFieldComponent
							fields={unusedFields}
							asc
							label={formatMessage(
								sortFieldsLocal.length ? messages.sortThenLabel : messages.sortFirstLabel,
							)}
							onChangeField={(newField) => onAddNewField(newField)}
							onClickDirection={noop}
						/>
					)}
				</AddNewSortFieldComponentWrapper>
			</Controls>
			<Actions>
				{!!sortFieldsLocal.length && (
					<Button
						id="polaris-ideas.ui.view-controls.config-sort.reset-sort-button"
						appearance="subtle-link"
						onClick={onReset}
					>
						{formatMessage(messages.resetButton)}
					</Button>
				)}
			</Actions>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddNewSortFieldComponentWrapper = styled.div({
	paddingLeft: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="sort-field-container-a7G5"]': {
		backgroundColor: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="drag-handle-68Hh"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'none !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'start',
	paddingTop: token('space.100', '8px'),
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Controls = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Actions = styled.div({
	padding: `0 ${token('space.200', '16px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		margin: '5px 5px 5px 0',
		padding: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		span: {
			margin: 0,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigSortHelpText = styled.p({
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')} 10px`,
});
