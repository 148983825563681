import React, { useCallback, useState } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewDescription,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { CollectionDetails } from '@atlassian/jira-polaris-component-collection-details/src/ui/index.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { IssuelessAdfController } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { EditViewDescriptionOverlay } from './edit-view-description-overlay';
import messages from './messages';
import { DescriptionReadView } from './read-view';

export const ViewDescription = () => {
	const { formatMessage } = useIntl();
	const [isEditing, setIsEditing] = useState(false);
	const description = useCurrentViewDescription();
	const { setUnsavedChanges, changeViewDescription } = useViewActions();
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const isCollectionView = useIsCollectionView();
	const environmentContainer = useEnvironmentContainer();
	const collectionUUID = isCollectionView ? environmentContainer?.id : undefined;

	const handleEdit = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'editDescription',
		);
		setIsEditing(true);
	}, [createAnalyticsEvent]);

	const handleFinishEditing = useCallback(() => {
		setIsEditing(false);
		setUnsavedChanges(false);
	}, [setUnsavedChanges]);

	const handleCancelEdit = useCallback(() => {
		handleFinishEditing();
	}, [handleFinishEditing]);

	const handleSave = useCallback(
		(changedDescription: ADF) => {
			experience.headerView.updateViewDescription.start();

			changeViewDescription(
				changedDescription,
				() => {
					experience.headerView.updateViewDescription.success();
				},
				(error?: Error) => {
					if (isClientFetchError(error)) {
						experience.headerView.updateViewDescription.abort(error);
						return;
					}

					experience.headerView.updateViewDescription.failure(error);
				},
			);
			handleFinishEditing();
		},
		[changeViewDescription, handleFinishEditing],
	);

	return (
		<Stack space="space.100">
			<Heading size="xsmall">{formatMessage(messages.aboutThisView)}</Heading>
			<IssuelessAdfController projectId={projectId} projectKey={projectKey}>
				{isEditing ? (
					<EditViewDescriptionOverlay
						onCancel={handleCancelEdit}
						onSave={handleSave}
						onDirty={setUnsavedChanges}
						description={description}
					/>
				) : (
					<DescriptionReadView
						description={description}
						onEdit={handleEdit}
						isEditingAllowed={
							ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews
						}
					/>
				)}
			</IssuelessAdfController>
			{isCollectionView && <CollectionDetails collectionUUID={collectionUUID} origin="view" />}
		</Stack>
	);
};
