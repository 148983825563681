import { useCallback } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { transformGICPayload } from '@atlassian/jira-new-issue-create-trigger/src/utils';
import { POLARIS_CREATE_DELIVERY_ISSUE } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { OnRequiredFields } from '@atlassian/jira-polaris-remote-issue/src/common/types.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';

export const useRequiredFieldsDeliveryTicketCreation = (): OnRequiredFields => {
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { updateIssueDescription } = useIssueRemote();

	return useCallback(
		({ project, issuetype, ...otherFields }, onClose) =>
			new Promise((resolve, reject) =>
				openIssueCreateModal({
					displayOnlyRequiredFields: true,
					// @ts-expect-error - TS2345 - types need to be fixed in @atlassian/jira-new-issue-create-trigger
					payload: transformGICPayload({
						prefilledFields: {
							pid: project.id,
							issuetype: issuetype.id,
							...otherFields,
						},
					}),
					onClose,
					triggerPointKey: POLARIS_CREATE_DELIVERY_ISSUE,
					onIssueCreate: (data) => {
						const { id, key } = data?.createdIssueDetails ?? {};
						if (id && key) {
							if (otherFields.description) {
								return updateIssueDescription({
									issueKey: key,
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									description: otherFields.description as ADF,
								})
									.then(() => resolve({ id, key }))
									.catch(() => reject(new Error('Failed to update description')));
							}
							return resolve({ id, key });
						}

						return reject(
							new Error('Something wrong happened when creating an issue using GIC modal'),
						);
					},
				}),
			),
		[openIssueCreateModal, updateIssueDescription],
	);
};
