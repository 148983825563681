import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch';
import type { JiraIssueFields } from '../../../common/types';
import { fetchRequiredFieldKeys } from '../create-issue-metadata';
import { getBadRequestErrorMessage } from './utils';

const BATCH_SIZE = 5;
const ISSUE_API_URL = '/rest/api/3/issue';
const ISSUE_LINK_API_URL = '/rest/api/2/issueLink';

export type CreateIssueRequest = (fields: JiraIssueFields) => Promise<{ id: string; key: string }>;
export type OnRequiredFields = CreateIssueRequest;

export type CreateAndLinkIssueRequest = {
	ideaIssue: {
		localIssueId: string;
		key: string;
		summary: string;
		description?: ADF;
	};
	projectId: string;
	issueTypeId: string;
	issueLinkTypeId: string | undefined;
	direction: 'outward' | 'inward';
	epicNameFieldKey: string | undefined;
	onIssueCreated: (arg1: string, arg2: string, arg3: string) => void;
	onIssueLinked?: (arg1: string, arg2: string) => void;
	onOperationFailed: (arg1: string, arg2: Error) => void;
	onRequiredFields: OnRequiredFields;
};

export type BasicLinkIssueRequest = {
	issueLinkTypeId: string | undefined;
	inwardIssueKey: string;
	outwardIssueKey: string;
};

export const linkIssue = ({
	issueLinkTypeId,
	inwardIssueKey,
	outwardIssueKey,
}: BasicLinkIssueRequest) =>
	performPostRequest(ISSUE_LINK_API_URL, {
		body: JSON.stringify({
			type: { id: issueLinkTypeId },
			inwardIssue: { key: inwardIssueKey },
			outwardIssue: { key: outwardIssueKey },
		}),
	});

export type CreateAndLinkIssuesRequest = {
	ideaIds: string[];
	jiraKeys: string[];
	summaries: (string | undefined)[];
	projectId: string;
	issueTypeId: string;
	issueLinkTypeId: string | undefined;
	epicNameFieldKey: string | undefined;
	onIssueCreated: (arg1: string, arg2: string) => void;
	onIssueLinked?: (arg1: string, arg2: string) => void;
	onOperationFailed: (arg1: string, arg2: Error) => void;
	onRequireFieldsMissing: () => void;
	onRequiredFields: OnRequiredFields;
};

const hasEmptyRequiredFields = (
	requiredFieldKeys: string[],
	epicNameFieldKey?: string,
): boolean => {
	const REQUIRED_FIELD_WHITELIST = [
		'reporter', // auto-filled in by backend create
		'issuetype', // supplied
		'project', // supplied
		'summary', // supplied
		epicNameFieldKey, // supplied
	];

	return requiredFieldKeys.some((fieldKey) => !REQUIRED_FIELD_WHITELIST.includes(fieldKey));
};

export const createAndLinkIssues = ({
	ideaIds,
	jiraKeys,
	summaries,
	projectId,
	issueTypeId,
	issueLinkTypeId,
	epicNameFieldKey,
	onIssueCreated,
	onIssueLinked,
	onOperationFailed,
	onRequireFieldsMissing,
	onRequiredFields,
}: CreateAndLinkIssuesRequest) => {
	fetchRequiredFieldKeys(projectId, issueTypeId).then((requiredFieldKeys) => {
		const getAdditionalFields = (summary: string) => {
			if (epicNameFieldKey !== undefined && requiredFieldKeys.includes(epicNameFieldKey)) {
				return { [epicNameFieldKey]: summary };
			}
			return {};
		};

		// has required fields other than summary, issuetype etc
		const hasRequiredFields = hasEmptyRequiredFields(requiredFieldKeys, epicNameFieldKey);

		if (hasRequiredFields) {
			if (ideaIds.length > 1) {
				onRequireFieldsMissing();
				return;
			}
			createAndLinkIssue({
				ideaIssue: {
					localIssueId: ideaIds[0],
					key: jiraKeys[0],
					// @ts-expect-error - TS2345 - Argument of type string | undefined is not assignable to parameter of type string.
					summary: summaries[0],
				},
				epicNameFieldKey,
				issueLinkTypeId,
				direction: 'outward',
				issueTypeId,
				projectId,
				onIssueCreated,
				onIssueLinked,
				onOperationFailed,
				onRequiredFields,
			});
			return;
		}

		runInBatch(
			ideaIds.map(
				(id, i) => () =>
					performPostRequest(ISSUE_API_URL, {
						body: JSON.stringify({
							fields: {
								issuetype: { id: issueTypeId },
								project: { id: projectId },
								summary: summaries[i],
								...getAdditionalFields(summaries[i] ?? ''),
							},
						}),
					})
						// @ts-expect-error - TS2345 - Argument of type '(createResponse: any) => false | Promise<void>' is not assignable to parameter of type '(value: any) => void | PromiseLike<void>'.
						.then((createResponse) => {
							onIssueCreated(id, createResponse.key);
							return (
								issueLinkTypeId !== undefined &&
								linkIssue({
									issueLinkTypeId,
									inwardIssueKey: createResponse.key,
									outwardIssueKey: jiraKeys[i],
								})
									.then(() => {
										onIssueLinked && onIssueLinked(id, createResponse.id);
									})
									.catch((error) => onOperationFailed(id, error))
							);
						})
						.catch((error) => onOperationFailed(id, error)),
			),
			BATCH_SIZE,
		);
	});
};

export type LinkIssueRequest = {
	issueLinkTypeId: string | undefined;
	direction: 'outward' | 'inward';
	deliveryIssue: {
		id: string;
		key: string;
	};
	ideaIssue: {
		localIssueId: string;
		key: string;
	};
	onIssueLinked?: (arg1: string, arg2: string) => void;
	onOperationFailed: (arg1: string, arg2: Error) => void;
};

export const linkIssueInJira = ({
	issueLinkTypeId,
	direction,
	deliveryIssue,
	ideaIssue,
	onIssueLinked,
	onOperationFailed,
}: LinkIssueRequest) => {
	const inwardIssueKey = direction === 'outward' ? deliveryIssue.key : ideaIssue.key;
	const outwardIssueKey = direction === 'outward' ? ideaIssue.key : deliveryIssue.key;

	return (
		issueLinkTypeId !== undefined &&
		linkIssue({
			issueLinkTypeId,
			inwardIssueKey,
			outwardIssueKey,
		})
			.then(() => {
				onIssueLinked && onIssueLinked(ideaIssue.localIssueId, deliveryIssue.id);
			})
			.catch((error) => onOperationFailed(ideaIssue.localIssueId, error))
	);
};

const createIssueInline: CreateIssueRequest = (fields: JiraIssueFields) =>
	performPostRequest(ISSUE_API_URL, {
		body: JSON.stringify({ fields }),
	}).then((createResponse) => ({
		key: createResponse.key,
		id: createResponse.id,
	}));

export const createAndLinkIssue = ({
	ideaIssue,
	projectId,
	issueTypeId,
	issueLinkTypeId,
	direction,
	epicNameFieldKey,
	onIssueCreated,
	onIssueLinked,
	onOperationFailed,
	onRequiredFields,
}: CreateAndLinkIssueRequest) => {
	fetchRequiredFieldKeys(projectId, issueTypeId).then((requiredFieldKeys) => {
		const getAdditionalFields = (summaryValue: string) => {
			if (epicNameFieldKey !== undefined && requiredFieldKeys.includes(epicNameFieldKey)) {
				return { [epicNameFieldKey]: summaryValue };
			}
			return {};
		};

		const fields: JiraIssueFields = {
			issuetype: { id: issueTypeId },
			project: { id: projectId },
			summary: ideaIssue.summary,
			...getAdditionalFields(ideaIssue.summary),
		};

		if (ideaIssue.description) {
			fields.description = ideaIssue.description;
		}

		// has required fields other than summary, issuetype etc
		const hasRequiredFields = hasEmptyRequiredFields(requiredFieldKeys, epicNameFieldKey);

		const createIssue = hasRequiredFields ? onRequiredFields : createIssueInline;

		createIssue(fields)
			// @ts-expect-error - TS2345 - Argument of type '(createResponse: any) => false | Promise<void>' is not assignable to parameter of type '(value: any) => void | PromiseLike<void>'.
			.then((deliveryIssue) => {
				onIssueCreated(ideaIssue.localIssueId, deliveryIssue.key, deliveryIssue.id);

				return linkIssueInJira({
					issueLinkTypeId,
					direction,
					deliveryIssue,
					ideaIssue,
					onIssueLinked,
					onOperationFailed,
				});
			})
			.catch((error) => {
				const validationErrorMessage = getBadRequestErrorMessage(error);
				if (validationErrorMessage) {
					onRequiredFields(fields);
				} else {
					onOperationFailed(ideaIssue.localIssueId, error);
				}
			});
	});
};
