import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari';
import { LAST_VIEWED_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/last-viewed/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ViewNotFoundError } from '../../../common/errors/view-not-found-error.tsx';

type User = {
	picture: string;
	accountId: AccountId | string;
	name: string;
};

export type PolarisViewLastViewed = {
	timestamp: Date;
	aaid: string;
	account?: User;
	isCurrentUser?: boolean;
};

export const GET_VIEW_LAST_VIEWED_QUERY = gqlTagPolaris`
query jira_polaris_GetViewLastViewedQuery($view: ID!) {
    polarisView(id: $view) {
        id
        lastViewed {
            ...PolarisViewLastViewed
        }
    }
  }

${LAST_VIEWED_FRAGMENT}`;

export type ViewLastViewed = {
	subject: Ari;
	lastViewed: PolarisViewLastViewed[];
};

export const fetchViewLastViewed = async (
	apolloClient: PolarisApolloClient,
	viewId: Ari,
): Promise<ViewLastViewed> => {
	const result = await apolloClient.query({
		query: GET_VIEW_LAST_VIEWED_QUERY,
		variables: {
			view: viewId,
		},
		fetchPolicy: 'network-only',
	});

	const { data, errors } = result;
	if (errors !== undefined) {
		throw new Error(`last-viewed.fetch-error:${errors.map((e) => e.message).join(', ')}`);
	}

	if (!data || data.polarisView === undefined || data.polarisView === null) {
		throw new ViewNotFoundError('last-viewed.fetch-error: no data or no data node');
	} else {
		return {
			subject: viewId,
			lastViewed: data.polarisView.lastViewed || [],
		};
	}
};
