import gqlTagPolaris from 'graphql-tag';
import { INSIGHT_FRAGMENT_WITH_FULL_SNIPPET } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/insight/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { UpdateInsight, UpdateInsightVariables, UpdatePolarisSnippetInputs } from './types';

const UPDATE_INSIGHT_QUERY = gqlTagPolaris`
mutation jira_polaris_UpdateInsight($id: ID!, $update: UpdatePolarisInsightInput!) {
  updatePolarisInsight(id: $id, update: $update) {
    success
    errors {
        message
    }
    node {
      ...Insight
    }
  }
}
${INSIGHT_FRAGMENT_WITH_FULL_SNIPPET}
`;

export const updateInsight = async (
	client: PolarisApolloClient,
	id: string,
	description?: unknown,
	snippets?: UpdatePolarisSnippetInputs,
) => {
	const result = await client.mutate<UpdateInsight, UpdateInsightVariables>({
		mutation: UPDATE_INSIGHT_QUERY,
		variables: {
			id,
			update: {
				description,
				snippets,
			},
		},
	});

	if (!result.data?.updatePolarisInsight?.success) {
		throw new Error('polaris-insights.update-insight-error: no error but not successful either');
	}

	if (!result.data.updatePolarisInsight.node) {
		throw new Error('polaris-insights.update-insight-error: no data returned');
	}

	return result.data.updatePolarisInsight.node;
};
