import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { LoadingButton } from '@atlaskit/button';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { SnippetRefreshStatus } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import { useAdgCompliantRelativeDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { UnfurlStatus } from '../../../controllers/unfurl/utils';
import { isAuthRefreshError, isRefreshError, isPendingRefresh } from '../../common/refresh';
import { messages } from './messages';

type PolarisRefreshTooltipProps = {
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
};

export const PolarisRefreshTooltipContent = ({ refresh, updated }: PolarisRefreshTooltipProps) => {
	const { formatMessage } = useIntl();
	const lastRefreshDateString = useAdgCompliantRelativeDate(refresh?.last);
	const lastUpdateDateString = useAdgCompliantRelativeDate(updated);

	const hasError = isRefreshError(refresh);
	const isPending = isPendingRefresh(refresh);

	if (isPending) {
		return (
			<>
				<div>{formatMessage(messages.refreshInProgress)}</div>
				{lastUpdateDateString !== undefined && (
					<div>
						{formatMessage(messages.refreshLastSuccess, {
							date: lastUpdateDateString,
						})}
					</div>
				)}
			</>
		);
	}

	if (hasError) {
		return (
			<>
				<div>{formatMessage(messages.refreshError)}</div>
				{lastUpdateDateString !== undefined && (
					<div>
						{formatMessage(messages.refreshLastSuccess, {
							date: lastUpdateDateString,
						})}
					</div>
				)}
				{lastRefreshDateString !== undefined && (
					<div>
						{formatMessage(messages.refreshLastAttempt, {
							date: lastRefreshDateString,
						})}
					</div>
				)}
			</>
		);
	}

	if (lastUpdateDateString !== undefined) {
		return <>{formatMessage(messages.refreshLastSuccess, { date: lastUpdateDateString })}</>;
	}

	return null;
};

type RefreshDropdownItemProps = {
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
	onSnippetRefreshRequested?: () => void;
};

export const RefreshDropdownItem = ({
	refresh,
	updated,
	onSnippetRefreshRequested,
}: RefreshDropdownItemProps) => {
	const { formatMessage } = useIntl();
	const manualRefresh = useCallback(() => {
		if (onSnippetRefreshRequested !== undefined) {
			onSnippetRefreshRequested();
		}
	}, [onSnippetRefreshRequested]);

	return (
		<Tooltip
			content={<PolarisRefreshTooltipContent refresh={refresh} updated={updated} />}
			position="bottom"
		>
			<DropdownItem isDisabled={isPendingRefresh(refresh)} onClick={manualRefresh}>
				{formatMessage(messages.refreshNowMenuEntry)}
			</DropdownItem>
		</Tooltip>
	);
};

type Props = {
	refresh?: SnippetRefreshStatus;
	updated: string | null;
	showRefreshIndicator: boolean;
};

export const PolarisSnippetRefreshIndicator = ({
	refresh,
	updated,
	showRefreshIndicator,
}: Props) => {
	const isPending = isPendingRefresh(refresh);
	return (
		<>
			{!isPending && isAuthRefreshError(refresh) ? <UnfurlStatus /> : null}
			{isRefreshError(refresh) || isPending ? (
				<Tooltip
					content={<PolarisRefreshTooltipContent refresh={refresh} updated={updated} />}
					position="top"
				>
					{isPending ? (
						<>
							{showRefreshIndicator && (
								<RefreshStatusWrapper>
									<LoadingButton
										spacing="none"
										appearance="subtle"
										isLoading={isPending}
										iconBefore={
											// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
											<ErrorIcon size="medium" label="error" primaryColor={colors.R300} />
										}
									/>
								</RefreshStatusWrapper>
							)}
						</>
					) : (
						<RefreshStatusWrapper>
							{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
							<ErrorIcon size="medium" label="error" primaryColor={colors.R300} />
						</RefreshStatusWrapper>
					)}
				</Tooltip>
			) : null}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RefreshStatusWrapper = styled.div({
	display: 'flex',
	marginRight: token('space.100', '8px'),
	alignItems: 'center',
});
