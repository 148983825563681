import gqlTagPolaris from 'graphql-tag';
import { FULL_SNIPPET_FRAGMENT } from '../snippet';

export const INSIGHT_FRAGMENT_WITH_FULL_SNIPPET = gqlTagPolaris`
  fragment Insight on PolarisInsight {
    id
    aaid
    container
    description
    created
    updated

    account {
      accountId
      name
      picture
    }

    play {
      id
    }

    snippets {
      ...Snippet
    }
  }
  ${FULL_SNIPPET_FRAGMENT}
`;
