import React, { type ReactNode, useMemo } from 'react';
import { RemoteContextProviders } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/main.tsx';
import { createSharingFieldRemoteProvider } from '@atlassian/jira-polaris-remote-field/src/main.tsx';
import { GlobalFieldSharingRemoteProvider } from '@atlassian/jira-polaris-remote-global-field';
import { NoopInsightRemoteProvider } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { createSharingIssueRemoteProvider } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { createSharingMediaRemoteProvider } from '@atlassian/jira-polaris-remote-media/src/controllers/index.tsx';
import { createPlayContributionSharingProvider } from '@atlassian/jira-polaris-remote-play-contribution/src/main.tsx';
import { SharingRankingRemoteProvider } from '@atlassian/jira-polaris-remote-ranking/src/controllers/index.tsx';
import { createSharingViewRemoteProvider } from '@atlassian/jira-polaris-remote-view/src/main.tsx';

const STATIC_PROVIDERS = [
	SharingRankingRemoteProvider,
	NoopInsightRemoteProvider,
	GlobalFieldSharingRemoteProvider,
];

type SharingAppRemoteContextProps = {
	apolloClientHash?: string;
	viewId: string;
	children: ReactNode;
};

export const SharingAppRemoteContext = ({ children, viewId }: SharingAppRemoteContextProps) => {
	const providers = useMemo(
		() => [
			...STATIC_PROVIDERS,
			createSharingMediaRemoteProvider(viewId),
			createPlayContributionSharingProvider(),
			createSharingViewRemoteProvider(viewId),
			createSharingFieldRemoteProvider(viewId),
			createSharingIssueRemoteProvider(viewId),
		],
		[viewId],
	);
	return <RemoteContextProviders providers={providers}>{children}</RemoteContextProviders>;
};
