import has from 'lodash/has';
import gqlTagPolaris from 'graphql-tag';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { createAri } from '@atlassian/jira-platform-ari';
import { INSIGHT_FRAGMENT_WITH_FULL_SNIPPET } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/insight/index.tsx';
import {
	FULL_SNIPPET_FRAGMENT,
	LIGHT_SNIPPET_FRAGMENT,
} from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/snippet/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type {
	GetInsightsWithErrorsQuery,
	InsightContext,
	PolarisErrorInsight,
	PolarisProjectConfigInsight,
	PolarisProjectConfigInsightsQuery,
	PolarisInsightsQuery,
	ArchivedMode,
} from './types';

export const GET_INSIGHTS_QUERY = gqlTagPolaris`
  query jira_polaris_ReadInsights($project: ID!, $container: ID) {
    polarisInsights(project: $project, container: $container) {
      ...Insight
    }
    polarisLabels(projectID: $project) {
      label
      count
    }
  }
  ${INSIGHT_FRAGMENT_WITH_FULL_SNIPPET}
`;

const GET_INSIGHTS_WITH_ERROR_QUERY = gqlTagPolaris`
query jira_polaris_ReadInsightsWithErrors($project: ID!) {
    polarisInsightsWithErrors(project: $project) {
        id
        account {
            accountId
            name
            picture
        }
        snippets {
            ...Snippet
        }
        play {
          id
        }
        description
        created
        updated
        container
    }
}
${FULL_SNIPPET_FRAGMENT}
`;

const PROJECT_CONFIG_INSIGHTS_QUERY = gqlTagPolaris`
  query jira_polaris_GetProjectConfigInsightsQuery($project: ID!, $archivedMode: ArchivedMode!) {
    polarisProject(id: $project, skipRefresh: true) {
      id
      insights(archivedMode: $archivedMode) {
        id
        container
        updated
        created
        snippets {
          ...SnippetLight
        }
        account {
          accountId
        }
        play {
          id
        }
      }
    }
  }
  ${LIGHT_SNIPPET_FRAGMENT}
`;

export const getInsightsWithErrors = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
): Promise<Readonly<PolarisErrorInsight[]>> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: String(projectId),
	});

	return client
		.query<GetInsightsWithErrorsQuery>({
			query: GET_INSIGHTS_WITH_ERROR_QUERY,
			variables: {
				project: projectAri.toString(),
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (!result.data || !has(result.data, 'polarisInsightsWithErrors')) {
				throw new Error('polaris-insights.fetch-error: no data or no data node');
			}

			return result.data.polarisInsightsWithErrors || [];
		});
};

export const fetchInsights = async (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	issueId?: string,
): Promise<InsightContext> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});
	const containerAri =
		issueId !== undefined
			? createAri({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'issue',
					resourceId: issueId,
				})
			: undefined;

	const result = await client.query<PolarisInsightsQuery>({
		query: GET_INSIGHTS_QUERY,
		variables: {
			project: projectAri.toString(),
			container: containerAri?.toString(),
		},
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	if (
		result.errors !== undefined &&
		// ignore polarisConnectAppByClientID errors in case it was deleted
		result.errors.filter(
			(err) => !err.path || (err.path && err.path[0] !== 'polarisConnectAppByClientID'),
		).length > 0
	) {
		throw new Error(
			`polaris-insights.fetch-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data || result.data.polarisInsights === undefined) {
		throw new Error('polaris-insights.fetch-error: no data or no data node');
	}

	const insights = result.data.polarisInsights == null ? [] : result.data.polarisInsights;
	const labels = result.data.polarisLabels || [];

	return {
		insights,
		labels,
	};
};

export const getProjectConfigInsights = async (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	archivedMode: ArchivedMode,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	fireAnalyticsEvent?: boolean,
): Promise<Readonly<PolarisProjectConfigInsight[]>> => {
	const fetchStart = new Date();

	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: String(projectId),
	});

	const result = await client.query<PolarisProjectConfigInsightsQuery>({
		query: PROJECT_CONFIG_INSIGHTS_QUERY,
		variables: {
			project: projectAri.toString(),
			archivedMode,
		},
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	if (!has(result, 'data.polarisProject.insights')) {
		throw new Error('polaris-insights.fetch-error: no data or no data node');
	}

	const insights = result.data?.polarisProject?.insights ?? [];

	if (fireAnalyticsEvent === true) {
		fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'JpdFetchInsights succeeded', {
			cloudId,
			projectId,
			durationMs: new Date().getTime() - fetchStart.getTime(),
			insights: insights.length,
			insights_snippets: insights.reduce((acc, insight) => acc + insight.snippets.length, 0),
		});
	}

	return insights;
};
