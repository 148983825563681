import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LinkedIssue } from './types';
import { fetchLinkedIssuesArchived } from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { LinkedIssue } from './types';

export const getLinkedIssuesArchived = (
	issueIds: number[],
	cloudId: CloudId,
): Promise<LinkedIssue[]> =>
	isEmpty(issueIds)
		? Promise.resolve([])
		: fetchLinkedIssuesArchived(
				`id IN (${join(issueIds, ',')})`,
				issueIds.length,
				cloudId,
			).toPromise();
