import { useEffect, useState } from 'react';
import { useOpenRightSidebarOnFilterRemoveTmpFieldKey } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarFilterTmpFieldKey } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { useAllowedFiltersFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export const useTmpFiltersKeysState = (canAddFilters = true, fieldsToSkip: Field[] = []) => {
	const [tmpFieldKey] = useRightSidebarFilterTmpFieldKey();
	const openRightSidebarOnFilterRemoveTmpFieldKey = useOpenRightSidebarOnFilterRemoveTmpFieldKey();
	const allowedFiltersFields = useAllowedFiltersFields();

	const [tmpFiltersKeys, setTmpFiltersKeys] = useState<FieldKey[]>(() => {
		if (!tmpFieldKey || !canAddFilters) {
			return [];
		}

		const isSkippedField = fieldsToSkip.some((field) => field.key === tmpFieldKey);
		if (isSkippedField) {
			return [];
		}

		const notAvailableFieldKey = !allowedFiltersFields.some((field) => field.key === tmpFieldKey);
		if (notAvailableFieldKey) {
			return [];
		}

		return [tmpFieldKey];
	});

	useEffect(() => {
		openRightSidebarOnFilterRemoveTmpFieldKey();
	}, [openRightSidebarOnFilterRemoveTmpFieldKey]);

	return {
		tmpFiltersKeys,
		setTmpFiltersKeys,
		tmpFieldKey,
	};
};
