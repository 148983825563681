import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewNotFoundError } from '../../../common/errors/view-not-found-error.tsx';

const DELETE_VIEW_MUTATION = gqlTagPolaris`
mutation jira_polaris_DeleteView($id: ID!) {
    deletePolarisView(id: $id) {
      success
      errors {
          message
      }
    }
  }
`;

export const deleteView = async (apolloClient: PolarisApolloClient, viewId: Ari): Promise<void> => {
	const result = await apolloClient.mutate({
		mutation: DELETE_VIEW_MUTATION,
		variables: {
			id: viewId,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.delete-view-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.deletePolarisView.success) {
		throw new ViewNotFoundError(
			'project-config.delete-view-error: no error but not successful either',
		);
	}
};
