import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

const DELETE_VIEW_SET_MUTATION = gqlTagPolaris`
mutation jira_polaris_DeleteViewSet($id: ID!) {
    deletePolarisViewSet(id: $id) {
      success
      errors {
        message
      }
    }
  }
`;

export const deleteViewSet = async (
	apolloClient: PolarisApolloClient,
	viewSetId: Ari,
): Promise<void> => {
	const result = await apolloClient.mutate({
		mutation: DELETE_VIEW_SET_MUTATION,
		variables: {
			id: viewSetId,
		},
	});

	if (result.errors) {
		throw new Error(
			`project-config.delete-view-set-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.deletePolarisViewSet.success) {
		throw new Error('project-config.delete-view-set-error: no error but not successful either');
	}
};
