import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { DATE_FORMAT_DFNS } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { FieldComponentProps } from '../types';

export const DateField = ({ isEditable, onUpdate, ...fieldProps }: FieldComponentProps) => {
	const locale = useLocale();

	// value is expected in ISO format YYYY-MM-DD and needs to be converted properly
	const onChange = useCallback(
		(val: string) => {
			if (val === '') {
				onUpdate(undefined);
			} else {
				onUpdate(val);
			}
		},
		[onUpdate],
	);

	return (
		<DateFieldWrapper>
			<DatePicker
				{...fieldProps}
				defaultValue=""
				isDisabled={!isEditable}
				onChange={onChange}
				locale={locale}
				dateFormat={DATE_FORMAT_DFNS}
				placeholder=""
				spacing="compact"
			/>
		</DateFieldWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateFieldWrapper = styled.div({
	width: '350px',
});
