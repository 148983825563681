import { type ReactElement, useRef, useEffect, useCallback } from 'react';
import uuid from 'uuid';
import { isPageVisible, supportedVisiblityEvent } from '@atlassian/jira-common-page-visibility';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	useAnalyticsEvents,
	fireOperationalAnalyticsDeferred,
} from '@atlassian/jira-product-analytics-bridge';
import { INTERVAL_2M, INITIAL_LOAD_TIMEOUT } from './constants';

const initialTime = Date.now();
const tabId = uuid();

function getTimeInSecondAfter(startTime: number) {
	return Math.ceil((Date.now() - startTime) / 1000);
}

export const TrackActivity = ({
	children,
}: {
	children: ({ onFetch }: { onFetch: (path: string) => void }) => ReactElement;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fetchedUrls = useRef(new Map<string, number>());
	const isActiveUser = useRef(false);
	const tabVisibilityChangedTime = useRef(Date.now());
	const isInitialLoad = useRef(true);
	const onFetch = useCallback((path: string) => {
		if (!ff('polaris-user-activity-metric')) {
			return;
		}
		fetchedUrls.current.set(path, (fetchedUrls.current.get(path) || 0) + 1);
	}, []);
	const onInteraction = useCallback(() => {
		isActiveUser.current = true;
	}, []);
	const onVisibilityChange = useCallback(() => {
		tabVisibilityChangedTime.current = Date.now();
	}, []);

	useEffect(() => {
		if (!ff('polaris-user-activity-metric')) {
			return;
		}

		const track = () => {
			if (!fetchedUrls.current.size) {
				return;
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const searchParams = new URLSearchParams(window.location.search);

			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'jpdUser tracked', {
				isFetchUsed: fetchedUrls.current.size > 0,
				isActiveUser: isActiveUser.current,
				isActiveTab: isPageVisible(),
				isInitialLoad: isInitialLoad.current,
				tabId,
				timeAfterVisibilityChangeInSeconds: getTimeInSecondAfter(tabVisibilityChangedTime.current),
				queryParams: [...searchParams.keys()].reduce(
					(acc, key) =>
						Object.assign(acc, {
							[key]:
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								searchParams.get(key) && /\d+/.test(searchParams.get(key)!)
									? '<param>'
									: searchParams.get(key),
						}),
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					{} as Record<string, string>,
				),
				timeAfterLoadInSeconds: getTimeInSecondAfter(initialTime),
				...(fetchedUrls.current.size > 0
					? { fetchedUrls: Object.fromEntries(fetchedUrls.current) }
					: {}),
			});
			fetchedUrls.current = new Map();
			isActiveUser.current = false;
			isInitialLoad.current = false;
		};

		const initialLoadTimeout = setTimeout(track, INITIAL_LOAD_TIMEOUT);
		const interval = setInterval(track, INTERVAL_2M);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousemove', onInteraction);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mouseup', onInteraction);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener(supportedVisiblityEvent, onVisibilityChange);

		return () => {
			clearInterval(interval);
			clearTimeout(initialLoadTimeout);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousemove', onInteraction);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mouseup', onInteraction);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener(supportedVisiblityEvent, onVisibilityChange);
		};
	}, [createAnalyticsEvent, onInteraction, onVisibilityChange]);

	return children({ onFetch });
};
