import React, { useMemo } from 'react';
import LockLockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import LockUnlockedIcon from '@atlaskit/icon/core/migration/lock-unlocked--unlock-filled';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import messages from './messages';
import type { OptionType } from './select-components';

export const useAccessLevelOptions = (): OptionType[] => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => [
			{
				label: fg('polaris-view-permissions-confluence-align')
					? formatMessage(messages.openOptionVerboseLabel)
					: formatMessage(messages.open),
				description: fg('polaris-view-permissions-confluence-align')
					? undefined
					: formatMessage(messages.openOptionVerboseLabel),
				value: VIEW_ACCESS_LEVELS.OPEN,
				icon: <LockUnlockedIcon label={formatMessage(messages.open)} color="currentColor" />,
			},
			{
				label: fg('polaris-view-permissions-confluence-align')
					? formatMessage(messages.limitedOptionVerboseLabel)
					: formatMessage(messages.limited),
				description: fg('polaris-view-permissions-confluence-align')
					? undefined
					: formatMessage(messages.limitedOptionVerboseLabel),
				value: VIEW_ACCESS_LEVELS.LIMITED,
				icon: <LockLockedIcon label={formatMessage(messages.limited)} color="currentColor" />,
			},
			{
				label: fg('polaris-view-permissions-confluence-align')
					? formatMessage(messages.restrictedOptionVerboseLabel)
					: formatMessage(messages.restricted),
				description: fg('polaris-view-permissions-confluence-align')
					? undefined
					: formatMessage(messages.restrictedOptionVerboseLabel),
				value: VIEW_ACCESS_LEVELS.RESTRICTED,
				icon: (
					<LockLockedIcon
						label={formatMessage(messages.restricted)}
						color={token('color.icon.accent.red')}
					/>
				),
			},
		],
		[formatMessage],
	);
};
