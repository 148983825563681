import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Link from '@atlaskit/link';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Select, { components, createFilter } from '@atlaskit/select';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useCanManageGlobalFields } from '../../../../../../../controllers';
import { FieldOptionItem } from './field-option-item';
import messages from './messages';

export const DONT_COPY_OPTION_ID = 'DONT_COPY';

export type FieldOptionData = NonNullable<Field['options']>[0];

type FieldOptionSelectorProps = {
	field: Field;
	optionId: string | undefined;
	options: Record<string, FieldOptionData>;
	onChange?: (optionId: string | undefined) => void;
};

export const FieldOptionSelector = ({
	optionId,
	options,
	field,
	onChange,
}: FieldOptionSelectorProps) => {
	const { formatMessage } = useIntl();
	const canManageGlobalFields = useCanManageGlobalFields();

	const dontCopyOption = useMemo(
		() => ({
			label: formatMessage(messages.dontCopy),
			value: DONT_COPY_OPTION_ID,
		}),
		[formatMessage],
	);

	const dropdownOptions = useMemo(
		() =>
			Object.values(options).map((option) => ({
				label: option.value,
				value: option.id,
			})),
		[options],
	);

	const selectedOption = useMemo(
		() => [dontCopyOption, ...dropdownOptions].find((option) => option.value === optionId),
		[dontCopyOption, dropdownOptions, optionId],
	);

	const optiopGroups = useMemo(
		() => [
			{
				options: dropdownOptions,
			},
			{
				options: [dontCopyOption],
			},
		],
		[dropdownOptions, dontCopyOption],
	);

	return (
		<Select
			styles={{
				control: (base) => ({
					...base,
					width: '250px',
				}),
				menuPortal: (base) => ({
					...base,
					zIndex: layers.modal,
				}),
				group: (base, props) => {
					const { data } = props;
					if (data.options?.[0]?.value === dontCopyOption.value) {
						return {
							...base,
							borderTop: `1px solid ${token('color.border')}`,
						};
					}

					return {
						...base,
						maxHeight: '205px',
						overflowY: 'auto',
					};
				},
			}}
			options={optiopGroups}
			value={selectedOption}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget={document.body}
			placeholder={formatMessage(messages.selectOption)}
			noOptionsMessage={() =>
				formatMessage(
					canManageGlobalFields
						? messages.noOptionsMessage
						: messages.noOptionsMessageNoPermissions,
				)
			}
			filterOption={createFilter({
				ignoreCase: true,
				ignoreAccents: true,
				trim: true,
				stringify: (option) => option.label,
			})}
			isSearchable
			isClearable={false}
			onChange={(option) => {
				onChange?.(option?.value);
			}}
			components={{
				Option: ({ data, innerProps, isFocused }) => {
					const { onClick, onMouseMove, onMouseOver } = innerProps;

					return (
						<Box
							xcss={[
								optionContentContainerStyles,
								isFocused && optionContentContainerFocusedStyles,
							]}
							onMouseMove={onMouseMove}
							onMouseOver={onMouseOver}
						>
							<Flex xcss={optionContentStyles}>
								<OptionWrapper onClick={onClick}>
									<FieldOptionItem
										fieldKey={field.key}
										optionId={data.value}
										optionValue={data.label}
									/>
								</OptionWrapper>
							</Flex>
						</Box>
					);
				},
				SingleValue: ({ innerProps, ...rest }) => {
					return (
						<components.SingleValue
							innerProps={{
								...innerProps,
								style: {
									minHeight: '16px',
								},
							}}
							{...rest}
						>
							<FieldOptionItem
								fieldKey={field.key}
								optionId={rest.data.value}
								optionValue={rest.data.label}
							/>
						</components.SingleValue>
					);
				},
				MenuList: ({ children }) => {
					return (
						<>
							{children}
							{canManageGlobalFields && (
								<Box padding="space.200" xcss={editGlobalFieldOptionsLinkStyles}>
									<Link
										appearance="subtle"
										href={`/jira/settings/products/jira-product-discovery/global-fields?openFieldConfigurationPanel=${field.key}`}
										target="_blank"
									>
										{formatMessage(messages.editGlobalFieldOptions)}
									</Link>
								</Box>
							)}
						</>
					);
				},
			}}
		/>
	);
};

const editGlobalFieldOptionsLinkStyles = xcss({
	borderTop: `1px solid ${token('color.border')}`,
});

const optionContentStyles = xcss({
	flex: '1 1 auto',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		display: 'flex',
	},
	width: '100%',
});

const optionContentContainerStyles = xcss({
	overflow: 'hidden',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

const optionContentContainerFocusedStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
});
