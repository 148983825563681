import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import { INTERVAL_1M, INTERVAL_5M } from '../../constants';
import type { RequestError, RequestRecord } from '../../types';

const initialTime = Date.now();

const getKeyForRequest = (request: RequestRecord) => `${request.method}:${request.path}`;

const getRequestCount = (requests: RequestRecord[], errorTime: number) =>
	requests.reduce(
		(result, request) => {
			const group =
				request.time && request.time <= errorTime ? `status:${request.status}` : 'status:inflight';
			const key = getKeyForRequest(request);
			return Object.assign(result, {
				[group]: Object.assign(result[group] || {}, {
					[key]: result[group]?.[key] ? result[group]?.[key] + 1 : 1,
				}),
			});
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as Record<string, Record<string, number>>,
	);

function getTimeAfterLoadInSeconds() {
	return Math.ceil((Date.now() - initialTime) / 1000);
}

export function sendAnalytics(
	errors: RequestError[],
	createAnalyticsEvent: CreateUIAnalyticsEvent,
) {
	errors.forEach((error) => {
		const requests1m = error.requests.filter(
			(request) =>
				error.requestWithError !== request &&
				(request.time === undefined ||
					(error.time >= request.time && error.time - INTERVAL_1M <= request.time)),
		);
		const requests5m = error.requests.filter(
			(request) =>
				error.requestWithError !== request &&
				(request.time === undefined ||
					(error.time >= request.time && error.time - INTERVAL_5M <= request.time)),
		);
		fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'jpdFetchError tracked', {
			path: error.requestWithError.path,
			method: error.requestWithError.method,
			status: error.requestWithError.status,
			originPath: error.requestWithError.originPath,
			isActiveTab: error.isActiveTab,
			requests1m: getRequestCount(requests1m, error.time),
			requests5m: getRequestCount(requests5m, error.time),
			timeAfterLoadInSeconds: getTimeAfterLoadInSeconds(),

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			clientUrl: window?.location?.href || 'unknown',
		});
	});
}
