import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_GetViewSets_polarisProject as GetViewSetsResponse,
	jira_polaris_GetViewSets as GetViewSets,
	jira_polaris_GetViewSetsVariables as GetViewSetsVariables,
} from './__generated_apollo__/jira_polaris_GetViewSets';

const GET_VIEWSETS_QUERY = gqlTagPolaris`
query jira_polaris_GetViewSets($id: ID!) {
    polarisProject(id: $id, skipRefresh: true) {
        id
        viewsets {
            id
            type
            viewsets {
                id
                type
                rank
                name
            }
        }
    }
}
`;

export const fetchViewSets = async (
	apolloClient: PolarisApolloClient,
	projectAri: Ari,
): Promise<GetViewSetsResponse> => {
	const { data, errors } = await apolloClient.query<GetViewSets, GetViewSetsVariables>({
		query: GET_VIEWSETS_QUERY,
		variables: {
			id: projectAri,
		},
		fetchPolicy: 'network-only',
	});

	if (errors !== undefined) {
		throw new Error(`project-config.get-viewsets-error:${errors.map((e) => e.message).join(', ')}`);
	}

	if (!data || !data.polarisProject) {
		throw new Error('project-config.get-viewsets-error: no data');
	}

	return data.polarisProject;
};
