import React, { useCallback, useEffect, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button';
import type { EmojiDescription } from '@atlaskit/emoji';
import Form, { Field } from '@atlaskit/form';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalTitle,
	ModalHeader,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type {
	IdeaTemplateData,
	IdeaTemplate,
} from '@atlassian/jira-polaris-common/src/common/types/idea-templates/types';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { IssuelessAdfProvider } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/utils/provider.tsx';
import type { EditorActions } from '@atlassian/jira-polaris-lib-editor/src/ui/editor/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { TemplateColorPicker } from '../common/color-picker/index.tsx';
import { TemplateEmojiPicker } from '../common/emoji-picker/index.tsx';
import { messages } from './messages';

type TemplateFormProps = {
	templateData?: IdeaTemplate;
	onClose: () => void;
	onSave: (template: IdeaTemplateData) => void;
};

export const TemplateForm = ({
	onClose,
	onSave,
	// @ts-expect-error - TS2322 - Type '{}' is not assignable to type 'IdeaTemplate'.
	templateData = {},
}: TemplateFormProps) => {
	const { formatMessage } = useIntl();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const { title, description, template, emoji, color } = templateData;

	const formTitle = isEmpty(templateData)
		? formatMessage(messages.createTemplate)
		: formatMessage(messages.updateTemplate);

	const [emojiId, setEmojiId] = useState(emoji);
	const selectedEmoji = useEmoji(emojiId);
	const [selectedColor, setSelectedColor] = useState(color);
	const [safeDescription, setSafeDescription] = useState<ADF | undefined>(undefined);

	const editorPortalRef = useRef<HTMLDivElement>(null);
	const [editorActions, setEditorActions] = useState<EditorActions | undefined>();
	const isEditorAiEnabled = useEditorAiEnabled();

	useEffect(() => {
		const currentDescription = prepareForRender(template);

		if (!isEqual(currentDescription, safeDescription)) {
			setSafeDescription(currentDescription);
		}
	}, [safeDescription, template]);

	const colorChanged = useCallback((hexCode?: string) => {
		setSelectedColor(hexCode);
	}, []);

	const emojiSelected = useCallback((emojiDescription?: EmojiDescription) => {
		setEmojiId(emojiDescription?.id);
	}, []);

	const onSubmit = useCallback(
		(formData: IdeaTemplateData, actions?: EditorActions) => {
			(actions || editorActions)?.getValue().then((value) => {
				const newTemplate = {
					...formData,
					color: selectedColor,
					template: value,
					emoji: emojiId,
				};
				onSave(newTemplate);
				onClose();
			});
		},
		[emojiId, onClose, onSave, selectedColor, editorActions],
	);

	return (
		<ShortcutScope>
			<ModalDialog
				shouldCloseOnOverlayClick={false}
				width="x-large"
				height="auto"
				onClose={onClose}
			>
				<ModalHeader>
					<ModalTitle>{formTitle}</ModalTitle>
					<Button
						id="polaris.ideas.idea-description-template.form.close"
						appearance="subtle"
						iconBefore={<CrossIcon label="close" />}
						onClick={onClose}
					/>
				</ModalHeader>
				<ModalBody>
					<Form<IdeaTemplateData> onSubmit={(formData) => onSubmit(formData)}>
						{({ formProps }) => (
							<form {...formProps} id="idea-template.ui.modal.create-form">
								<Field
									isRequired
									defaultValue={title}
									id="title"
									name="title"
									label={formatMessage(messages.templateTitle)}
								>
									{({ fieldProps }) => (
										<Textfield
											placeholder={formatMessage(messages.templateTitle)}
											{...fieldProps}
										/>
									)}
								</Field>
								<EditorContainer>
									<IssuelessAdfProvider projectId={projectId} projectKey={projectKey}>
										{({ adfConfiguration }) => (
											<>
												{adfConfiguration && (
													<EditorBoundary fallback={<AdfSkeleton />}>
														<Editor
															minHeight={200}
															maxHeight={500}
															emojiProvider={adfConfiguration.emojiProvider}
															defaultValue={safeDescription}
															allowUndoRedoButtons
															allowFullEditorCapabilities
															placeholder={formatMessage(messages.templatePlaceholder)}
															popupsBoundariesElement={editorPortalRef.current || undefined}
															withActionButtons={false}
															onEditorReady={setEditorActions}
															isAiEnabled={isEditorAiEnabled}
														/>
													</EditorBoundary>
												)}
												<div ref={editorPortalRef} />
											</>
										)}
									</IssuelessAdfProvider>
								</EditorContainer>
								<Field
									defaultValue={description}
									id="description"
									name="description"
									label={formatMessage(messages.templateDescription)}
								>
									{({ fieldProps }) => (
										<Textfield
											placeholder={formatMessage(messages.templateDescription)}
											{...fieldProps}
										/>
									)}
								</Field>
								<ThumbnailContainer>
									<TemplateEmojiPicker
										onEmojiSelected={emojiSelected}
										selectedEmojiId={selectedEmoji?.id}
										thumbnailColor={selectedColor}
									/>
									<TemplateColorPicker
										compact={false}
										color={selectedColor}
										onChange={colorChanged}
									/>
								</ThumbnailContainer>
							</form>
						)}
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						id="polaris.ideas.idea-description-template.form.cancel"
						appearance="subtle"
						onClick={onClose}
					>
						{formatMessage(messages.cancelTemplateModal)}
					</Button>
					<Button
						id="polaris.ideas.idea-description-template.form.submit"
						form="idea-template.ui.modal.create-form"
						appearance="primary"
						type="submit"
					>
						{formatMessage(messages.saveTemplate)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

type CreateTemplateFormProps = {
	onClose: () => void;
	onCreate: (template: IdeaTemplateData) => void;
};

export const CreateTemplateForm = ({ onClose, onCreate }: CreateTemplateFormProps) => {
	const createNewTemplate = useCallback(
		(formData: IdeaTemplateData) => {
			onCreate(formData);
		},
		[onCreate],
	);

	return <TemplateForm onSave={createNewTemplate} onClose={onClose} />;
};

type UpdateTemplateFormProps = {
	templateData?: IdeaTemplate;
	onClose: () => void;
	onUpdate: (template: IdeaTemplate) => void;
};

export const UpdateTemplateForm = ({
	onClose,
	onUpdate,
	// @ts-expect-error - TS2322 - Type '{}' is not assignable to type 'IdeaTemplate'.
	templateData = {},
}: UpdateTemplateFormProps) => {
	const updateTemplate = useCallback(
		(formData: IdeaTemplateData) => {
			const newTemplate = {
				...formData,
				id: templateData?.id,
			};
			onUpdate(newTemplate);
		},
		[templateData?.id, onUpdate],
	);

	return <TemplateForm onSave={updateTemplate} onClose={onClose} templateData={templateData} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditorContainer = styled.div({
	margin: `${token('space.200', '16px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ThumbnailContainer = styled.div({
	display: 'flex',
	margin: `${token('space.200', '16px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :first-of-type': {
		marginRight: token('space.200', '16px'),
	},
});
