import React from 'react';
import OfficeBuildingIcon from '@atlaskit/icon/core/migration/office-building--office-building-filled';
import GlobalFieldIcon from '@atlaskit/icon/glyph/office-building-filled';
import type { NewGlyphColorProps } from '@atlaskit/icon/src/types';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type GlobalFieldMarkerProps = {
	color?: NewGlyphColorProps['color'];
	noMargins?: boolean;
	hideTooltip?: boolean;
	inline?: boolean;
};

export const GlobalFieldMarker = ({
	color,
	noMargins,
	hideTooltip = false,
	inline = false,
}: GlobalFieldMarkerProps) => {
	const { formatMessage } = useIntl();

	if (fg('jpd-visual-refresh_icons_aurora')) {
		return (
			<Flex
				alignItems="center"
				testId="polaris-lib-global-field-marker.ui"
				xcss={[baseStyles, !noMargins && withMarginsStyles, inline && inlineStyles]}
			>
				<Tooltip content={!hideTooltip && formatMessage(messages.globalField)}>
					<OfficeBuildingIcon
						color={color}
						label={formatMessage(messages.globalField)}
						spacing="none"
						LEGACY_primaryColor={color}
						LEGACY_size="small"
					/>
				</Tooltip>
			</Flex>
		);
	}

	return (
		<Box
			testId="polaris-lib-global-field-marker.ui"
			xcss={[!noMargins && withMarginsStyles, inline && inlineStylesOld]}
		>
			<Tooltip content={!hideTooltip && formatMessage(messages.globalField)}>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				<GlobalFieldIcon
					primaryColor={color}
					label={formatMessage(messages.globalField)}
					size="small"
				/>
			</Tooltip>
		</Box>
	);
};

const baseStyles = xcss({
	width: '16px',
});

const withMarginsStyles = xcss({
	marginLeft: 'space.075',
});

const inlineStyles = xcss({
	display: 'inline-flex',
});

const inlineStylesOld = xcss({
	display: 'inline-block',
});
