import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { deleteViewFromState } from '../delete-view-from-state';
import { withRefreshBlocker } from '../refresh-blocker';
import { findViewBy, modifyView } from '../utils';

export const syncViewName = (viewId: string, type: ViewKind, name: string): Action<State, Props> =>
	withRefreshBlocker(async ({ setState, getState }, props) => {
		const { cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view } = findViewBy(
			getState().projects[projectId],
			(innerView) => String(innerView.polarisId) === viewId && innerView.viewType === type,
		);
		if (!view) {
			return;
		}

		if (view.name === name) {
			return;
		}

		setState(
			modifyView(getState(), projectId, (innerView) => {
				if (String(innerView.polarisId) === viewId && innerView.viewType === type) {
					return {
						...innerView,
						name,
					};
				}
				return innerView;
			}),
		);
	});

export const syncViewEmoji = (
	viewId: string,
	type: ViewKind,
	emoji: string | undefined,
): Action<State, Props> =>
	withRefreshBlocker(async ({ setState, getState }, props) => {
		const { cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view } = findViewBy(
			getState().projects[projectId],
			(innerView) => String(innerView.polarisId) === viewId && innerView.viewType === type,
		);
		if (!view) {
			return;
		}

		if (view.emoji === emoji) {
			return;
		}

		setState(
			modifyView(getState(), projectId, (innerView) => {
				if (String(innerView.polarisId) === viewId && innerView.viewType === type) {
					return {
						...innerView,
						emoji,
					};
				}
				return innerView;
			}),
		);
	});

export const syncViewDeletion = (viewId: string, type: ViewKind): Action<State, Props> =>
	withRefreshBlocker(async ({ dispatch, getState }, props) => {
		const { cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view } = findViewBy(
			getState().projects[projectId],
			(innerView) => String(innerView.polarisId) === viewId && innerView.viewType === type,
		);

		if (!view) {
			return;
		}

		dispatch(deleteViewFromState(view.id));
	});
