/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import NoRestrictionPencil from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/no-restriction-pencil';
import { useIntl } from '@atlassian/jira-intl';
import {
	ACCESS_ROLE_EDITOR,
	ACCESS_ROLE_VIEWER,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	PrincipalType,
	ViewAccess,
	ViewAccessLevel,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { AccessLevelSelect } from './access-level-select';
import { AccessUserList, type AccessUserListProps } from './access-user-list';
import { AccessScreen as AccessScreenLegacy } from './legacy';
import messages from './messages';
import { NoEditorsSection, type NoEditorsSectionProps } from './no-editors-section';
import { UserPicker, type UserPickerProps } from './user-picker';
import { isPrincipalTypeItem } from './utils';

type PermissionOption = { label: string; value: AccessRole };

export type AccessScreenProps = ViewAccess & {
	currentUserAccountId: AccessUserListProps['currentUserAccountId'];
	userPickerOptions: UserPickerProps['userPickerOptions'];
	isUserPickerLoading: boolean;
	failures?: {
		role: AccessRole;
		principals: { id: string; name: string; type: PrincipalType }[];
	};
	skippedAccountFailures?: AccessUserListProps['skippedAccountFailures'];
	canEdit?: boolean;
	setUserPickerOptions: UserPickerProps['setUserPickerOptions'];
	onPrincipalRoleChange: AccessUserListProps['onPrincipalRoleChange'];
	onRemovePrincipals: AccessUserListProps['onRemovePrincipals'];
	onUpdateAccessLevel: (accessLevel: ViewAccessLevel) => Promise<void>;
	onAddPrincipals: ({
		principals,
		role,
	}: {
		role: AccessRole;
		principals: { id: string; name: string; type: PrincipalType; avatarUrl?: string }[];
	}) => Promise<void>;
	onClearFailures: (role?: AccessRole) => void;
	onDuplicateView: NoEditorsSectionProps['onDuplicateView'];
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
};

export const AccessScreen = componentWithFG(
	'polaris-view-permissions-confluence-align',
	(props: AccessScreenProps) => {
		const {
			isUserPickerLoading,
			canEdit = false,
			accessLevel,
			additionalAccess,
			userPickerOptions,
			currentUserAccountId,
			failures,
			skippedAccountFailures,
			setUserPickerOptions,
			onUpdateAccessLevel,
			onPrincipalRoleChange,
			onRemovePrincipals,
			onAddPrincipals,
			onClearFailures,
			onDuplicateView,
			onMenuClose,
			onMenuOpen,
		} = props;
		const { formatMessage } = useIntl();
		const [selectedRole, setSelectedRole] = useState<AccessRole>(ACCESS_ROLE_EDITOR);

		const permissionOptions: PermissionOption[] = useMemo(
			() => [
				{ label: formatMessage(messages.canViewLabel), value: ACCESS_ROLE_VIEWER },
				{ label: formatMessage(messages.canEditLabel), value: ACCESS_ROLE_EDITOR },
			],
			[formatMessage],
		);

		const handleAddPrincipals = useCallback(async () => {
			const principals = userPickerOptions.flatMap(({ id, type, name, avatarUrl }) =>
				isPrincipalTypeItem(type) ? [{ id, type, name, avatarUrl }] : [],
			);

			await onAddPrincipals({ principals, role: selectedRole });
			setUserPickerOptions([]);
		}, [onAddPrincipals, selectedRole, setUserPickerOptions, userPickerOptions]);

		useEffect(() => {
			experience.viewPermissions.openDialog.success();
		}, []);

		const handleAccessLevelUpdate = useCallback(
			(updatedAccessLevel: ViewAccessLevel) => {
				setSelectedRole(ACCESS_ROLE_EDITOR);
				return onUpdateAccessLevel(updatedAccessLevel);
			},
			[onUpdateAccessLevel],
		);

		return (
			<>
				<Stack space="space.150" xcss={accessScreenContainerStyles}>
					<AccessLevelSelect
						accessLevel={accessLevel}
						onUpdateAccessLevel={handleAccessLevelUpdate}
						isDisabled={!canEdit}
						onMenuOpen={onMenuOpen}
						onMenuClose={onMenuClose}
					/>
					<Stack space="space.150">
						{accessLevel !== VIEW_ACCESS_LEVELS.OPEN && (
							<Flex gap="space.100">
								<UserPicker
									isLoading={isUserPickerLoading}
									groups={additionalAccess.groups}
									profiles={additionalAccess.profiles}
									userPickerOptions={userPickerOptions}
									setUserPickerOptions={setUserPickerOptions}
									isDisabled={!canEdit}
									onMenuOpen={onMenuOpen}
									onMenuClose={onMenuClose}
								/>
								<Box xcss={roleSelectWrapperStyles}>
									{accessLevel !== VIEW_ACCESS_LEVELS.RESTRICTED ? (
										<Flex alignItems="center" xcss={selectedRoleContainerStyles}>
											{permissionOptions.find((option) => option.value === selectedRole)?.label}
										</Flex>
									) : (
										<Select<PermissionOption, false>
											options={permissionOptions}
											value={permissionOptions.find((option) => option.value === selectedRole)}
											menuPosition="fixed"
											onChange={(arg) => {
												if (arg) {
													setSelectedRole(arg.value);
												}
											}}
											isDisabled={accessLevel !== VIEW_ACCESS_LEVELS.RESTRICTED}
										/>
									)}
								</Box>
								<div css={addPrincipalButtonWrapperStyles}>
									<Button
										data-component-selector="polaris-component-view-access.ui.access-screen.add-button-0Kd6"
										appearance="primary"
										onClick={handleAddPrincipals}
										testId="polaris-component-view-access.ui.access-screen.add-button"
										isDisabled={isUserPickerLoading || userPickerOptions.length === 0}
										isLoading={isUserPickerLoading}
										shouldFitContainer
									>
										{formatMessage(messages.addButtonLabel)}
									</Button>
								</div>
							</Flex>
						)}
						<NoEditorsSection
							accessLevel={accessLevel}
							additionalAccess={additionalAccess}
							canEdit={canEdit}
							onDuplicateView={onDuplicateView}
						/>
						{failures && (
							<SectionMessage
								testId="polaris-component-view-access.ui.access-screen.failures-section"
								key={failures.role}
								title={formatMessage(messages.addPrincipalsFailureMessage)}
								appearance="error"
								actions={[
									<SectionMessageAction
										key="retry"
										onClick={() => {
											onAddPrincipals(failures);
										}}
									>
										{formatMessage(messages.addPrincipalsRetryAction)}
									</SectionMessageAction>,
									<SectionMessageAction key="cancel" onClick={() => onClearFailures(failures.role)}>
										{formatMessage(messages.addPrincipalsCancelAction)}
									</SectionMessageAction>,
								]}
							>
								{failures.principals.map(({ name }) => name).join(', ')}
							</SectionMessage>
						)}
						{accessLevel === VIEW_ACCESS_LEVELS.OPEN ? (
							<Flex justifyContent="center" xcss={noRestrictionsImageContainerStyles}>
								<NoRestrictionPencil alt="" width={178} />
							</Flex>
						) : (
							<AccessUserList
								accessLevel={accessLevel}
								additionalAccess={additionalAccess}
								isDisabled={!canEdit}
								currentUserAccountId={currentUserAccountId}
								skippedAccountFailures={skippedAccountFailures}
								onPrincipalRoleChange={onPrincipalRoleChange}
								onRemovePrincipals={onRemovePrincipals}
							/>
						)}
					</Stack>
				</Stack>
			</>
		);
	},
	AccessScreenLegacy,
);

const accessScreenContainerStyles = xcss({ height: '100%' });

const noRestrictionsImageContainerStyles = xcss({ height: '260px' });

const roleSelectWrapperStyles = xcss({
	width: '120px',
	flexShrink: 0,
});

const addPrincipalButtonWrapperStyles = css({
	width: '80px',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="polaris-component-view-access.ui.access-screen.add-button-0Kd6"]': {
		height: '40px',
		alignItems: 'center',
	},
});

const selectedRoleContainerStyles = xcss({
	height: '100%',
	paddingInline: 'space.100',
	backgroundColor: 'color.background.accent.gray.subtlest',
	borderRadius: 'border.radius.100',
	border: '1px solid transparent',
	maxHeight: '40px',
});
