import React, { useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useProjectAriUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { DEFAULT_TEMPLATES } from '../../../../common/types/idea-templates/default';
import { useSubmitIdeaFormDescriptionTemplate } from '../../../../controllers/project-properties';
import { getIdeaTemplates } from '../../../../services/polaris-api/idea-templates';
import type { FieldComponentProps } from '../types';
import { AdfComponent } from './adf';

export const Description = ({
	onUpdate,
	isEditable,
	shouldUpdateTemplate,
}: FieldComponentProps) => {
	const descriptionTemplateId = useSubmitIdeaFormDescriptionTemplate();
	const apolloClient = useApolloClient();
	const projectAri = useProjectAriUnsafe();

	const [template, setTemplate] = useState<ADF | undefined>();
	const [isLoading, setLoading] = useState(true);
	const { generalDataLoadingFailedError } = useErrorHandlers();

	const loadDescriptionTemplate = useCallback(() => {
		setLoading(true);
		if (!descriptionTemplateId.value) {
			setLoading(false);
			return;
		}
		getIdeaTemplates(apolloClient, projectAri)
			.then((loadedTemplates) => {
				const templateForIdeaCreation = [...loadedTemplates, ...DEFAULT_TEMPLATES].find(
					({ id }) => id === descriptionTemplateId.value,
				);
				setTemplate(templateForIdeaCreation?.template);
			})
			.catch(generalDataLoadingFailedError)
			.finally(() => setLoading(false));
	}, [apolloClient, descriptionTemplateId.value, generalDataLoadingFailedError, projectAri]);

	useEffect(() => {
		if (shouldUpdateTemplate) {
			loadDescriptionTemplate();
		}
	}, [shouldUpdateTemplate, loadDescriptionTemplate]);

	useEffect(() => {
		loadDescriptionTemplate();
	}, [descriptionTemplateId.value, loadDescriptionTemplate]);

	return (
		<AdfComponent
			description={template}
			onUpdate={onUpdate}
			isLoadingTemplate={isLoading}
			isDisabled={!isEditable}
		/>
	);
};
