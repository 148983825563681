import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Box, Flex, Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	useLocalIssueIdToJiraIssueIdMapper,
	useRankedIssueIds,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import { useSwitchedToViewRankFlag } from './utils';

type ProjectRankContentProps = {
	disabledButtonMessage?: string;
};

export const ProjectRankContent = ({ disabledButtonMessage }: ProjectRankContentProps) => {
	const rankedIssueIds = useRankedIssueIds();
	const idMapper = useLocalIssueIdToJiraIssueIdMapper();

	const { setSortModeOfCurrentViewToViewRank } = useViewActions();
	const showSwitchedToViewRankFlag = useSwitchedToViewRankFlag();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const setSortModeToViewRank = useCallback(() => {
		fireCompoundAnalyticsEvent.SortChangeToViewRankButtonClicked(createAnalyticsEvent({}));
		setSortModeOfCurrentViewToViewRank(rankedIssueIds.map(idMapper).filter(Boolean));
		showSwitchedToViewRankFlag();
	}, [
		createAnalyticsEvent,
		idMapper,
		rankedIssueIds,
		setSortModeOfCurrentViewToViewRank,
		showSwitchedToViewRankFlag,
	]);

	const { formatMessage } = useIntl();
	return (
		<Box paddingBlockEnd="space.300">
			<Box paddingBlockStart="space.075" paddingBlockEnd="space.150">
				<Text>{formatMessage(messages.manualRankDescription)}</Text>
			</Box>
			<Box paddingBlockEnd="space.075">
				<Text size="UNSAFE_small">{formatMessage(messages.projectRankHeader)}</Text>
			</Box>
			<Box paddingBlockEnd="space.200">
				<Text size="UNSAFE_small">{formatMessage(messages.projectRankDescription)}</Text>
			</Box>
			<Flex alignItems="center">
				<Tooltip content={disabledButtonMessage}>
					<Button
						testId="polaris-ideas.ui.right-sidebar.sort.advanced.project-rank.change-to-view-rank-mode"
						onClick={setSortModeToViewRank}
						isDisabled={!!disabledButtonMessage}
					>
						{formatMessage(messages.changeToViewRankMode)}
					</Button>
				</Tooltip>
				<Box paddingInlineStart="space.050">
					<Tooltip content={formatMessage(messages.changeToViewRankModeTooltip)}>
						<EditorInfoIcon label="info" />
					</Tooltip>
				</Box>
			</Flex>
		</Box>
	);
};
