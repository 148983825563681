import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { STATUS_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import {
	IssueCreateGroupTypeSpecified,
	type IssueCreatedProperty,
	type Props,
	type State,
} from '../types';
import {
	createGetFieldMapping,
	createGetFieldOptions,
	createGetFieldOptionsWithAriResolved,
	getIssuesSimiliarStatusIdsByStatusId,
	getSortedDistinctIssueStatuses,
} from './fields';
import { getRankedIssueIds } from './issue-ids';
import { getIdeasInCreationGrouped } from './properties';
import { getSortedIssueIds, getSortedUnfilteredIssueIds } from './sort';

export type GroupOptions<TValueType> = {
	options: {
		groupIdentity: string;
		value: TValueType;
	}[];
	allowEmpty: boolean;
};

export type GroupIdMap<TValueType> = Record<
	LocalIssueId,
	{
		groupIdentity: string;
		value: TValueType;
	}[]
>;

export type IssuesByGroupIdentity = {
	groups: {
		[key: string]: LocalIssueId[];
	};
	empty: LocalIssueId[] | undefined;
};

export type IssuesByCell = {
	groups: {
		[key: string]:
			| {
					[key: string]: LocalIssueId[] | undefined;
			  }
			| undefined;
	};
	yEmpty: {
		[key: string]: LocalIssueId[] | undefined;
	};
	xEmpty: {
		[key: string]: LocalIssueId[] | undefined;
	};
	empty: LocalIssueId[] | undefined;
};

const EMPTY_GROUP: Array<LocalIssueId> = [];
const EMPTY_OPTIONS = {
	options: [],
	allowEmpty: false,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_IDENTITIES: Record<string, any> = {};

export const createGetGroupIdentities =
	(fieldKey?: FieldKey) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(state: State, props: Props | undefined): GroupIdMap<any> => {
		const mapping = createGetFieldMapping(fieldKey)(state, props);
		if (fieldKey === undefined || mapping === undefined) {
			return EMPTY_IDENTITIES;
		}
		const cached = cacheSelectorCreator(mapping.getGroupIdentitiesSelector);
		return cached(fieldKey, getRankedIssueIds)(state);
	};

export const createGetGroupOptions = cacheSelectorCreator((fieldKey?: FieldKey) => {
	const getFieldOptionsWithAriResolved = createGetFieldOptionsWithAriResolved(fieldKey);
	const getGroupIdentities = createGetGroupIdentities(fieldKey);
	const getFieldMapping = createGetFieldMapping(fieldKey);

	return createSelector(
		getFieldOptionsWithAriResolved,
		getGroupIdentities,
		getFieldMapping,
		getSortedDistinctIssueStatuses,
		(state, props) => props?.isCollectionView,
		(
			fieldOptions,
			identities,
			mapping,
			distinctIssueStatuses,
			isCollectionView,
		): GroupOptions<unknown> => {
			if (mapping === undefined) {
				return EMPTY_OPTIONS;
			}

			const CHECKBOX_FIELD_VALUES = [0, 1];

			if (mapping.field?.type === FIELD_TYPES.CHECKBOX) {
				return {
					options: CHECKBOX_FIELD_VALUES.map((value) => ({
						groupIdentity: value.toString(),
						value,
					})),
					allowEmpty: mapping.allowEmptyGroup,
				};
			}

			const isGlobalLabelsField =
				mapping.field?.type === FIELD_TYPES.CUSTOM_LABELS && mapping.field?.global;

			if (fieldOptions.length && !isGlobalLabelsField) {
				return {
					options: fieldOptions.map(({ id }) => ({
						groupIdentity: id,
						value: mapping.isMultiValueField ? [{ id }] : { id },
					})),
					allowEmpty: mapping.allowEmptyGroup,
				};
			}

			const allValues = flatten(map(identities, (value) => value));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const distinctValues: Record<string, any> = {};
			// use all available groups by default
			let distinctValuesFilter = (groupIdentity: string): boolean => !!groupIdentity;

			if (isCollectionView && fieldKey === STATUS_FIELDKEY) {
				const distinctStatusesById = keyBy(distinctIssueStatuses, 'id');
				distinctValuesFilter = (groupIdentity: string) => !!distinctStatusesById[groupIdentity];
			}

			allValues.forEach((val) => {
				if (distinctValuesFilter(val.groupIdentity)) {
					distinctValues[val.groupIdentity] = val;
				}
			});

			if (isGlobalLabelsField && fieldOptions.length) {
				const globalLabels = fieldOptions
					.filter(({ id }) => !(id in distinctValues))
					.map(({ id }) => ({
						groupIdentity: id,
						value: mapping.isMultiValueField ? [{ id }] : { id },
					}));

				globalLabels.forEach((val) => {
					distinctValues[val.groupIdentity] = val;
				});
			}

			return {
				options: values(distinctValues),
				allowEmpty: mapping.allowEmptyGroup,
			};
		},
	);
});

export const createGetSortedGroupOptions = (fieldKey?: FieldKey) => {
	const getGroupOptions = createGetGroupOptions(fieldKey);
	const getFieldOptions = createGetFieldOptions(fieldKey);
	const getFieldMapping = createGetFieldMapping(fieldKey);

	return createSelector(
		getFieldOptions,
		getGroupOptions,
		getFieldMapping,
		(fieldOptions, groupOptions, fieldMapping) => {
			if (fieldOptions !== undefined || fieldMapping === undefined) {
				// actual options array on this field or no comparator available. Do not modify the order
				return groupOptions;
			}

			const sortedOptions = [...groupOptions.options];
			const comparator = (
				a: {
					groupIdentity: string;
					value: unknown;
				},
				b: {
					groupIdentity: string;
					value: unknown;
				},
			) => fieldMapping.comparator(a.value, b.value, 'ASC');
			sortedOptions.sort(comparator);
			return {
				...groupOptions,
				options: sortedOptions,
			};
		},
	);
};

const getStatusGroupIdentities = createSelector(
	createGetGroupIdentities(STATUS_FIELDKEY),
	createGetGroupOptions(STATUS_FIELDKEY),
	getIssuesSimiliarStatusIdsByStatusId,
	(
		groupIdMap: GroupIdMap<unknown>,
		options: GroupOptions<unknown>,
		similarStatusIdsByStatusId,
	): GroupIdMap<unknown> => {
		const statusIdToGroupIdentity = mapValues(
			similarStatusIdsByStatusId,
			(similarIds) =>
				options.options.find(({ groupIdentity }) => similarIds.includes(groupIdentity))
					?.groupIdentity,
		);

		const groupIdMapMerged = mapValues(groupIdMap, (groups) => {
			const groupsMerged: {
				groupIdentity: string;
				value: unknown;
			}[] = [];

			groups.forEach((group) => {
				const groupIdentity = statusIdToGroupIdentity[group.groupIdentity];
				if (groupIdentity) {
					groupsMerged.push({ ...group, groupIdentity });
				}
			});

			return groupsMerged;
		});

		return groupIdMapMerged;
	},
);

/**
 * internal helper to map local issue ids by group identity. used for both sorted/filtered sets
 * and unfiltered sets (to identify why columns might be empty, e.g. filter)
 */
export const createGetLocalIssueIdsByGroupIdentityForIssues = cacheSelectorCreator(
	(
		fieldKey: FieldKey | undefined,
		baseLocalIssueIdSelector: (arg1: State, arg2: Props | undefined) => Array<LocalIssueId>,
	): ((arg1: State, arg2: Props | undefined) => IssuesByGroupIdentity) => {
		const getGroupIdentities = createGetGroupIdentities(fieldKey);
		const getGroupOptions = createGetGroupOptions(fieldKey);

		return createSelector(
			baseLocalIssueIdSelector,
			getGroupIdentities,
			getGroupOptions,
			getIdeasInCreationGrouped,
			getStatusGroupIdentities,
			(state: State, props?: Props) => props?.isCollectionView,
			(
				sortedIds: LocalIssueId[],
				groupIdMapDefault: GroupIdMap<unknown>,
				options: GroupOptions<unknown>,
				ideasInCreationGrouped: IssueCreatedProperty,
				statusGroupIdMap: GroupIdMap<unknown>,
				isCollectionView: boolean | undefined,
			) => {
				const groupIdMap =
					isCollectionView && fieldKey === STATUS_FIELDKEY ? statusGroupIdMap : groupIdMapDefault;
				const result: IssuesByGroupIdentity = {
					groups: options.options.reduce<Record<string, string[]>>(
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						(agg, { groupIdentity }) => ({ ...agg, [groupIdentity]: [] }),
						{},
					),
					empty: options.allowEmpty ? [] : undefined,
				};

				sortedIds.forEach((id) => {
					const ideaInCreation = ideasInCreationGrouped[id];

					if (ideaInCreation && ideaInCreation.groupType === IssueCreateGroupTypeSpecified) {
						if (has(result.groups, ideaInCreation.groupIdentity)) {
							result.groups[ideaInCreation.groupIdentity].push(id);
						}
						return;
					}

					if (groupIdMap[id] === undefined || groupIdMap[id].length === 0) {
						if (options.allowEmpty) {
							result.empty?.push(id);
						}
					} else {
						groupIdMap[id].forEach(({ groupIdentity }) => {
							if (has(result.groups, groupIdentity)) {
								result.groups[groupIdentity].push(id);
							}
						});
					}
				});

				return result;
			},
		);
	},
);

export const createGetLocalIssueIdsByGroupIdentity = (fieldKey: FieldKey | undefined) =>
	createGetLocalIssueIdsByGroupIdentityForIssues(fieldKey, getSortedIssueIds);

export const createGetUnfilteredLocalIssueIdsByGroupIdentity = (fieldKey: FieldKey | undefined) =>
	createGetLocalIssueIdsByGroupIdentityForIssues(fieldKey, getSortedUnfilteredIssueIds);

export const createGetLocalIssueIdsForGroupIdentity =
	(
		baseSelector: (
			arg1: FieldKey | undefined,
		) => (arg1: State, arg2: Props | undefined) => IssuesByGroupIdentity,
	) =>
	(fieldKey: FieldKey, groupIdentity?: string) => {
		const getLocalIssueIdsByGroupIdentity = baseSelector(fieldKey);
		return createSelector(
			getLocalIssueIdsByGroupIdentity,
			(idGroups) =>
				(groupIdentity !== undefined ? idGroups.groups[groupIdentity] : idGroups.empty) ||
				EMPTY_GROUP,
		);
	};

export const createGetLocalIssueIdsByCellForIssues = cacheSelectorCreator(
	(
		fieldKey: FieldKey,
		verticalFieldKey: FieldKey,
		baseLocalIssueIdSelector: (arg1: State, arg2: Props | undefined) => Array<LocalIssueId>,
	) => {
		const getGroupIdentities = createGetGroupIdentities(fieldKey);
		const getGroupOptions = createGetGroupOptions(fieldKey);

		const getVerticalGroupIdentities = createGetGroupIdentities(verticalFieldKey);
		const getVerticalGroupOptions = createGetGroupOptions(verticalFieldKey);

		return createSelector(
			baseLocalIssueIdSelector,
			getGroupIdentities,
			getGroupOptions,
			getVerticalGroupIdentities,
			getVerticalGroupOptions,
			getStatusGroupIdentities,
			(state: State, props?: Props) => props?.isCollectionView,
			(
				sortedIds: LocalIssueId[],
				groupIdMapDefault: GroupIdMap<unknown>,
				options: GroupOptions<unknown>,
				verticalGroupIdMapDefault: GroupIdMap<unknown>,
				verticalOptions: GroupOptions<unknown>,
				getStatusGroupIdMap: GroupIdMap<unknown>,
				isCollectionView,
			) => {
				const groupIdMap =
					isCollectionView && fieldKey === STATUS_FIELDKEY
						? getStatusGroupIdMap
						: groupIdMapDefault;
				const verticalGroupIdMap =
					isCollectionView && verticalFieldKey === STATUS_FIELDKEY
						? getStatusGroupIdMap
						: verticalGroupIdMapDefault;

				const optionsGroupIdentityMap = options.options.reduce(
					(result, { groupIdentity }) =>
						Object.assign(result, {
							[groupIdentity]: true,
						}),
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					{} as Record<string, boolean>,
				);

				const verticalOptionsGroupIdentityMap = verticalOptions.options.reduce(
					(result, { groupIdentity }) =>
						Object.assign(result, {
							[groupIdentity]: true,
						}),
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					{} as Record<string, boolean>,
				);

				const retVal: IssuesByCell = {
					groups: {},
					xEmpty: {},
					yEmpty: {},
					empty: options.allowEmpty && verticalOptions.allowEmpty ? [] : undefined,
				};

				sortedIds.forEach((id) => {
					if ((groupIdMap[id]?.length || 0) === 0 && (verticalGroupIdMap[id]?.length || 0) === 0) {
						if (options.allowEmpty && verticalOptions.allowEmpty) {
							retVal.empty?.push(id);
						}
					} else if (
						(groupIdMap[id]?.length || 0) > 0 &&
						(verticalGroupIdMap[id]?.length || 0) === 0
					) {
						if (verticalOptions.allowEmpty) {
							groupIdMap[id].forEach(({ groupIdentity }) => {
								if (!optionsGroupIdentityMap[groupIdentity]) {
									return;
								}
								if (!retVal.yEmpty[groupIdentity]) {
									retVal.yEmpty[groupIdentity] = [];
								}
								retVal.yEmpty[groupIdentity]?.push(id);
							});
						}
					} else if (
						(groupIdMap[id]?.length || 0) === 0 &&
						(verticalGroupIdMap[id]?.length || 0) > 0
					) {
						if (options.allowEmpty) {
							verticalGroupIdMap[id].forEach(({ groupIdentity: verticalGroupIdentity }) => {
								if (!verticalOptionsGroupIdentityMap[verticalGroupIdentity]) {
									return;
								}
								if (!retVal.xEmpty[verticalGroupIdentity]) {
									retVal.xEmpty[verticalGroupIdentity] = [];
								}
								retVal.xEmpty[verticalGroupIdentity]?.push(id);
							});
						}
					} else {
						groupIdMap[id].forEach(({ groupIdentity }) => {
							verticalGroupIdMap[id].forEach(({ groupIdentity: verticalGroupIdentity }) => {
								if (!optionsGroupIdentityMap[groupIdentity]) {
									return;
								}
								if (!verticalOptionsGroupIdentityMap[verticalGroupIdentity]) {
									return;
								}
								if (!retVal.groups[groupIdentity]) {
									retVal.groups[groupIdentity] = {};
								}
								const groupIdentityMap = retVal.groups[groupIdentity];
								if (groupIdentityMap && !groupIdentityMap[verticalGroupIdentity]) {
									groupIdentityMap[verticalGroupIdentity] = [];
								}
								retVal.groups?.[groupIdentity]?.[verticalGroupIdentity]?.push(id);
							});
						});
					}
				});
				return retVal;
			},
		);
	},
);

export const createGetLocalIssueIdsForCell =
	(
		baseSelector: (
			fieldKey: FieldKey,
			verticalFieldKey: FieldKey,
		) => (arg1: State, arg2: Props | undefined) => IssuesByCell,
	) =>
	(
		fieldKey: FieldKey,
		groupIdentity: string | undefined,
		verticalFieldKey: FieldKey,
		verticalGroupIdentity: string | undefined,
	) => {
		const getLocalIssueIdsByCell = baseSelector(fieldKey, verticalFieldKey);
		return createSelector(getLocalIssueIdsByCell, (idGroups) => {
			if (groupIdentity !== undefined && verticalGroupIdentity !== undefined) {
				return idGroups.groups[groupIdentity]?.[verticalGroupIdentity] || EMPTY_GROUP;
			}
			if (groupIdentity === undefined && verticalGroupIdentity !== undefined) {
				return idGroups.xEmpty[verticalGroupIdentity] || EMPTY_GROUP;
			}
			if (groupIdentity !== undefined && verticalGroupIdentity === undefined) {
				return idGroups.yEmpty[groupIdentity] || EMPTY_GROUP;
			}
			if (groupIdentity === undefined && verticalGroupIdentity === undefined) {
				return idGroups.empty || EMPTY_GROUP;
			}
			return EMPTY_GROUP;
		});
	};

export const createGetLocalIssueIdsByCell = (fieldKey: FieldKey, verticalFieldKey: FieldKey) =>
	createGetLocalIssueIdsByCellForIssues(fieldKey, verticalFieldKey, getSortedIssueIds);

export const createGetUnfilteredLocalIssueIdsByCell = (
	fieldKey: FieldKey,
	verticalFieldKey: FieldKey,
) => createGetLocalIssueIdsByCellForIssues(fieldKey, verticalFieldKey, getSortedUnfilteredIssueIds);

export const createGetLocalIssueIdsCountForVerticalGroupIdentity =
	(
		baseSelector: (
			fieldKey: FieldKey,
			verticalFieldKey: FieldKey,
		) => (arg1: State, arg2: Props | undefined) => IssuesByCell,
	) =>
	(fieldKey: FieldKey, verticalFieldKey: FieldKey, verticalGroupIdentity?: string) => {
		const getLocalIssueIdsByCell = baseSelector(fieldKey, verticalFieldKey);
		return createSelector(getLocalIssueIdsByCell, (idGroups): number => {
			if (verticalGroupIdentity === undefined) {
				const sum = idGroups.empty?.length || 0;
				return Object.keys(idGroups.yEmpty).reduce(
					(result, key) => result + (idGroups.yEmpty[key]?.length || 0),
					sum,
				);
			}
			const sum = idGroups.xEmpty[verticalGroupIdentity]?.length || 0;
			return Object.keys(idGroups.groups).reduce(
				(result, key) => result + (idGroups.groups[key]?.[verticalGroupIdentity]?.length || 0),
				sum,
			);
		});
	};

export const createGetSearchableLocalIssueIds = (
	fieldKey: FieldKey,
	groupIdentity?: string,
	verticalFieldKey?: FieldKey,
	verticalGroupIdentity?: string,
) => {
	const getUnfilteredLocalIssueIdsForCellOrGroupIdentity =
		verticalFieldKey === undefined
			? createGetLocalIssueIdsForGroupIdentity(createGetUnfilteredLocalIssueIdsByGroupIdentity)(
					fieldKey,
					groupIdentity,
				)
			: createGetLocalIssueIdsForCell(createGetUnfilteredLocalIssueIdsByCell)(
					fieldKey,
					groupIdentity,
					verticalFieldKey,
					verticalGroupIdentity,
				);

	return createSelector(
		getSortedUnfilteredIssueIds,
		getUnfilteredLocalIssueIdsForCellOrGroupIdentity,
		(sortedUnfilteredIssueIds, unfilteredLocalIssueIdsForCellOrGroupIdentity) =>
			sortedUnfilteredIssueIds.filter(
				(id) => !unfilteredLocalIssueIdsForCellOrGroupIdentity.includes(id),
			),
	);
};
