import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { isNotFoundError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';

export const onHandleADFUploadError = (experience: JPDExperience, error?: Error) => {
	if (!error) {
		return experience.failure('MISSING_ERROR');
	}
	if (error && 'statusCode' in error && error.statusCode === 400) {
		if (
			['ATTACHMENT_VALIDATION_ERROR', 'CONTENT_LIMIT_EXCEEDED', 'INVALID_INPUT'].includes(
				error.message,
			)
		) {
			// https://pi-dev-sandbox.atlassian.net/browse/POL-9425
			return experience.successWithReason(error);
		}

		if (error.message === '') {
			// https://pi-dev-sandbox.atlassian.net/browse/POL-10700
			return experience.successWithReason(error);
		}
	}
	if (isNotFoundError(error) || isClientFetchError(error)) {
		return experience.abort(error);
	}
	return experience.failure(error);
};
