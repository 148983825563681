import React, { useCallback, useMemo, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useIntl } from '@atlassian/jira-intl';
import { useIsRightSidebarShowingSort } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	type Showing,
	showingSort,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import {
	useHasSortUnsavedChanges,
	useCurrentSortByFieldCount,
	useCurrentViewInViewSortMode,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { ActionButton } from '../action-button';
import { messages } from './messages';

export type Props = {
	onControlButtonClick: (arg1: Showing, analyticsEvent: UIAnalyticsEvent) => void;
};

export const SortControlButton = ({ onControlButtonClick }: Props) => {
	const { formatMessage } = useIntl();
	const [isSelected] = useIsRightSidebarShowingSort();
	const isViewInViewSortMode = useCurrentViewInViewSortMode();
	const sortFieldCount = useCurrentSortByFieldCount();
	const hasSortUnsavedChanges = useHasSortUnsavedChanges();

	const onClick = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			onControlButtonClick(showingSort, analyticsEvent);
		},
		[onControlButtonClick],
	);

	const elemAfter = useMemo(() => {
		if (isViewInViewSortMode) {
			return formatMessage(messages.viewRankIndicator);
		}
		if (sortFieldCount > 0) {
			return sortFieldCount;
		}
		return null;
	}, [formatMessage, isViewInViewSortMode, sortFieldCount]);

	return (
		<ActionButton
			id="polaris.ideas.ui.view-controls.sort-button"
			testId="polaris-ideas.ui.view-controls.sort-button"
			isSelected={isSelected}
			isAccentuated={isViewInViewSortMode}
			onClick={onClick}
			elemAfter={elemAfter}
			isEmpty={!isViewInViewSortMode && sortFieldCount === 0}
			hasUnsavedChanges={hasSortUnsavedChanges}
		>
			{formatMessage(messages.sortButton)}
		</ActionButton>
	);
};
