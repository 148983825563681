import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraProjectSearchResponse } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { JiraProjectSearchResponse };

export const searchProjects = (query?: string, type?: string): Promise<JiraProjectSearchResponse> =>
	fetchJson(
		`/rest/api/3/project/search?expand=issueTypes&orderBy=NAME&maxResults=20&action=browse&startAt=0&query=${
			query || ''
		}&typeKey=${type || ''}`,
		{
			method: 'GET',
		},
	);
