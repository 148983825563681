import gqlTagPolaris from 'graphql-tag';
import { type Ari, isAri, getAriConfig } from '@atlassian/jira-platform-ari';
import type { FieldIdMap } from '@atlassian/jira-polaris-component-field-id-mapping/src/controllers/types.tsx';
import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_GetViewArrangementInfo as QueryResponse } from './__generated_apollo__/jira_polaris_GetViewArrangementInfo';

const QUERY_GQL = gqlTagPolaris`
query jira_polaris_GetViewArrangementInfo($viewId: ID!) {
  polarisViewArrangementInfo(id: $viewId)
}
`;

const tryParseJSON = (str: string) => {
	try {
		return JSON.parse(str);
	} catch (error) {
		return undefined;
	}
};

// Replace in old config fields ARIs with new fields keys (JIRA)
const replaceARIsWithFieldKeys = (
	arrangementInfoStr: string,
	fields: FieldMap,
	fieldIdMapping: FieldIdMap,
): string | undefined => {
	const arrangementInfo = tryParseJSON(arrangementInfoStr);
	if (!arrangementInfo) return arrangementInfoStr;

	Object.keys(fields).forEach((fieldKey) => {
		const id = fieldIdMapping[fieldKey];
		if (!id || !isAri(id)) return;
		const { resourceId } = getAriConfig(id);
		if (!resourceId) return;

		if (arrangementInfo[resourceId]) {
			arrangementInfo[fieldKey] = arrangementInfo[resourceId];
			arrangementInfo[resourceId] = undefined;
		}
	});
	return JSON.stringify(arrangementInfo);
};

export const fetchArrangementInfo = async (
	apolloClient: PolarisApolloClient,
	viewId: Ari,
	fields: FieldMap,
	fieldIdMappingPromise: Promise<FieldIdMap>,
): Promise<string | undefined> => {
	const result = await apolloClient.query<QueryResponse>({
		query: QUERY_GQL,
		variables: {
			viewId,
		},
		fetchPolicy: 'no-cache',
	});

	if (result.errors !== undefined) {
		throw new Error(
			`polaris-ideas.fetch-arrangement-error:${result.errors
				.map((e: Error) => e.message)
				.join(', ')}`,
		);
	}
	if (!result.data || !result.data.polarisViewArrangementInfo) {
		return undefined;
	}

	const fieldIdMapping = await fieldIdMappingPromise;

	return replaceARIsWithFieldKeys(result.data.polarisViewArrangementInfo, fields, fieldIdMapping);
};
