import { useEffect, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const LeavePageWarning = ({ isDirty }: { isDirty: boolean }) => {
	const { formatMessage } = useIntl();

	const onBeforeUnload = useCallback(
		(event: Event) => {
			if (!isDirty) {
				return undefined;
			}
			event.preventDefault(); // Firefox
			const returnValue = formatMessage(messages.warningMessage);
			Object.assign(event, { returnValue }); // Chrome
			return returnValue; // IE
		},
		[formatMessage, isDirty],
	);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('beforeunload', onBeforeUnload);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('beforeunload', onBeforeUnload);
		};
	}, [onBeforeUnload]);

	return null;
};
