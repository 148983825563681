import type { DynamicFieldFormula } from '../types';
import { CONSTANT_TEMPLATE } from './types';
import type { ConstantFormula } from './types.tsx';

export const constant = (value: number): ConstantFormula => ({
	template: CONSTANT_TEMPLATE,
	parameters: {
		value,
	},
});

export const isConstant = (formula: DynamicFieldFormula): boolean =>
	formula.template === CONSTANT_TEMPLATE;
