import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { LinkButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { getIdentityLoginURL } from '@atlassian/jira-polaris-common/src/common/utils/identity';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/controllers/user';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import messages from './messages';

export const AvatarControl = () => {
	const { formatMessage } = useIntl();
	const { data, error, loading } = useCurrentUser();
	const isSharedView = useIsSharedView();
	const environment = useEnvironment();

	if (isSharedView && !loading && data === null) {
		return (
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			<LinkButton href={getIdentityLoginURL(environment, window.location.href)}>
				{formatMessage(messages.login)}
			</LinkButton>
		);
	}

	if ((loading || error || data === null) && !isSharedView) {
		return <Avatar appearance="circle" size="small" />;
	}

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...triggerProps }) => (
				<AvatarContainer>
					<Avatar
						{...triggerProps}
						appearance="circle"
						src={data?.avatarUrls['48x48']}
						size="small"
						name={data?.displayName}
						ref={triggerRef}
					/>
				</AvatarContainer>
			)}
			placement="bottom-start"
		>
			<DropdownItemGroup>
				<DropdownItem href="/logout" UNSAFE_shouldDisableRouterLink>
					{formatMessage(messages.logout)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	cursor: 'pointer',
});
