import React, { type ComponentPropsWithoutRef, useState, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import AkEmojiPicker, { Emoji } from '@atlaskit/emoji';
import EmojiAddIcon from '@atlaskit/icon/core/migration/emoji-add';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useEmoji, useEmojiProviderPromise } from '../controllers';
import messages from './messages';
import type { EmojiPickerProps } from './types';

export const EmojiPicker = ({
	onEmojiSelected,
	selectedEmojiId,
	readonly = false,
	placement = 'bottom-start',
	noPadding = false,
	color,
	offset,
	zIndex,
	emojiAddIcon,
	shouldRenderToParent = false,
	onOpenChange,
	onEmojiLoaded,
}: EmojiPickerProps) => {
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { formatMessage } = useIntl();
	const selectedEmoji = useEmoji(selectedEmojiId);
	const emojiProviderPromise = useEmojiProviderPromise();

	const onSelection = useCallback<
		NonNullable<ComponentPropsWithoutRef<typeof AkEmojiPicker>['onSelection']>
	>(
		(_emoji, emojiDescription) => {
			setIsPickerOpen(false);
			onEmojiSelected?.(emojiDescription);
		},
		[onEmojiSelected],
	);

	const handleEmojiClick = () => {
		setIsMenuOpen(true);
		setIsPickerOpen(false);
	};

	const handleEditEmoji = () => {
		setIsMenuOpen(false);
		setIsPickerOpen(true);
	};

	const toggleEmojiPicker = () => {
		setIsPickerOpen((prev) => !prev);
	};

	const handleDeleteEmoji = () => {
		setIsMenuOpen(false);
		onEmojiSelected?.(undefined);
	};

	useEffect(() => {
		onOpenChange?.(isPickerOpen);
	}, [isPickerOpen, onOpenChange]);

	useEffect(() => {
		if (ff('polaris.publish.replace-view-icon-when-publishing')) {
			if (selectedEmoji === undefined) {
				onEmojiLoaded?.(null);
			}
		}
	}, [selectedEmoji, onEmojiLoaded]);

	return (
		<Flex direction="column" xcss={emojiPickerContainerStyles}>
			<Popup
				isOpen={isPickerOpen}
				onClose={() => setIsPickerOpen(false)}
				content={() => (
					<Box>
						<AkEmojiPicker emojiProvider={emojiProviderPromise} onSelection={onSelection} />
					</Box>
				)}
				shouldRenderToParent={shouldRenderToParent}
				trigger={(emojiPickerTriggerProps) => (
					<Popup
						isOpen={isMenuOpen}
						content={() => (
							<MenuGroup onClick={(e) => e.stopPropagation()}>
								<Section>
									<ButtonItem
										onClick={handleEditEmoji}
										testId="polaris-lib-emoji-picker.ui.edit-button"
									>
										{formatMessage(messages.editEmoji)}
									</ButtonItem>
									<ButtonItem
										onClick={handleDeleteEmoji}
										testId="polaris-lib-emoji-picker.ui.remove-button"
									>
										{formatMessage(messages.removeEmoji)}
									</ButtonItem>
								</Section>
							</MenuGroup>
						)}
						placement="bottom-start"
						onClose={() => setIsMenuOpen(false)}
						trigger={(popupTriggerProps) =>
							selectedEmoji ? (
								<EmojiButton
									data-testid="polaris-lib-emoji-picker.ui.emoji-button"
									{...(isMenuOpen ? popupTriggerProps : emojiPickerTriggerProps)}
									disabled={readonly}
									color={color}
									onClick={handleEmojiClick}
									noPadding={noPadding}
									type="button"
								>
									<Emoji
										emoji={selectedEmoji}
										fitToHeight={24}
										showTooltip
										onLoadSuccess={
											ff('polaris.publish.replace-view-icon-when-publishing')
												? onEmojiLoaded
												: undefined
										}
									/>
								</EmojiButton>
							) : (
								<IconButton
									testId="polaris-lib-emoji-picker.ui.add-button"
									{...emojiPickerTriggerProps}
									isDisabled={readonly}
									icon={emojiAddIcon ?? EmojiAddIcon}
									appearance="subtle"
									label={formatMessage(messages.emojiButtonCaption)}
									onClick={toggleEmojiPicker}
									spacing={noPadding ? 'compact' : 'default'}
									type="button"
								/>
							)
						}
						zIndex={zIndex}
					/>
				)}
				placement={placement}
				offset={offset}
				zIndex={zIndex}
			/>
		</Flex>
	);
};

const emojiPickerContainerStyles = xcss({
	maxWidth: '192px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiButton = styled.button<{ noPadding: boolean; color?: string }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ noPadding, color }) => ({
		display: 'flex',
		border: 'none',
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${noPadding ? 24 : 32}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${noPadding ? 24 : 32}px`,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: token('border.radius.100', '3px'),
		transition: '0.1s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: `${color || 'transparent'}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:hover:not(disabled)': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.neutral', colors.N100),
		},
		'&:active': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.neutral.hovered', colors.N30),
		},
		'&:disabled': {
			backgroundColor: 'transparent',
			cursor: 'not-allowed',
			opacity: 0.3,
		},
	}),
);
