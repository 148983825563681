import { useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useStoreActions } from './controllers';

export const EnvironmentTenantProvider = () => {
	const { load } = useStoreActions();
	const apolloClient = useApolloClient();
	const cloudId = useCloudId();
	const { generalDataLoadingFailedError } = useErrorHandlers();
	const container = useEnvironmentContainer();

	useEffect(() => {
		load({
			apolloClient,
			cloudId,
			onLoadingFailed: generalDataLoadingFailedError,
			container,
		});
	}, [apolloClient, cloudId, generalDataLoadingFailedError, load, container]);

	return null;
};

export const useBetaActions = () => {
	const apolloClient = useApolloClient();
	const cloudId = useCloudId();

	const {
		tryAddCurrentUserAsSiteCreator,
		toggleEnforcePermissionChecks,
		toggleSelfServeAddAsSiteCreator,
	} = useStoreActions();

	return {
		tryAddCurrentUserAsSiteCreator: () => tryAddCurrentUserAsSiteCreator({ apolloClient, cloudId }),
		toggleEnforcePermissionChecks,
		toggleSelfServeAddAsSiteCreator,
	};
};
