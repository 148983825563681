import React from 'react';
import Link from '@atlaskit/link';
import { Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { useCanManageGlobalFields } from '../../../../controllers';
import messages from './messages';

const GLOBAL_FIELDS_PAGE = '/jira/settings/products/jira-product-discovery/global-fields';

type GlobalFieldPromptProps = {
	fieldType: FieldType;
};

export const GlobalFieldPrompt = ({ fieldType }: GlobalFieldPromptProps) => {
	const { formatMessage } = useIntl();
	const canManageGlobalFields = useCanManageGlobalFields();

	const queryParams = new URLSearchParams();
	queryParams.set('openNewFieldPanel', 'true');
	queryParams.set('fieldType', fieldType);
	const href = `${GLOBAL_FIELDS_PAGE}?${queryParams.toString()}`;

	const actions = canManageGlobalFields
		? [
				<SectionMessageAction
					key="create-global-field"
					href={href}
					linkComponent={(props) => <Link {...props} target="_blank" />}
				>
					{formatMessage(messages.createAGlobalField)}
				</SectionMessageAction>,
			]
		: [];

	return (
		<SectionMessage
			actions={actions}
			testId="polaris-component-copy-values.ui.copy-values-sidebar.copy-values-form.global-field-prompt"
		>
			<Text>
				{canManageGlobalFields
					? formatMessage(messages.noGlobalFieldsOfTypeAdmin)
					: formatMessage(messages.noGlobalFieldsOfType)}
			</Text>
		</SectionMessage>
	);
};
