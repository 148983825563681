import React from 'react';
import { PolarisIdeasCommonContext } from '../context';
import { Container, IdeaContextHandler } from './main';
import type { Props } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useIdeaActions } from './main';

export const IdeaViewContainer = ({ children, ...rest }: Props) => (
	<PolarisIdeasCommonContext.Consumer>
		{(context) => (
			<Container {...rest}>
				<IdeaContextHandler context={context} />
				{children}
			</Container>
		)}
	</PolarisIdeasCommonContext.Consumer>
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ExternalProps } from './types';
