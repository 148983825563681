import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { FieldIdMap } from '@atlassian/jira-polaris-component-field-id-mapping/src/controllers/types.tsx';
import { toProjectAri } from '@atlassian/jira-polaris-domain-project/src/utils.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createCachedViewConfigFetcher } from '../services/jpd-views-service/view-config';
import type { ViewRemote } from '../types';
import {
	createPolarisApiViewArrangementInfoRemote,
	createSharingViewArrangementInfoRemote,
	createCollectionViewArrangementInfoRemote,
} from './arrangement';
import {
	createPolarisApiViewCommentsRemote,
	createSharingViewCommentsRemote,
	createNotImplementedViewCommentsRemote,
} from './comments';
import {
	createPolarisApiViewDescriptionRemote,
	createSharingViewDescriptionRemote,
	createCollectionViewDescriptionRemote,
} from './description';
import {
	createSharingViewLastViewedRemote,
	createCollectionViewLastViewedRemote,
} from './last-viewed/jpd-views-service/index.tsx';
import { createPolarisApiViewLastViewedRemote } from './last-viewed/polaris-api/index.tsx';
import {
	createViewMarkersRemote,
	createSharingViewMarkersRemote,
	createCollectionViewMarkersRemote,
} from './markers';
import {
	createNotImplementedViewRankRemote,
	createCollectionViewRankRemote,
	createPolarisApiViewRankRemote,
} from './rank';
import {
	createSharingImplementedViewRankFieldRemote,
	createNotImplementedViewRankFieldRemote,
} from './rank-field';
import {
	createViewSharingRemote,
	createSharedViewSharingRemote,
	createCollectionViewSharingRemote,
} from './sharing';
import { createNotImplementedViewSetsRemote, createPolarisApiViewSetsRemote } from './view-sets';
import {
	createViewEntityRemote,
	createSharingViewEntityRemote,
	createCollectionViewEntityRemote,
} from './views';

export const createViewsRemote = (
	cloudId: CloudId,
	projectId: ProjectId,
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	fieldIdMappingPromise: Promise<FieldIdMap>,
): ViewRemote => {
	const projectAri = toProjectAri(projectId, cloudId);

	return {
		...createNotImplementedViewRankFieldRemote(),
		...createPolarisApiViewSetsRemote(apolloClient, projectAri),
		...createViewEntityRemote(cloudId, projectId, apolloClient, createAnalyticsEvent),
		...createPolarisApiViewRankRemote(apolloClient, projectAri),
		...createPolarisApiViewCommentsRemote(apolloClient),
		...createPolarisApiViewDescriptionRemote(apolloClient),
		...createPolarisApiViewArrangementInfoRemote(apolloClient, fieldIdMappingPromise),
		...createPolarisApiViewLastViewedRemote(apolloClient),
		...createViewSharingRemote(),
		...createViewMarkersRemote(),
	};
};

export const createSharingViewsRemote = (
	cloudId: CloudId,
	viewId: string,
	atlassianAccountId: string | null,
): ViewRemote => {
	const viewConfigFetcher = createCachedViewConfigFetcher(viewId);

	return {
		...createNotImplementedViewSetsRemote(),
		...createSharingViewEntityRemote(cloudId, viewId, viewConfigFetcher),
		...createNotImplementedViewRankRemote(),
		...createSharingViewCommentsRemote(viewId),
		...createSharingViewDescriptionRemote(viewConfigFetcher),
		...createSharingViewArrangementInfoRemote(viewConfigFetcher),
		...createSharingViewLastViewedRemote(viewConfigFetcher, atlassianAccountId),
		...createSharingImplementedViewRankFieldRemote(viewConfigFetcher),
		...createSharedViewSharingRemote(),
		...createSharingViewMarkersRemote(),
	};
};

export const createCollectionViewsRemote = (
	cloudId: CloudId,
	collectionUUID: string,
): ViewRemote => ({
	// not implemented
	...createNotImplementedViewRankFieldRemote(),
	...createNotImplementedViewSetsRemote(),
	...createNotImplementedViewCommentsRemote(),
	// mocked
	...createCollectionViewSharingRemote(),
	// ready
	...createCollectionViewArrangementInfoRemote(collectionUUID),
	...createCollectionViewEntityRemote(cloudId, collectionUUID),
	...createCollectionViewRankRemote(cloudId, collectionUUID),
	...createCollectionViewDescriptionRemote(collectionUUID),
	...createCollectionViewLastViewedRemote(collectionUUID),
	...createCollectionViewMarkersRemote(collectionUUID),
});
