import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import Button, { LoadingButton } from '@atlaskit/button';
import Form from '@atlaskit/form';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useIsProjectAdmin,
	useIsAdmin,
	useIsSiteAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	DESCRIPTION_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { prepareForSave } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useIssueActions } from '../../../controllers/issue/main.tsx';
import { useSubmitIdeaFormView } from '../../../controllers/project-properties';
import { useIdeaIssueTypes } from '../../../controllers/project/selectors/project-hooks.tsx';
import { usePolarisRouter } from '../../../controllers/route';
import { useUserPropertiesActions, useUserProperty } from '../../../controllers/user-properties';
import { useValidIssueSubmissionFormFieldKeys } from '../../../controllers/views/selectors/view-hooks';
import ConfirmDismissModal from '../confirmation-dialog';
import { IssueCreationForm } from '../creation-form';
import { LeavePageWarning } from '../leave-page-warning';
import { useOnSubmittedIssue } from '../notification';
import { useTryApplyFiltersToIssuePayload } from '../utils';
import messages from './messages';
import { ProjectInfo } from './project-info';

const CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY = 'polaris.create-idea-form-modal-hint-closed';

export type IssueFormData = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export const IssueCreationModalDialog = ({
	setIsOpen,
	isOpenModal,
}: {
	isOpenModal: boolean;
	setIsOpen: (isOpen: boolean) => void;
}) => {
	const [isCreating, setCreating] = useState(false);
	const [isModalFinishOpening, setModalFinishOpening] = useState(false);
	const [shouldShowConfirmationDialog, setShouldShowConfirmationDialog] = useState(false);
	const { formatMessage } = useIntl();
	const { submitIdea } = useIssueActions();
	const [ideaTypes] = useIdeaIssueTypes();
	const onSubmittedIssueSuccessfully = useOnSubmittedIssue();
	const [isPreviewContributorMode] = useIsPreviewContributorMode();
	const { routeTo, setQuery } = usePolarisRouter();

	const [isProjectAdmin] = useIsProjectAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const isAdmin = useIsAdmin();

	const viewId = useSubmitIdeaFormView();
	const viewFieldKeys = useValidIssueSubmissionFormFieldKeys(viewId.value);

	const applyFiltersToIssuePayload = useTryApplyFiltersToIssuePayload(viewId.value);

	const [, { loadProperty, saveProperty }] = useUserPropertiesActions();
	const [{ value: isHintClosed, isLoading: isLoadingHint, error: hintClosedError }] =
		useUserProperty(CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY);

	const isSpotlightOpen = isHintClosed !== true && hintClosedError === null && !isLoadingHint;

	const shouldShowSpotlight = useMemo(
		() => isPreviewContributorMode && isModalFinishOpening && isSpotlightOpen,
		[isSpotlightOpen, isModalFinishOpening, isPreviewContributorMode],
	);

	useEffect(() => {
		loadProperty(CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY);
	}, [loadProperty]);

	const clearSearchParam = useCallback(() => {
		setQuery({ openIdeaCreationModal: undefined }, 'replace');
	}, [setQuery]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);
		const isCreationModalOpen = params.get('openIdeaCreationModal');
		if (isCreationModalOpen) {
			setIsOpen(true);
		}
	}, [setIsOpen]);

	const handleCloseHint = useCallback(() => {
		saveProperty(CREATE_IDEA_FORM_MODAL_HINT_CLOSED_KEY, true);
	}, [saveProperty]);

	// use summary and description fields by default if empty fieldKeys list return for form
	const fieldKeysWithDescription = viewFieldKeys.length ? [...viewFieldKeys] : [SUMMARY_FIELDKEY];
	fieldKeysWithDescription.splice(
		fieldKeysWithDescription.indexOf(SUMMARY_FIELDKEY) + 1,
		0,
		DESCRIPTION_FIELDKEY,
	);

	const onSubmitIdea = useCallback(
		(fieldsToUpdate: IssueFormData) => {
			if (!ideaTypes?.[0]) {
				return;
			}
			submitIdea(
				ideaTypes[0],
				fieldsToUpdate.summary,
				fieldsToUpdate,
				(issueKey?: IssueKey, isFiltered?: boolean) => {
					setIsOpen(false);
					clearSearchParam();
					onSubmittedIssueSuccessfully(issueKey, isFiltered);
				},
				() => setCreating(false),
			);
		},
		[ideaTypes, onSubmittedIssueSuccessfully, setIsOpen, submitIdea, clearSearchParam],
	);

	const onSubmit = useCallback(
		(data: IssueFormData) => {
			const descriptionForSave = data.description
				? prepareForSave(data.description)
				: data.description;
			const transformedData = {
				...data,
				description: descriptionForSave,
			};
			setCreating(true);
			const payloadWithAppliedFilters = applyFiltersToIssuePayload(transformedData);
			onSubmitIdea(payloadWithAppliedFilters);
			experience.issues.globalJpdIssueCreate.success();
		},
		[applyFiltersToIssuePayload, onSubmitIdea],
	);

	const onModalCloseWithConfirm = useCallback(
		(isDirty: boolean) => {
			if (isDirty) {
				setShouldShowConfirmationDialog(true);
			} else {
				setIsOpen(false);
				clearSearchParam();
				experience.issues.globalJpdIssueCreate.abort();
			}
		},
		[setIsOpen, clearSearchParam],
	);

	const onConfirmDiscardIssue = useCallback(() => {
		setShouldShowConfirmationDialog(false);
		setIsOpen(false);
		clearSearchParam();
		experience.issues.globalJpdIssueCreate.abort();
	}, [setIsOpen, clearSearchParam]);

	const onFormSettingsClicked = useCallback(() => {
		routeTo({
			section: 'settings',
			resource: 'create-ideas',
		});
		setIsOpen(false);
		clearSearchParam();
		experience.issues.globalJpdIssueCreate.abort('User navigated to settings');
	}, [routeTo, setIsOpen, clearSearchParam]);

	const onProjectSwitch = useCallback(() => {
		setIsOpen(false);
		clearSearchParam();
		experience.issues.globalJpdIssueCreate.abort('User switched project');
	}, [setIsOpen, clearSearchParam]);

	const renderConfirmationModalDialog = () => {
		if (shouldShowConfirmationDialog) {
			return (
				<ConfirmDismissModal
					onConfirm={onConfirmDiscardIssue}
					onCancel={() => setShouldShowConfirmationDialog(false)}
				/>
			);
		}
		return null;
	};

	return (
		<>
			<ModalTransition>
				{isOpenModal && (
					<ContextualAnalyticsData sourceName="createIdea" sourceType={MODAL}>
						<ShortcutScope>
							<Modal
								testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.issue-creation-form-modal"
								width="large"
								autoFocus={false}
								shouldCloseOnOverlayClick={false}
								onOpenComplete={() => setModalFinishOpening(true)}
							>
								<Form onSubmit={onSubmit}>
									{({ formProps, setFieldValue, dirty }) => (
										<>
											<ModalHeader>
												<TitleWrapper>
													<ModalTitle>{formatMessage(messages.createIdeaMessage)}</ModalTitle>
													{(isProjectAdmin || isAdmin || isSiteAdmin) && (
														<Button
															appearance="subtle"
															iconBefore={
																<EditorSettingsIcon label="form settings" size="medium" />
															}
															onClick={onFormSettingsClicked}
														>
															{formatMessage(messages.customizeFormBtn)}
														</Button>
													)}
												</TitleWrapper>
												<ToolTip position="top" content={formatMessage(messages.close)}>
													<Button
														appearance="subtle"
														onClick={() => onModalCloseWithConfirm(dirty)}
														iconBefore={<CrossIcon label={formatMessage(messages.close)} />}
													/>
												</ToolTip>
											</ModalHeader>
											<ModalBody>
												<FireScreenAnalytics />
												<ProjectInfo onProjectSwitch={onProjectSwitch} />
												<form {...formProps} id="idea-create.ui.modal.create-form">
													<IssueCreationForm
														setFieldValue={setFieldValue}
														isPreview={isPreviewContributorMode && isSpotlightOpen}
													/>
												</form>
											</ModalBody>
											<ModalFooter>
												<Button
													isDisabled={isCreating}
													appearance="subtle"
													onClick={() => onModalCloseWithConfirm(dirty)}
												>
													{formatMessage(messages.cancelButton)}
												</Button>
												<SpotlightTarget name="contributor-create-idea-form-modal">
													<LoadingButton
														id="polaris.common.src.ui.create-idea-dialog.submit-idea-loading-button"
														appearance="primary"
														form="idea-create.ui.modal.create-form"
														type="submit"
														isLoading={isCreating}
													>
														{formatMessage(messages.createButton)}
													</LoadingButton>
												</SpotlightTarget>
											</ModalFooter>
											<LeavePageWarning isDirty={dirty} />
										</>
									)}
								</Form>
							</Modal>
						</ShortcutScope>
					</ContextualAnalyticsData>
				)}
			</ModalTransition>
			<SpotlightTransition>
				{shouldShowSpotlight && (
					<JiraSpotlight
						actions={[
							{
								onClick: handleCloseHint,
								text: formatMessage(messages.spotlightCloseBtn),
							},
						]}
						target="contributor-create-idea-form-modal"
						key="contributor-create-idea-form-modal"
						dialogPlacement="top right"
						targetRadius={3}
						messageId="polaris-common.ui.create-idea-dialog.form-modal-dialog.jira-spotlight.contributor-create-idea-form-modal"
						messageType="transactional"
					>
						{formatMessage(messages.spotlightCreateIdeaBtn)}
					</JiraSpotlight>
				)}
			</SpotlightTransition>
			{renderConfirmationModalDialog()}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});
