import React, { type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import GrowDiagonalIcon from '@atlaskit/icon/core/grow-diagonal';
import ShrinkDiagonalIcon from '@atlaskit/icon/core/shrink-diagonal';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { fireUIAnalytics, type Attributes } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	testId: string;
	analyticsAttributes: Attributes;
	onToggle: (isFullScreen: boolean, globalStyle?: HTMLElement) => void;
};

const FullscreenButton = ({
	testId,
	onToggle,
	analyticsAttributes: extraAnalyticsAttributes,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isFullscreenLayout, { setIsFullscreen: setIsFullScreenLayout }] = useIsFullscreen();

	const onClick = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		const analyticsAttributes: Attributes = {
			...extraAnalyticsAttributes,
			expandToFullscreen: !isFullscreenLayout,
		};

		fireUIAnalytics(analyticsEvent, 'fullscreenButton', analyticsAttributes);
		setIsFullScreenLayout(!isFullscreenLayout);
		onToggle(!isFullscreenLayout);
	};

	const keyMap: ActiveKeyMap = {
		z: {
			callback: () => {
				setIsFullScreenLayout(!isFullscreenLayout);
			},
		},
	};

	return (
		<>
			<IconButton
				testId={testId}
				appearance="subtle"
				onClick={onClick}
				// Show the tooltip when the button is hovered
				isTooltipDisabled={false}
				label={formatMessage(
					isFullscreenLayout ? messages.exitFullScreen : messages.enterFullScreen,
				)}
				icon={isFullscreenLayout ? ShrinkDiagonalIcon : GrowDiagonalIcon}
			/>
			<Shortcuts keyMap={keyMap} />
		</>
	);
};

FullscreenButton.defaultProps = {
	onToggle: noop,
	analyticsAttributes: {},
};

export default FullscreenButton;
