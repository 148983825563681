import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { HelperMessage } from '@atlaskit/form';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { useIntl } from '@atlassian/jira-intl';
import type { SelectedProject } from '../../common/ui/project-picker/option';
import { useIssueLinksMap } from '../../controllers/idea/selectors/hooks';
import { IssueSelect } from '../common/issue-select/main.tsx';
import type { GroupedOption, IssueOption } from '../common/issue-select/types.tsx';
import { MinimalLozenge } from '../lozenge';
import { messages } from './messages';

type LinkIssueSelectProps = {
	isDisabled: boolean;
	projectId: string | undefined;
	excludedProjectTypes: ProjectType[];
	defaultOptions?: GroupedOption;
	hideArchived: boolean;
	onIssueSelected: (arg1: IssueOption | undefined) => void;
	isPoalrisIssueLink: boolean;
	onProjectChanged?: (payload: SelectedProject | undefined) => void;
};

export const LinkIssueSelect = ({ isPoalrisIssueLink, ...props }: LinkIssueSelectProps) => {
	const { formatMessage } = useIntl();
	const issueLinksMap = useIssueLinksMap();

	const renderOptionIconAfter = useCallback(
		({ item }: IssueOption) => {
			const id = String(item?.id);
			const linkedIssue = issueLinksMap.get(id);

			if (!isPoalrisIssueLink || !linkedIssue) return null;

			return (
				<LinkIconWrapper>
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
					<LinkIcon
						size="small"
						primaryColor={token('color.border.accent.blue', colors.B400)}
						label="link"
					/>
				</LinkIconWrapper>
			);
		},
		[isPoalrisIssueLink, issueLinksMap],
	);

	return (
		<>
			<IssueSelect
				isAttachedToBody
				renderOptionIconAfter={renderOptionIconAfter}
				autoFocus
				{...props}
			/>
			<HelperMessage>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<span>
					{formatMessage(messages.linkIssueSelectHelperText, {
						status: (
							<MinimalLozenge appearance="success">
								{formatMessage(messages.doneStatusLabel)}
							</MinimalLozenge>
						),
					})}
				</span>
			</HelperMessage>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkIconWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: 21,
	height: 13,
	backgroundColor: token('color.background.accent.blue.subtlest', '#E9F2FF'),
	borderRadius: 8,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& svg': {
		transform: 'rotate(42deg)',
	},
});
