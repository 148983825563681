import React, {
	useState,
	useCallback,
	useRef,
	useEffect,
	type ComponentPropsWithoutRef,
} from 'react';
import { styled } from '@compiled/react';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraDetailPanel } from '@atlassian/jira-layout-controller/src/ui/detail-panel/index.tsx';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { useCloseRightSidebar } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	usePreventClosingSidebar,
	useRightSidebarShowing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingIdeaTemplates,
	RightSidebarShowingNothing,
	RightSidebarShowingPlay,
	RightSidebarShowingField,
	RightSidebarShowingGlobalFields,
	RightSidebarShowingCreateField,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { setSidebarWidthCssVariable } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/utils/sidebar-width-css-variable.tsx';
import { setSidebarWidthToStorage } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/utils/storage.tsx';
import {
	usePolarisRouter,
	useIsIssueOpenInFullscreen,
	useIsIssueOpenInSidebar,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useHasUnsavedChanges,
	useViewAnalyticsData,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { PolarisNavigationBlocker } from '@atlassian/jira-polaris-common/src/ui/navigation-blocker';
import { N0 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter';
import { sized, type SizedProps } from '@atlassian/jira-polaris-lib-resize-observer';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	DRAWER,
	ContextualAnalyticsData,
	fireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { shouldPreventClosing } from '../click-focus-checker';
import { SIDEBAR_MAX_WIDTH_RATIO, RESIZABLE_SIDEBAR_MIN_WIDTH } from './constants';
import { ResizeHandle } from './resize-handle/main.tsx';
import { SidebarContent } from './sidebar-content';
import {
	getCalculatedWidth,
	isSidebarResizable,
	getSidebarWidth,
	convertRightSidebarModeToAnalyticsSourceName,
} from './utils';

export type RightSidebarProps = {
	isFullScreen?: boolean;
};

const withRightSidebarAnalytics =
	<P extends object>(Component: React.ComponentType<P>) =>
	(props: P) => {
		const [{ mode }] = useRightSidebarShowing();
		const [sidebarShowing] = useRightSidebarShowing();
		const fieldKey =
			sidebarShowing.mode === RightSidebarShowingField && sidebarShowing.fieldKey !== undefined
				? sidebarShowing.fieldKey
				: undefined;
		const field = useField(fieldKey);
		const fieldAttributes = fieldKey
			? {
					issueFieldKey: fieldKey,
					issueFieldType: field?.type,
					issueFieldSource: field?.configuration?.source,
				}
			: {};
		const viewAnalyticsData = useViewAnalyticsData();

		return (
			<ContextualAnalyticsData
				sourceName={convertRightSidebarModeToAnalyticsSourceName(mode)}
				sourceType={DRAWER}
				attributes={fieldAttributes}
				containers={viewAnalyticsData?.containers}
			>
				<Component {...props} />
			</ContextualAnalyticsData>
		);
	};

export const RightSidebar = withRightSidebarAnalytics(({ isFullScreen }: RightSidebarProps) => {
	const hasUnsavedChanges = useHasUnsavedChanges();
	const { setUnsavedChanges } = useViewActions();
	const [sidebarShowing] = useRightSidebarShowing();
	const { closeIssueView } = usePolarisRouter();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const isResizable = isSidebarResizable(isIssueOpenInSidebar, sidebarShowing.mode);
	const closeRightSidebar = useCloseRightSidebar();
	const { resetFieldSidebarMode } = useIdeaActions();
	const sidebarRef = useRef<HTMLDivElement | null>(null);
	const [showPrompt, setShowPrompt] = useState<boolean>(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentWidth = getSidebarWidth(sidebarShowing.mode, isResizable);
	const [resizedWidth, setResizedWidth] = useState(currentWidth);
	const [preventClosing] = usePreventClosingSidebar();

	useEffect(
		() => () => {
			setSidebarWidthCssVariable(0);
		},
		[],
	);

	useEffect(() => {
		setSidebarWidthCssVariable(isResizable ? resizedWidth : currentWidth);
	}, [currentWidth, isResizable, resizedWidth, sidebarShowing.mode]);

	useCloseOnEscapePress({
		onClose: (event) => {
			if (ignoreEscapePress(event)) {
				return;
			}
			closeRightSidebar();

			setSidebarWidthCssVariable(0);
		},
		isDisabled: preventClosing === 'onEsc',
	});

	useEffect(() => {
		if (sidebarShowing.mode !== RightSidebarShowingNothing) {
			fireScreenAnalytics(createAnalyticsEvent({}));
		}
	}, [sidebarShowing.mode, createAnalyticsEvent]);

	const closeSidebar = useCallback(() => {
		// if no other sidebar is displayed except issue view preview
		if (isIssueOpenInSidebar) {
			resetFieldSidebarMode();
			closeIssueView();
		}

		if (sidebarShowing.mode !== RightSidebarShowingNothing) {
			closeRightSidebar();
		}

		setSidebarWidthCssVariable(0);
	}, [
		closeIssueView,
		closeRightSidebar,
		resetFieldSidebarMode,
		isIssueOpenInSidebar,
		sidebarShowing.mode,
	]);

	const resetPromptState = useCallback(() => {
		closeSidebar();

		setUnsavedChanges(false);
		setShowPrompt(false);
	}, [closeSidebar, setUnsavedChanges, setShowPrompt]);

	const handleCloseAfterPrompt = useCallback(
		(event?: Event) => {
			if (shouldPreventClosing(event?.target)) {
				return;
			}

			if (hasUnsavedChanges && !showPrompt && ff('polaris.unsaved-changes-prompt-active')) {
				event?.stopPropagation?.();
				event?.preventDefault?.();
			}

			if (!hasUnsavedChanges || sidebarShowing.mode === RightSidebarShowingNothing) {
				closeSidebar();
				return;
			}

			setShowPrompt(true);
		},
		[sidebarShowing.mode, setShowPrompt, showPrompt, hasUnsavedChanges, closeSidebar],
	);

	const handleResize = useCallback(
		(deltaX: number) => {
			const newWidth = getCalculatedWidth(
				resizedWidth,
				deltaX,
				sidebarRef.current?.parentElement?.clientWidth,
			);
			setResizedWidth(newWidth);
		},
		[resizedWidth],
	);

	const handleResizeStop = useCallback(
		(deltaX: number) => {
			const newWidth = getCalculatedWidth(
				resizedWidth,
				deltaX,
				sidebarRef.current?.parentElement?.clientWidth,
			);
			setSidebarWidthToStorage(newWidth);
		},
		[resizedWidth],
	);

	const handleClickOutside = useCallback(
		(event: Event) => {
			const isDNDDropEvent = event instanceof DragEvent && event.type === 'drop';
			if (
				isDNDDropEvent ||
				(isIssueOpenInFullscreen && sidebarShowing.mode === RightSidebarShowingPlay)
			) {
				return;
			}

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'screen',
				}),
				'close',
			);

			handleCloseAfterPrompt(event);
		},
		[isIssueOpenInFullscreen, sidebarShowing.mode, createAnalyticsEvent, handleCloseAfterPrompt],
	);

	const handleRightSidebarContainerSizeChanged = useCallback<
		NonNullable<SizedProps['onSizeChanged']>
	>((width) => {
		setSidebarWidthCssVariable(width);
	}, []);

	const isOpenedFromProjectFieldsPage =
		(sidebarShowing.mode === RightSidebarShowingField ||
			sidebarShowing.mode === RightSidebarShowingCreateField) &&
		sidebarShowing.origin === 'projectFieldsPage';

	// todo add modes for field create and edit
	const isAbsolutePositionedSidebar =
		sidebarShowing.mode === RightSidebarShowingIdeaTemplates ||
		sidebarShowing.mode === RightSidebarShowingGlobalFields ||
		isOpenedFromProjectFieldsPage;

	return (
		<>
			{sidebarShowing.mode !== RightSidebarShowingNothing && (
				<PolarisNavigationBlocker
					isDirty={hasUnsavedChanges}
					onDiscard={resetPromptState}
					isPromptOpen={showPrompt}
					setIsPromptOpen={setShowPrompt}
				/>
			)}
			<OutsideClickAlerter onClickOutside={handleClickOutside}>
				{(outsideClickAlerterProps) => (
					<JiraDetailPanel panelWidth={currentWidth}>
						<RightSidebarContainer
							{...outsideClickAlerterProps}
							onSizeChanged={
								fg('polaris_right_sidebar_resize_observer')
									? handleRightSidebarContainerSizeChanged
									: undefined
							}
							width={isResizable ? resizedWidth : currentWidth}
							isAbsolute={isAbsolutePositionedSidebar}
							ref={sidebarRef}
							data-testid="polaris-ideas.ui.right-sidebar.polaris-right-sidebar"
							isResizable={isResizable}
							isFullScreen={isFullScreen}
						>
							{isResizable && (
								<ResizeHandle onResize={handleResize} onResizeStop={handleResizeStop} />
							)}
							<SidebarContent onClose={handleCloseAfterPrompt} />
						</RightSidebarContainer>
					</JiraDetailPanel>
				)}
			</OutsideClickAlerter>
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightSidebarContainerStyled = styled.div<{
	width: number;
	isResizable: boolean;
	isAbsolute: boolean;
	isFullScreen?: boolean;
	// Just to sync interfaces between RightSidebarContainerStyled & RightSidebarContainerSized
	// to make feature flagging much easier
	onSizeChanged?: SizedProps['onSizeChanged'];
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: ({ isAbsolute }) => (isAbsolute ? 'absolute' : 'relative'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	right: ({ isAbsolute }) => (isAbsolute ? 0 : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', N0),
	flex: '0 0 auto',
	// Linked issues in idea view have z-index 103, so templates sidebar should have higher z-index to overlap it
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: ({ isAbsolute }) => (isAbsolute ? 104 : 50),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isAbsolute, isFullScreen }) =>
		isAbsolute
			? `calc(100vh - ${
					isFullScreen ? '0px' : '(var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px))'
				})`
			: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	maxWidth: ({ isResizable }) => (isResizable ? `${SIDEBAR_MAX_WIDTH_RATIO * 100}%` : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	minWidth: ({ isResizable }) => (isResizable ? `${RESIZABLE_SIDEBAR_MIN_WIDTH}px` : 'auto'),
	'&::before': {
		content: '',
		position: 'absolute',
		bottom: 0,
		top: 0,
		left: '-3px',
		zIndex: 50,
		width: '3px',
		boxShadow: token('elevation.shadow.overflow', ''),
		background: token(
			'color.border',
			'linear-gradient(to left, rgba(0, 0, 0, 0.23) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%)',
		),
		opacity: 0.5,
		pointerEvents: 'none',
	},
});

const RightSidebarContainerSized = sized<
	ComponentPropsWithoutRef<typeof RightSidebarContainerStyled>
>(RightSidebarContainerStyled);

const RightSidebarContainer = componentWithFG(
	'polaris_right_sidebar_resize_observer',
	RightSidebarContainerSized,
	RightSidebarContainerStyled,
);
