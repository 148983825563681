import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { LoadingButton } from '@atlaskit/button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import OpenIcon from '@atlaskit/icon/glyph/open';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useSummary } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	issueId: LocalIssueId;
	issueTypeIconUrl: string;
	linkedJiraIssueKey: IssueKey | undefined;
	error: Error | undefined;
};

export const LinkRow = ({ issueId, linkedJiraIssueKey, error, issueTypeIconUrl }: Props) => {
	const summary = useSummary(issueId);

	const handleClick = useCallback(() => {
		if (linkedJiraIssueKey !== undefined) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(`/browse/${linkedJiraIssueKey}`, '_blank');
		}
	}, [linkedJiraIssueKey]);
	return (
		<Container key={issueId}>
			<Title>
				<Avatar
					testId="polaris-ideas.ui.view-controls.send-to-backlog-modal.link-row.avatar"
					src={issueTypeIconUrl}
					size="xsmall"
				/>
				{linkedJiraIssueKey !== undefined && <Key>{linkedJiraIssueKey}</Key>}
				<Summary>{summary}</Summary>
			</Title>
			<LoadingButton
				appearance="subtle"
				onClick={handleClick}
				isLoading={!linkedJiraIssueKey && !error}
				iconBefore={
					linkedJiraIssueKey ? (
						<OpenIcon size="small" label="Open" />
					) : (
						<ErrorIcon
							size="small"
							label="Error"
							primaryColor={token('color.text.danger', colors.R400)}
						/>
					)
				}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	display: 'flex',
	alignItems: 'center',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.div({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N300),
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	overflow: 'hidden',
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	marginLeft: token('space.100', '8px'),
});
