import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ViewConfigFetcher } from '../../../services/jpd-views-service/view-config/types';
import { transformToViewSets, transformViewConfig, getRootViewSet } from './transform';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { transformViewConfig, getRootViewSet };

export const fetchViewAsViewSet = (
	fetcher: ViewConfigFetcher,
	cloudId: CloudId,
	viewId: string,
	fields: FieldMap,
): Promise<ViewSet[]> =>
	fetcher().then((response) => transformToViewSets(viewId, response, cloudId, fields));
