import React, {
	memo,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
	type ReactNode,
} from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { Main } from '@atlassian/jira-navigation-system';
import {
	usePolarisQuery,
	useCurrentPolarisRouteSection,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import type { PolarisRoute } from '@atlassian/jira-polaris-common/src/controllers/route/types';
import { createQueryStringPostfix } from '@atlassian/jira-polaris-common/src/controllers/route/utils/query';
import {
	useCurrentViewSlug,
	useViewSlugByRank,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import {
	fireOperationalAnalyticsDeferred,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import { constructAppUrl } from '../../controllers/utils';
import { Sidebar } from '../sidebar';

export function useLandOnViewByRank() {
	const projectKey = useProjectKeyUnsafe();
	const { replace } = useRouterActions();
	const polarisQuery = usePolarisQuery();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const landOnViewByRank = useCallback(
		(viewRank: number) => {
			try {
				replace(
					`${constructAppUrl(projectKey)}${createQueryStringPostfix({ ...polarisQuery, landingViewRank: String(viewRank) })}`,
				);
				fireOperationalAnalyticsDeferred(
					createAnalyticsEvent({}),
					'appendLandingViewRank success',
					{
						landingViewRank: String(viewRank),
					},
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'jpdAppendLandingViewRankError',
						packageName: 'jiraPolarisIdeas',
					},
					attributes: {
						errorContext: 'Failed appending the URL with the landing view rank.',
						errorMessage: error.message,
						landingViewRank: String(viewRank),
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},
		[replace, projectKey, polarisQuery, createAnalyticsEvent],
	);

	return landOnViewByRank;
}

export const LandingViewRedirect = memo(({ children }: { children: ReactNode }) => {
	const section = useCurrentPolarisRouteSection();
	const polarisQuery = usePolarisQuery();
	const projectKey = useProjectKeyUnsafe();
	const viewSlug = useCurrentViewSlug();
	const hasLandingViewRank =
		polarisQuery?.landingViewRank !== undefined && polarisQuery?.landingViewRank !== null;
	const landingViewRank = +(polarisQuery?.landingViewRank ?? 1) || 1;
	const landingViewSlug = useViewSlugByRank(landingViewRank);
	const { replace } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [hasError, setHasError] = useState(false);

	const getAppUrl = useCallback(
		(route: PolarisRoute) => constructAppUrl(projectKey, route),
		[projectKey],
	);

	useEffect(() => {
		if (!hasError) {
			try {
				if (section === 'view' && viewSlug && hasLandingViewRank) {
					const resource = landingViewSlug ?? viewSlug;
					replace(
						`${getAppUrl({ resource, section })}${createQueryStringPostfix(polarisQuery, ['landingViewRank'])}`,
					);
					fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'landingViewReroute success', {
						landingViewRank: polarisQuery?.landingViewRank,
					});
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'jpdLandingViewRerouteError',
						packageName: 'jiraPolarisIdeas',
					},
					attributes: {
						errorContext: 'Failed replacing the URL with the landing view slug.',
						errorMessage: error.message,
						landingViewRank: polarisQuery?.landingViewRank,
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
				setHasError(true);
			}
		}
	}, [
		section,
		viewSlug,
		landingViewSlug,
		polarisQuery,
		getAppUrl,
		replace,
		hasError,
		children,
		hasLandingViewRank,
		createAnalyticsEvent,
	]);

	const childrenToRender = useMemo(() => {
		if (!hasError) {
			try {
				if (hasLandingViewRank && (!viewSlug || viewSlug !== landingViewSlug)) {
					return <LandingViewSkeleton />;
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'jpdLandingViewSkeletonError',
						packageName: 'jiraPolarisIdeas',
					},
					attributes: {
						errorContext: 'Failed rendering the landing view skeleton.',
						errorMessage: error.message,
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
				setHasError(true);
			}
		}

		return children;
	}, [children, hasError, hasLandingViewRank, landingViewSlug, viewSlug]);

	return childrenToRender;
});

const LandingViewSkeleton = () => {
	const MainComponent = getWillShowNav4() ? Fragment : Main;
	return (
		<Flex
			testId="polaris-ideas.ui.landing-view-redirect.landing-view-skeleton"
			xcss={getWillShowNav4() ? fullHeightStyles : undefined}
		>
			{getWillShowNav4() ? null : <Sidebar isLandingOnOtherView />}
			<MainComponent>
				<Flex
					testId="polaris-ideas.ui.landing-view-redirect.main"
					xcss={landingViewLoadingStyles}
					justifyContent="center"
					alignItems="center"
				>
					<Spinner />
				</Flex>
			</MainComponent>
		</Flex>
	);
};

const landingViewLoadingStyles = xcss({
	width: '100%',
	height: '100%',
});

const fullHeightStyles = xcss({
	flex: 1,
});
