import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import type { RawItem } from '../../common/types/timeline/index.tsx';
import type { ItemRows, ItemId } from '../../types';
import { end, overlap } from './item';

const shiftBelow = (rowArrangement: ItemRows, offset: number): ItemRows =>
	mapValues(rowArrangement, (row) => (row >= offset ? row + 1 : row));

export const updateRowArrangement = (
	itemRows: ItemRows,
	itemId: ItemId,
	row: number,
	isInNewRow: boolean,
): ItemRows => ({
	...(isInNewRow ? shiftBelow(itemRows, row) : itemRows),
	[itemId]: row,
});

export const removeEmptyRows = (itemRows: ItemRows): ItemRows => {
	const lookup = invert({ ...sortBy(uniq(Object.values(itemRows))) });
	return mapValues(itemRows, (oldRow) => Number.parseInt(lookup[oldRow], 10));
};

export const getMaxRow = (itemRows: ItemRows): number | undefined =>
	isEmpty(itemRows) ? undefined : Math.max(...Object.values(itemRows));

export const createOnePerRow = (rawItems: { id: ItemId }[], offset: number): ItemRows => {
	const mapping: Record<ItemId, number> = {};
	rawItems.forEach((item, index) => {
		mapping[item.id] = offset + index;
	});
	return mapping;
};

export const getItemsPerRow = (itemRows: ItemRows): (ItemId[] | undefined)[] => {
	const itemsPerRow: (ItemId[] | undefined)[] = [];
	Object.keys(itemRows).forEach((itemId) => {
		const list = itemsPerRow[itemRows[itemId]];
		if (list !== undefined) {
			list.push(itemId);
		} else {
			itemsPerRow[itemRows[itemId]] = [itemId];
		}
	});
	return itemsPerRow;
};

const findRow = (
	item: RawItem,
	rowTakenUntil: number[],
	fixedItem: RawItem | undefined,
): number | undefined => {
	if (item === fixedItem) {
		return 0;
	}
	const rowIndex = rowTakenUntil.findIndex(
		(endSlot, row) =>
			endSlot < item.start && (row !== 0 || !fixedItem || !overlap(fixedItem, item)),
	);
	return rowIndex >= 0 ? rowIndex : undefined;
};

export const createCompactArrangement = (
	items: RawItem[],
	fixedItemId: ItemId | undefined,
): ItemRows => {
	const itemRows: ItemRows = {};

	const fixedItem = items.find((item) => item.id === fixedItemId);
	items.sort((item1, item2) => {
		if (item1.start !== item2.start) {
			return item1.start - item2.start;
		}
		if (item1.size !== item2.size) {
			return item2.size - item1.size;
		}
		return item1.id.localeCompare(item2.id);
	});
	const rowTakenUntil: number[] = [];
	items.forEach((item) => {
		const row = findRow(item, rowTakenUntil, fixedItem);
		if (row === undefined) {
			rowTakenUntil.push(end(item));
			itemRows[item.id] = rowTakenUntil.length - 1;
		} else {
			rowTakenUntil[row] = end(item);
			itemRows[item.id] = row;
		}
	});
	return itemRows;
};

const addRows = (itemRows: ItemRows, offset: number) => mapValues(itemRows, (row) => row + offset);

export const reconcileItemArrangement = (itemRows: ItemRows, rawItems: RawItem[]): ItemRows => {
	const cleaned = removeEmptyRows(itemRows);
	const itemsPerRow = getItemsPerRow(cleaned);

	let reconciledItemArrangement = {};
	let offset = 0;
	itemsPerRow.forEach((rowItems) => {
		if (rowItems === undefined) {
			return;
		}
		const reconciledRowArrangement = createCompactArrangement(
			rawItems.filter((item) => rowItems.includes(item.id)),
			undefined,
		);

		reconciledItemArrangement = {
			...reconciledItemArrangement,
			...addRows(reconciledRowArrangement, offset),
		};
		offset = Math.max(offset, getMaxRow(reconciledItemArrangement) || 0) + 1;
	});

	return reconciledItemArrangement;
};

export const calculateItemArrangement = (items: RawItem[], itemRows: ItemRows): ItemRows => {
	const [arrangedItems, notArrangedItems] = partition(
		items,
		(item) => itemRows[item.id] !== undefined,
	);

	const preferredRows: Record<ItemId, number> = {};
	arrangedItems.forEach((item) => {
		preferredRows[item.id] = itemRows[item.id];
	});
	const cleanItemArrangement = reconcileItemArrangement(preferredRows, items);
	const maxRow = getMaxRow(cleanItemArrangement);
	return {
		...cleanItemArrangement,
		...createOnePerRow(notArrangedItems, maxRow === undefined ? 0 : maxRow + 1),
	};
};
