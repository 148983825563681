import React, { type ComponentType, useMemo, type ReactElement } from 'react';
import flow from 'lodash/flow';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	type MediaProvider,
	ProviderFactory,
	type AutoformattingProvider,
} from '@atlaskit/editor-common/provider-factory';
import type { Transformer } from '@atlaskit/editor-common/types';
import { EditorCardProvider } from '@atlaskit/smart-card';
import { type ActivityProvider, ActivityResource } from '@atlassian/activity-provider';
import { getEmojiProviderWithCustomEmoji } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import type { MediaContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import withMediaProvider from '@atlassian/jira-issue-media-provider/src/controllers/with-media-provider';
import { withAutoformattingProvider } from '@atlassian/jira-issue-view-common/src/component/smart-card/with-autoformatting-provider.tsx';
import { mediaFeatureFlags } from '@atlassian/jira-issue-view-media-feature-flags';
import type { AbstractMentionProvider } from '@atlassian/jira-mention-provider/src/services/abstract-mention-provider/index.tsx';
import { UserMentionProvider } from '@atlassian/jira-mention-provider/src/services/user-mention-provider/index.tsx';
import type { EditorProps as AkEditorProps } from '@atlassian/jira-polaris-lib-editor/src/ui/editor/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useSearchProvider } from '@atlassian/jira-search-provider';
import type {
	AccountId,
	IssueId,
	IssueKey,
	ProjectKey,
	ProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { PolarisAdfConsumerProps } from '../types';
import { ContainerlessMentionProvider } from './containerless-mention-provider';
import { MediaContextSupplier, IdeaCreateMediaContextSupplier } from './media-context';
import messages from './messages';
import { ProjectMentionProviderWithHighlight } from './project-mention-provider-with-highlight';
import type {
	IssuelessAdfProviderProps,
	AdfProviderProps,
	IdeaCreateAdfProviderProps,
	ContainerlessAdfProviderProps,
} from './types';

const ACTIVITY_URL = '/gateway/api/graphql';

// The below regex is partially copied from here https://sourcegraph-frontend.internal.shared-prod.us-west-2.kitt-inf.net/stash.atlassian.com/CONFCLOUD/confluence-frontend/-/blob/next/packages/editor-features/src/utils/mediaOptions.ts#L49:67
// It checks that the text input accepts characters from all languages along with specific special characters.
// eslint-disable-next-line no-useless-escape
const altTextValidationRegex = /^([\p{L}\p{N},'\.\s\-_\(\)])*$/u;

type PolarisAdfProviderInternalProps = {
	issueId?: IssueId;
	issueKey?: IssueKey;
	commentId?: string;
	autoformattingProvider?: AutoformattingProvider;
	mediaProvider?: Promise<MediaProvider>;
	children: (arg1: PolarisAdfConsumerProps) => ReactElement;
	projectId?: ProjectId;
	projectKey?: ProjectKey;
};

type PolarisInjectionContainerProps = {
	issueId?: IssueId;
	issueKey?: IssueKey;
	commentId?: string;
	mediaContext: MediaContext;
	children: (arg1: PolarisAdfConsumerProps) => ReactElement;
	projectId: ProjectId;
	projectKey: ProjectKey;
};

/**
 * the internals of the UserMentionProvider constructor retrieve the tenant context and cannot
 * be mocked. use this helper function to replace the constructor for dependency injection
 */
export const createUserMentionProvider = (
	issueKey: IssueKey | undefined,
	projectKey: ProjectKey | undefined,
	atlassianAccountId: AccountId | null,
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
): AbstractMentionProvider => {
	if (issueKey !== undefined) {
		return new UserMentionProvider('', issueKey, atlassianAccountId, null, createAnalyticsEvent);
	}
	if (projectKey !== undefined) {
		return new ProjectMentionProviderWithHighlight(projectKey, atlassianAccountId);
	}
	return new ContainerlessMentionProvider(atlassianAccountId);
};

const PolarisAdfProviderInternal = ({
	children,
	issueKey,
	issueId,
	commentId,
	mediaProvider,
	autoformattingProvider,
	projectId,
	projectKey,
}: PolarisAdfProviderInternalProps) => {
	const { atlassianAccountId, cloudId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const searchProvider = useSearchProvider(cloudId);

	const { formatMessage } = useIntl();

	const emojiProviderPromise = useMemo(
		() => getEmojiProviderWithCustomEmoji(cloudId, atlassianAccountId),
		[atlassianAccountId, cloudId],
	);

	const mentionProvider = useMemo(
		() =>
			createUserMentionProvider(
				issueKey,
				projectKey,
				atlassianAccountId,
				createAnalyticsEvent ?? null,
			),
		[atlassianAccountId, createAnalyticsEvent, issueKey, projectKey],
	);

	const activityProvider = useMemo<ActivityProvider>(
		() => new ActivityResource(ACTIVITY_URL, cloudId),
		[cloudId],
	);

	const contentTransformerProvider = useMemo(
		(): AkEditorProps['contentTransformerProvider'] => () =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({
				encode: (node) => node,
				parse: (node) => node,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}) as Transformer<any>,
		[],
	);

	const editorContext = useMemo(() => {
		if (issueId === undefined) {
			return undefined;
		}
		return {
			objectId: String(issueId),
			containerId: projectId || '',
			childObjectId: commentId,
		};
	}, [commentId, issueId, projectId]);

	const media = useMemo(() => {
		if (mediaProvider === undefined) {
			return undefined;
		}

		const altTextValidator = (text: string) => {
			if (!altTextValidationRegex.test(text)) {
				return [formatMessage(messages.invalidAltText)];
			}

			return [];
		};

		return {
			provider: Promise.resolve(mediaProvider),
			allowMediaSingle: true,
			allowMediaGroup: true,
			allowLinking: true,
			allowLazyLoading: true,
			allowResizing: true,
			allowResizingInTables: true,
			allowAltTextOnImages: true,
			altTextValidator,
			featureFlags: mediaFeatureFlags(),
		};
	}, [mediaProvider, formatMessage]);

	const dataProviders = useMemo(() => {
		const providerFactory = new ProviderFactory();
		providerFactory.setProvider('emojiProvider', emojiProviderPromise ?? undefined);
		if (mentionProvider !== undefined) {
			providerFactory.setProvider('mentionProvider', Promise.resolve(mentionProvider));
		}
		if (mediaProvider !== undefined) {
			providerFactory.setProvider('mediaProvider', mediaProvider);
		}
		if (editorContext !== undefined) {
			providerFactory.setProvider('contextIdentifierProvider', Promise.resolve(editorContext));
		}
		return providerFactory;
	}, [editorContext, emojiProviderPromise, mediaProvider, mentionProvider]);

	const linking = useMemo(
		() => ({
			smartLinks: {
				provider: Promise.resolve(new EditorCardProvider()),
				allowBlockCards: true,
				allowEmbeds: true,
				allowDatasource: true,
			},
		}),
		[],
	);

	const akEditorPropsWithoutMedia = useMemo(
		(): AkEditorProps => ({
			mentionProvider: mentionProvider !== undefined ? Promise.resolve(mentionProvider) : undefined,
			emojiProvider: emojiProviderPromise !== null ? emojiProviderPromise : undefined,
			searchProvider: Promise.resolve(searchProvider),
			activityProvider: Promise.resolve(activityProvider),
			autoformattingProvider:
				autoformattingProvider !== undefined ? Promise.resolve(autoformattingProvider) : undefined,
			contentTransformerProvider,
			contextIdentifierProvider:
				editorContext !== undefined ? Promise.resolve(editorContext) : undefined,
			linking,
		}),
		[
			activityProvider,
			autoformattingProvider,
			contentTransformerProvider,
			editorContext,
			emojiProviderPromise,
			mentionProvider,
			searchProvider,
			linking,
		],
	);

	const akEditorProps = useMemo(
		(): AkEditorProps => ({
			...akEditorPropsWithoutMedia,
			allowBorderMark: true,
			media,
		}),
		[akEditorPropsWithoutMedia, media],
	);

	const childProps = useMemo(
		(): PolarisAdfConsumerProps => ({
			loading: false,
			adfConfiguration: {
				akEditorProps,
				akEditorPropsWithoutMedia,
				akRendererProps: {
					dataProviders,
				},
				dataProviders,
				emojiProvider: emojiProviderPromise !== null ? emojiProviderPromise : undefined,
				mentionProvider,
				mediaProvider,
			},
		}),
		[
			akEditorProps,
			akEditorPropsWithoutMedia,
			dataProviders,
			emojiProviderPromise,
			mentionProvider,
			mediaProvider,
		],
	);

	return <>{children(childProps)}</>;
};

const PolarisAdfProviderInjectionContainer: ComponentType<PolarisInjectionContainerProps> = flow([
	withAutoformattingProvider,
	withMediaProvider,
])(PolarisAdfProviderInternal);

export const IssuelessAdfProvider = ({ children, ...rest }: IssuelessAdfProviderProps) => (
	<PolarisAdfProviderInternal {...rest}>
		{(childProps) => children(childProps)}
	</PolarisAdfProviderInternal>
);

export const ContainerlessAdfProvider = ({ children, ...rest }: ContainerlessAdfProviderProps) => (
	<PolarisAdfProviderInternal {...rest}>
		{(childProps) => children(childProps)}
	</PolarisAdfProviderInternal>
);

export const IdeaCreateAdfProvider = ({
	projectId,
	children,
	getMediaContext,
	...rest
}: IdeaCreateAdfProviderProps) => (
	<IdeaCreateMediaContextSupplier projectId={projectId} getMediaContext={getMediaContext}>
		{(remoteMediaContext, mediaContextProjectId, loading) => {
			const mediaContext = remoteMediaContext || {
				uploadContext: null,
				userAuth: null,
				viewContext: null,
			};

			const isLoading =
				remoteMediaContext === undefined ||
				(loading && remoteMediaContext === undefined) ||
				mediaContextProjectId !== projectId;

			return (
				<PolarisAdfProviderInjectionContainer
					mediaContext={mediaContext}
					projectId={projectId}
					{...rest}
				>
					{(childProps) => <>{children(isLoading ? LOADING_PROPS : childProps)}</>}
				</PolarisAdfProviderInjectionContainer>
			);
		}}
	</IdeaCreateMediaContextSupplier>
);

const LOADING_PROPS = {
	loading: true,
	adfConfiguration: undefined,
} as const;

export const PolarisAdfProvider = ({
	issueKey,
	children,
	getMediaContext,
	isSharedView,
	...rest
}: AdfProviderProps) => (
	<>
		<MediaContextSupplier
			issueKey={issueKey}
			getMediaContext={getMediaContext}
			isSharedView={isSharedView}
		>
			{(remoteMediaContext, mediaContextIssueKey, loading) => {
				const mediaContext: MediaContext = remoteMediaContext?.mediaContext || {
					uploadContext: null,
					userAuth: null,
					viewContext: null,
				};

				const isLoading =
					remoteMediaContext === undefined ||
					(loading && remoteMediaContext === undefined) ||
					mediaContextIssueKey !== issueKey ||
					remoteMediaContext.mediaContext === undefined;

				return (
					<>
						<PolarisAdfProviderInjectionContainer
							mediaContext={mediaContext}
							issueId={remoteMediaContext?.issueId}
							issueKey={issueKey}
							{...rest}
						>
							{ff('polaris.publish.improve-media-loading')
								? (childProps) => (
										<>{children(isLoading && !isSharedView ? LOADING_PROPS : childProps)}</>
									)
								: (childProps) => <>{children(isLoading ? LOADING_PROPS : childProps)}</>}
						</PolarisAdfProviderInjectionContainer>
					</>
				);
			}}
		</MediaContextSupplier>
	</>
);
