import type { LinkedIssue } from '../../services/jira/linked-issue-archived/types.tsx';
import type { RemoteLinkedIssueArchived } from './types';

export const transformArchived = (
	response: LinkedIssue[] | undefined,
): RemoteLinkedIssueArchived[] => {
	if (!response) {
		return [];
	}
	return response.map((item) => ({
		...item,
		fields: item.fields.map((field) => {
			switch (field.__typename) {
				case 'ProjectField':
					return {
						...field,
						kind: 'project',
					};
				case 'SelectCustomField':
					return {
						...field,
						kind: 'select',
					};
				default:
					return field;
			}
		}),
	}));
};
