import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari';
import type { SnippetPropertiesConfig } from '@atlassian/jira-polaris-domain-insight/src/snippet-properties-config/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { SetSnippetPropertiesConfig } from './types';

export const SET_SNIPPET_PROPERTIES_CONFIG = gqlTagPolaris`
mutation jira_polaris_SetPolarisSnippetPropertiesConfig($input: SetPolarisSnippetPropertiesConfigInput!) {
  setPolarisSnippetPropertiesConfig(input: $input) {
    success
    errors {
      message
    }
  }
}
`;

export const setSnippetPropertiesConfig = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	oauthClientId: string,
	groupId: string,
	config: SnippetPropertiesConfig,
): Promise<void> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

	return client
		.mutate<SetSnippetPropertiesConfig>({
			mutation: SET_SNIPPET_PROPERTIES_CONFIG,
			variables: {
				input: {
					project: projectAri.toString(),
					oauthClientId,
					groupId,
					config,
				},
			},
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-insights.polaris-snippet-propeperties-config:${result.errors
						.map((e) => e.message)
						.join(', ')}`,
				);
			}

			if (
				!result.data ||
				!result.data.setPolarisSnippetPropertiesConfig ||
				!result.data.setPolarisSnippetPropertiesConfig.success
			) {
				throw new Error(
					'polaris-insights.polaris-snippet-propeperties-config: no data or no data node',
				);
			}
		});
};
