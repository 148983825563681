import React, { useCallback, useMemo, useState } from 'react';
import { usePreloadedSummary } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useSelectedIssue,
	useSelectedIssueStringValue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { EditableSummary } from './main';
import { handleError } from './utils';

export const Summary = () => {
	const [isEditing, setEditing] = useState(false);
	const value = useSelectedIssueStringValue('summary');
	const preloadedValue = usePreloadedSummary();
	const [isEditingAllowed] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();
	const isArchived = useIsSelectedIssueArchived();
	const localIssueId = useSelectedIssue();

	const initialValue = useMemo(() => {
		if (value !== undefined) {
			return value;
		}
		if (preloadedValue !== undefined) {
			return preloadedValue;
		}
		return '';
	}, [value, preloadedValue]);

	const handleEdit = useCallback(() => {
		if (isEditingAllowed && !isArchived) {
			setEditing(true);
			experience.ideaView.summaryUpdate.start();
		}
	}, [isArchived, isEditingAllowed]);

	const handleConfirm = useCallback(
		(newValue: string) => {
			if (isEditingAllowed) {
				updateFieldValue(
					{
						fieldKey: 'summary',
						// @ts-expect-error - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
						localIssueIds: [localIssueId],
						newValue,
					},
					() => {
						experience.ideaView.summaryUpdate.success();
					},
					(error?: Error) => handleError(experience.ideaView.summaryUpdate, error),
				);
			}
			setEditing(false);
		},
		[isEditingAllowed, localIssueId, updateFieldValue],
	);

	const handleCancel = useCallback(() => {
		setEditing(false);
		experience.ideaView.summaryUpdate.abort();
	}, []);

	return (
		<EditableSummary
			summary={initialValue}
			isEditingAllowed={isEditingAllowed && !isArchived}
			isEditing={isEditing}
			onEdit={handleEdit}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
		/>
	);
};
