/** @jsx jsx */
import React, { useMemo, useRef, useState, useCallback, type ComponentType } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import Button, { IconButton } from '@atlaskit/button/new';
import { Emoji } from '@atlaskit/emoji';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Flex, Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import type { GroupOptionsType, OptionType, SelectProps } from '../../../common/types/select';
import { useFieldEmoji, useFieldTypeIcon } from '../../../controllers/field/selectors/field-hooks';
import { useOpenRightSidebarOnCreate } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import type { RightSidebarOpeningSource } from '../../../controllers/right-sidebar/types';
import { EmojiWrapper } from '../../field-config/item/emoji-wrapper';
import { CreateFieldButton } from '../create-field';
import { FieldSelect as FieldSelectLegacy, FieldItem as FieldItemLegacy } from './legacy';
import { messages } from './messages';

type FieldTriggerProps = {
	field: Field;
};

export const FieldItem = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	({ field }: FieldTriggerProps) => {
		const icon = useFieldTypeIcon(field.key, undefined);
		const emojiId = useFieldEmoji(field.key);
		const emoji = useEmoji(emojiId);

		return (
			<Flex alignItems="center">
				{emoji && emojiId !== undefined ? (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</EmojiWrapper>
				) : (
					<Flex alignItems="center" xcss={fieldIconStyles}>
						{icon}
					</Flex>
				)}
				<Box xcss={fieldNameStyles}>{field.label}</Box>
			</Flex>
		);
	},
	FieldItemLegacy,
);

type FieldSelectProps = {
	inputId?: string;
	isClearable?: boolean;
	selectedField?: Field;
	fieldOptions: (OptionType | GroupOptionsType)[];
	SearchableComponent: ComponentType<SelectProps>;
	onChange: (fieldKey?: FieldKey) => void;
	canCreateField?: boolean;
	onFieldCreate?: (fieldKey: FieldKey) => void;
	isSearchable?: boolean;
	sidebarOpeningSource?: RightSidebarOpeningSource;
	isDisabled?: boolean;
};

export const FieldSelect = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	({
		inputId,
		isClearable = false,
		selectedField,
		fieldOptions,
		onChange,
		SearchableComponent,
		canCreateField,
		onFieldCreate = noop,
		isSearchable,
		sidebarOpeningSource,
		isDisabled = false,
	}: FieldSelectProps) => {
		const { formatMessage } = useIntl();
		const selectFieldMsg = useMemo(() => formatMessage(messages.notSelectedField), [formatMessage]);

		const buttonRef = useRef<HTMLButtonElement | null>(null);
		const [dialogOpen, setDialogOpen] = useState(false);
		const toggleDialog = useCallback(() => setDialogOpen(!dialogOpen), [dialogOpen]);
		const onClear = useCallback(() => onChange(undefined), [onChange]);
		const openRightSidebarOnCreate = useOpenRightSidebarOnCreate(sidebarOpeningSource);
		const [fieldSearchString, setFieldSearchString] = useState('');

		const handleOpenRightSidebarOnCreate = useCallback(() => {
			openRightSidebarOnCreate(fieldSearchString, onFieldCreate);
		}, [openRightSidebarOnCreate, fieldSearchString, onFieldCreate]);

		return (
			<PolarisInlineDialog
				noPadding
				onClose={(target) => {
					if (buttonRef.current && !buttonRef.current.contains(target)) {
						setDialogOpen(false);
					}
				}}
				isOpen={dialogOpen}
				placement="bottom-start"
				content={
					<>
						<SearchableComponent
							inputId={inputId}
							options={fieldOptions}
							onSelect={(fieldKey) => {
								onChange(fieldKey);
								setDialogOpen(false);
							}}
							onClose={() => setDialogOpen(false)}
							onSearch={setFieldSearchString}
							isSearchable={isSearchable}
						/>
						{canCreateField && (
							<div css={createFieldButtonContainerStyles}>
								<CreateFieldButton onCreate={handleOpenRightSidebarOnCreate} />
							</div>
						)}
					</>
				}
			>
				<Flex justifyContent="space-between" alignItems="center">
					<Box xcss={buttonWrapperStyles}>
						<Button
							id="pendo.field-select.button"
							testId="polaris-common.ui.common.field-select.button"
							ref={buttonRef}
							isSelected={dialogOpen}
							onClick={toggleDialog}
							iconAfter={(iconProps) => <ChevronDownIcon {...iconProps} />}
							shouldFitContainer
							isDisabled={isDisabled}
						>
							{selectedField ? (
								<FieldItem field={selectedField} />
							) : (
								<Inline alignInline="start" grow="fill">
									{selectFieldMsg}
								</Inline>
							)}
						</Button>
					</Box>
					{isClearable && selectedField !== undefined && (
						<Box paddingInlineStart="space.050">
							<IconButton
								appearance="subtle"
								onClick={onClear}
								label={formatMessage(messages.clearSelectionButton)}
								icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
								isDisabled={isDisabled}
							/>
						</Box>
					)}
				</Flex>
			</PolarisInlineDialog>
		);
	},
	FieldSelectLegacy,
);

const fieldIconStyles = xcss({
	marginInlineEnd: 'space.100',
});

const fieldNameStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const buttonWrapperStyles = xcss({
	flexGrow: 1,
	minWidth: '0',
});

const createFieldButtonContainerStyles = css({
	padding: token('space.100'),
	boxShadow: token('elevation.shadow.raised'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& button, && button:hover': {
		textAlign: 'center',
	},
});
