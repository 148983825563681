import React, { type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
	type Instruction,
	type ItemMode,
	attachInstruction,
	extractInstruction,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { scrollJustEnoughIntoView } from '@atlaskit/pragmatic-drag-and-drop/element/scroll-just-enough-into-view';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import {
	type RefHandler,
	type TreeItemBaseProps,
	TreeItemBase,
} from '../../common/ui/item-base/index.tsx';
import { getTreeItemData, isTreeItemData } from '../../common/utils';
import type {
	DragEnabledProp,
	DraggableState,
	DropEnabledProp,
	OnDragEnd,
	OnDragStart,
	DragPreview,
	TreeDestinationPosition,
	TreeSourcePosition,
	TreeItem,
} from '../../types';
import { delay } from '../../utils/delay.tsx';

export type LocalState =
	| { type: Exclude<DraggableState, 'preview'> }
	| { type: 'preview'; container: HTMLElement };

function getItemMode<TItem extends TreeItem>(isLastInGroup: boolean, item: TItem): ItemMode {
	if (item.isExpanded && item.hasChildren) {
		return 'expanded';
	}
	if (isLastInGroup) {
		return 'last-in-group';
	}
	return 'standard';
}

export type TreeItemDraggableProps<TItem extends TreeItem> = TreeItemBaseProps<TItem> & {
	onDragStart: OnDragStart;
	onDragEnd: OnDragEnd;
	index: number;
	isLastInGroup: boolean;
	path: TreeSourcePosition[];
	itemType: string;
	dragPreview?: DragPreview<TItem>;
	isDragEnabled?: DragEnabledProp<TItem>;
	isDropEnabled?: DropEnabledProp<TItem>;
};

// Shared state between all instances of TreeItemDraggable
const idle: LocalState = { type: 'idle' };

export const TreeItemDraggable = <TItem extends TreeItem>({
	onDragStart,
	onDragEnd,
	dragPreview,
	index,
	isLastInGroup,
	path,
	itemType,
	isDragEnabled,
	isDropEnabled,
	renderItem,
	...baseProps
}: PropsWithChildren<TreeItemDraggableProps<TItem>>) => {
	const { item, indentPerLevel, currentLevel, onExpand, onCollapse } = baseProps;
	const { parentId } = path[currentLevel];

	const [draggableState, setDraggableState] = useState<LocalState>(idle);
	const [dropIndicatorInstruction, setDropIndicatorInstruction] = useState<Instruction | null>(
		null,
	);

	const isDragDisabled =
		!isDragEnabled || (typeof isDragEnabled === 'function' && !isDragEnabled(item));

	const isDropDisabled =
		!isDropEnabled || (typeof isDropEnabled === 'function' && !isDropEnabled(item));

	const ref = useRef<RefHandler>(null);
	const cancelDelayedFn = useRef<null | (() => void)>(null);

	const makeDraggable = useCallback(
		(el: HTMLElement) =>
			draggable({
				element: el,
				onGenerateDragPreview: ({ source, nativeSetDragImage }) => {
					scrollJustEnoughIntoView({ element: source.element });
					setCustomNativeDragPreview({
						getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
						render({ container }) {
							// this will cause a sync re-render to add a portal
							setDraggableState({ type: 'preview', container });
						},
						nativeSetDragImage,
					});
				},
				onDragStart: () => {
					// Collapse section if expanded to prevent dropping into itself
					if (item.isExpanded) {
						onCollapse?.(item.id);
					}
					setDraggableState({ type: 'dragging' });
					onDragStart?.();
				},
				getInitialData: () => ({
					id: item.id,
					isExpandedBeforeDrag: item.isExpanded,
					type: itemType,
				}),
				onDrop: ({ location, source }) => {
					setDraggableState(idle);

					if (location?.current?.dropTargets[0]) {
						const { data: targetData } = location.current.dropTargets[0];
						const instruction: Instruction | null = extractInstruction(targetData);
						const target = isTreeItemData(targetData) ? targetData : null;

						if (
							!target ||
							target.type !== itemType ||
							!instruction ||
							// only allow an item to drop on itself if it's being reparented
							(target.id === item.id && instruction.type !== 'reparent')
						) {
							// re-expand the item if it was expanded before the drag and not dropped on a valid target
							if (source.data.isExpandedBeforeDrag) {
								onExpand?.(item.id);
							}
							return;
						}

						// Calculate the desired instruction based if the current instruction is blocked
						// Allow consumers to handle the move even if it's blocked in onDragEnd
						const desiredInstruction =
							instruction.type === 'instruction-blocked' ? instruction.desired : instruction;

						let destinationPosition: TreeDestinationPosition | null = null;
						switch (desiredInstruction.type) {
							case 'reorder-above':
								destinationPosition = {
									parentId: target.parentId,
									index: target.index,
								};
								break;
							case 'reorder-below':
								destinationPosition = {
									parentId: target.parentId,
									index: target.index + 1,
								};
								break;
							case 'make-child':
								destinationPosition = {
									parentId: target.id,
									index: undefined,
								};
								break;
							case 'reparent':
								destinationPosition = {
									parentId: target.path[desiredInstruction.desiredLevel].parentId,
									index: target.path[desiredInstruction.desiredLevel].index + 1,
								};
								break;
							default:
								break;
						}

						// Move item down within same parent. Can happen during reordering or reparenting.
						if (
							parentId === destinationPosition?.parentId &&
							typeof destinationPosition?.index === 'number' &&
							index < destinationPosition?.index
						) {
							destinationPosition.index--;
						}

						if (destinationPosition) {
							const sourcePosition = { parentId, index };
							onDragEnd(sourcePosition, destinationPosition, !!source.data.isExpandedBeforeDrag);
						}
					} else if (source.data.isExpandedBeforeDrag) {
						// re-expand the item if it was expanded before the drag and not dropped on a valid target
						onExpand?.(item.id);
					}
				},
			}),
		[onDragStart, onDragEnd, item, index, parentId, onExpand, onCollapse, itemType],
	);

	const makeDropTarget = useCallback(
		(el: HTMLElement) =>
			dropTargetForElements({
				element: el,
				canDrop: ({ source }) => !isDropDisabled && source.data.type === itemType,
				getData: ({ input, element }) => {
					const data = getTreeItemData({
						id: item.id,
						type: itemType,
						parentId,
						index,
						hasChildren: !!item.hasChildren,
						path,
					});
					return attachInstruction(data, {
						input,
						element,
						currentLevel,
						indentPerLevel,
						mode: getItemMode<TItem>(isLastInGroup, item),
						block: item.blockedInstructions,
					});
				},
				onDrag: ({ self, source, location }) => {
					const instruction = extractInstruction(self.data);
					if (
						// only show the drag indicator when dragging over the item being dragged in the case of reparenting
						(source.data.id !== item.id || instruction?.type === 'reparent') &&
						location?.current?.dropTargets[0]?.data?.id === item.id
					) {
						if (item.hasChildren) {
							if (
								instruction?.type === 'make-child' &&
								!item.isExpanded &&
								!cancelDelayedFn.current
							) {
								cancelDelayedFn.current = delay({
									time: 500,
									fn: () => {
										onExpand?.(item.id);
										// set `cancelDelayedFn` to `null` so we know there is no delayed fn running
										cancelDelayedFn.current = null;
									},
								});
							} else if (instruction?.type !== 'make-child') {
								cancelDelayedFn.current?.();
								cancelDelayedFn.current = null;
							}
						}
						setDropIndicatorInstruction(instruction);
					} else {
						setDropIndicatorInstruction(null);
					}
				},
				onDragLeave: () => {
					cancelDelayedFn.current?.();
					cancelDelayedFn.current = null;
					setDropIndicatorInstruction(null);
				},
				onDrop: () => {
					cancelDelayedFn.current?.();
					cancelDelayedFn.current = null;
					setDropIndicatorInstruction(null);
				},
			}),
		[
			isDropDisabled,
			itemType,
			item,
			parentId,
			index,
			path,
			currentLevel,
			indentPerLevel,
			isLastInGroup,
			onExpand,
		],
	);

	useEffect(() => {
		const draggableEl = ref.current?.draggableRef.current;
		const containerEl = ref.current?.containerRef.current;

		if (!draggableEl || !containerEl) {
			return;
		}

		const dropTarget = makeDropTarget(draggableEl);
		if (isDragDisabled) {
			return dropTarget;
		}

		return combine(makeDraggable(draggableEl), dropTarget);
	}, [isDragDisabled, makeDraggable, makeDropTarget]);

	useEffect(() => () => cancelDelayedFn.current?.(), []);

	const dropIndicator = dropIndicatorInstruction ? (
		<DropIndicator instruction={dropIndicatorInstruction} />
	) : null;

	return (
		<>
			<TreeItemBase<TItem>
				ref={ref}
				dropIndicator={dropIndicator}
				draggableState={draggableState.type}
				renderItem={renderItem}
				{...baseProps}
			/>

			{draggableState.type === 'preview'
				? ReactDOM.createPortal(
						<>
							{dragPreview?.(item) ||
								renderItem({
									item,
									draggableState: 'preview',
								})}
						</>,
						draggableState.container,
					)
				: null}
		</>
	);
};
