/** @jsx jsx */
import React, { type ReactNode, useState, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import type { EmojiDescription } from '@atlaskit/emoji';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { token } from '@atlaskit/tokens';
import type { IntervalFieldFilterOperator } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { FilterButtonLabel } from './button-label';
import { IntervalFilterContentContainer } from './content';

type IntervalFilterComponentProps = {
	isLastFilter: boolean;
	label: string;
	values: IntervalFieldFilterOperator[];
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
	defaultOpen?: boolean;
	onChange: (value: IntervalFieldFilterOperator[]) => void;
	isDisabled?: boolean;
	isUsingCustomColor?: boolean;
};

export const IntervalFilterComponent = ({
	label,
	values,
	onChange,
	fieldTypeIcon,
	emoji,
	defaultOpen,
	isLastFilter,
	isDisabled = false,
	isUsingCustomColor = false,
}: IntervalFilterComponentProps) => {
	const [dialogOpen, setDialogOpen] = useState(defaultOpen || (isLastFilter && !values.length));

	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={() => {
				setDialogOpen(false);
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={<IntervalFilterContentContainer values={values} onChange={onChange} />}
		>
			<div css={[isUsingCustomColor && buttonWrapperCustomColorStyles]}>
				<Button
					data-component-selector="polaris-ideas.ui.view-controls.config-filters.filter-component.interval.button-4Zxn"
					isSelected={dialogOpen}
					onClick={toggleDialog}
					iconAfter={(iconProps) => <ChevronDownIcon {...iconProps} />}
					isDisabled={isDisabled}
				>
					<FilterButtonLabel
						values={values}
						label={label}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
					/>
				</Button>
			</div>
		</PolarisInlineDialog>
	);
};

const buttonWrapperCustomColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="polaris-ideas.ui.view-controls.config-filters.filter-component.interval.button-4Zxn"]':
		{
			background: token('color.background.warning'),
			color: token('color.text.accent.yellow.bolder'),
		},
});
