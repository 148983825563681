import { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useIsExporting } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { CONTAINER_CLASS_NAME } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main';
import { COLUMN_CLASS_NAME } from '../constants';

const RENDERED_CARD_COUNT_CHECK_INTERVAL_MS = 100;
const CARD_MUTATION_DEBOUNCE_TIME_MS = 100;

export const VIEW_CONTENT_CONTAINER_ID = 'polaris-ideas.ui.view-content-container';

const getRenderedCardCount = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const viewContainer = document.getElementById(VIEW_CONTENT_CONTAINER_ID);
	return viewContainer?.getElementsByClassName(CONTAINER_CLASS_NAME).length || 0;
};

const resolveWhenRenderedCardCountDidNotChange = (resolve: () => void, count = 0) => {
	const newCount = getRenderedCardCount();

	if (newCount === count) {
		resolve();
		return;
	}

	setTimeout(
		() => resolveWhenRenderedCardCountDidNotChange(resolve, newCount),
		RENDERED_CARD_COUNT_CHECK_INTERVAL_MS,
	);
};

const waitForAllCardsToBeRendered = async () =>
	new Promise<void>((resolve) => {
		setTimeout(
			() => resolveWhenRenderedCardCountDidNotChange(resolve),
			RENDERED_CARD_COUNT_CHECK_INTERVAL_MS,
		);
	});

const getColumnLastCards = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const viewContainer = document.getElementById(VIEW_CONTENT_CONTAINER_ID);
	const columns = viewContainer
		? Array.from(viewContainer.getElementsByClassName(COLUMN_CLASS_NAME))
		: [];
	return columns
		.map((column: Element) => {
			const columnCards = column.getElementsByClassName(CONTAINER_CLASS_NAME);
			return columnCards[columnCards.length - 1];
		})
		.filter((card) => card !== undefined);
};

export const useBoardExport = () => {
	const isExporting = useIsExporting();
	const { setIsReadyToExport } = useViewActions();

	useEffect(() => {
		if (!isExporting) {
			return undefined;
		}

		let observer: MutationObserver;
		waitForAllCardsToBeRendered().then(() => {
			const debouncedReadyToExport = debounce(() => {
				setIsReadyToExport(true);
				observer.disconnect();
			}, CARD_MUTATION_DEBOUNCE_TIME_MS);

			const columnLastCards = getColumnLastCards();

			observer = new MutationObserver(() => {
				// reset the debounce timer everytime a card mutation happens
				debouncedReadyToExport();
			});

			columnLastCards.forEach((card: Element) =>
				observer.observe(card, {
					childList: true,
					subtree: true,
				}),
			);

			debouncedReadyToExport();
		});

		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, [isExporting, setIsReadyToExport]);
};
