import omit from 'lodash/omit';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { POLARIS_UNSUPPORTED_OAUTH_CLIENT_ID } from '@atlassian/jira-polaris-common/src/common/types/snippet/constants';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import { isEmpty } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import type { RemoteCreateSnippet } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Data as UserData } from '@atlassian/jira-user-services/src/types';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types';
import { getInsightAnalyticsData } from '../utils/analytics';
import { optimisticInsightId } from '../utils/optimistic-updates';
import { signalInsightsUpdates } from '../utils/signal';

type Description = ADF | null | undefined;

type CreateInsightParams = {
	description: Description;
	snippets: RemoteCreateSnippet[];
	user: UserData;
	issueKey?: IssueKey;
};

export const createInsight =
	({ description = null, snippets, user, issueKey }: CreateInsightParams) =>
	async ({ getState, setState }: StoreActionApi<State>, containerProps: Props) => {
		const { insightsRemote, onCreateFailed, issueId, createAnalyticsEvent, projectId } =
			containerProps;

		if (!insightsRemote.createInsight) {
			return;
		}

		const state = getState();
		const fakeInsightId = optimisticInsightId();

		if (isEmpty(description) && snippets.length === 0) {
			// early exit, do not create empty insights
			return;
		}

		const newInsight: Insight = {
			id: fakeInsightId,
			account: {
				accountId: user.accountId,
				name: user.displayName,
				picture: user.avatarUrls['48x48'],
			},
			container: null,
			snippets: snippets.map((snippet, i) => ({
				id: `${fakeInsightId}-snippet-${i}`,
				data: snippet.data,
				url: snippet.url ?? null,
				updated: new Date().toISOString(),
				properties: snippet.properties,
				oauthClientId: POLARIS_UNSUPPORTED_OAUTH_CLIENT_ID,
				appInfo: {
					id: POLARIS_UNSUPPORTED_OAUTH_CLIENT_ID,
					oauthClientId: POLARIS_UNSUPPORTED_OAUTH_CLIENT_ID,
					avatarUrl: '',
					name: '',
				},
				refresh: {
					last: null,
					next: new Date().toISOString(),
					error: null,
					errorType: null,
					queued: null,
				},
			})),
			description,
			created: new Date().toString(),
			updated: new Date().toString(),
			play: null,
		};

		setState({
			insights: {
				...state.insights,
				[newInsight.id]: newInsight,
			},
		});

		try {
			const createdInsight = await insightsRemote.createInsight({
				issueId: issueId ? Number(issueId) : undefined,
				snippets,
				description,
				projectId,
			});

			if (issueKey !== getState().meta?.issueKey) {
				return;
			}
			const newState = {
				...state,
				insights: {
					...omit(state.insights, fakeInsightId),
					[createdInsight.id]: createdInsight,
				},
			};

			setState(newState);

			signalInsightsUpdates(newState, containerProps);

			const { attributes, containers } = getInsightAnalyticsData({
				...createdInsight,
				snippets: newInsight.snippets,
			});
			fireTrackAnalytics(createAnalyticsEvent({ containers }), 'insight created', attributes);
		} catch (err) {
			setState({
				insights: {
					...omit(state.insights, fakeInsightId),
				},
			});

			const error = err instanceof Error ? err : new Error('Unknown error');
			onCreateFailed(error);
			throw error;
		}
	};
