import React, { useCallback, useEffect, useMemo } from 'react';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useHighlightedFields } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import {
	useIsLoading,
	useIsLoadingFailed,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks';
import { useAllFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { List } from '@atlassian/jira-polaris-lib-list/src/main.tsx';
import {
	useAreFiltersActive,
	useFilteredFieldsKeys,
} from '../../../../../../controllers/fields-table/selectors/fields-hooks';
import type { Column } from '../../../../../../controllers/fields-table/types';
import { Cell } from './cell';
import { EmptyScreen } from './empty-states/empty-screen/index.tsx';
import { EmptySearchScreen } from './empty-states/empty-search/index.tsx';
import { ErrorState } from './error-state';
import { Filters } from './filters';
import { GoToRowLabel } from './go-to-row';
import { Header } from './header';
import messages from './messages';
import { NewFieldsNotifications } from './notifications';
import { Row } from './row';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Cell } from './cell';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Header } from './header';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Row } from './row';

const columns: Column[] = ['label', 'fieldType', 'description', 'fieldSettings'];

// The sum of all columns width is exactly the width of the table.
const columnSize: Record<Column, number> = {
	label: 272,
	fieldType: 188,
	description: 560,
	fieldSettings: 60,
};

const useFields = () => {
	const fields = useAllFieldsForViewControls();

	return useMemo(() => Object.values(fields), [fields]);
};

const useFieldScopeCounters = (fields: Field[]) =>
	useMemo(() => {
		let projectScopeFieldsCount = 0;
		let globalScopeFieldsCount = 0;

		for (const field of fields) {
			if (field.global) {
				globalScopeFieldsCount++;
			} else {
				projectScopeFieldsCount++;
			}
		}

		return {
			projectScopeFieldsCount,
			globalScopeFieldsCount,
		};
	}, [fields]);

export const FieldsList = () => {
	const { formatMessage } = useIntl();
	const fields = useFields();
	const fieldsKeys = useFilteredFieldsKeys(fields);
	const areFieldsLoading = useIsLoading();
	const isFieldsLoadingFailed = useIsLoadingFailed();
	const areFiltersActive = useAreFiltersActive();
	const highlightedFieldKeys = useHighlightedFields();
	const { resetHighlightedFields } = useFieldActions();

	const onResetHighlightedFields = useCallback(() => {
		resetHighlightedFields();
	}, [resetHighlightedFields]);

	useEffect(
		() => () => {
			onResetHighlightedFields();
		},
		[onResetHighlightedFields],
	);

	const fieldsScopeCount = useFieldScopeCounters(fields);

	if (areFieldsLoading) {
		return (
			<Flex alignItems="center" justifyContent="center" xcss={loaderContainerStyles}>
				<Spinner />
			</Flex>
		);
	}

	if (isFieldsLoadingFailed) {
		return <ErrorState header={formatMessage(messages.errorScreenHeader)} />;
	}

	if (fieldsKeys.length === 0 && !areFiltersActive) {
		return <EmptyScreen />;
	}

	return (
		<>
			<NewFieldsNotifications newFields={highlightedFieldKeys} />
			<Filters filterCounters={fieldsScopeCount} />
			{fieldsKeys.length === 0 && areFiltersActive ? (
				<EmptySearchScreen />
			) : (
				<Box xcss={listWrapperStyles}>
					<List
						displayMode="framed"
						rows={fieldsKeys}
						columns={columns}
						columnSizes={columnSize}
						updatedRows={highlightedFieldKeys}
						components={{ Cell, Header, Row, GoToRowLabel }}
						onUpdatedRowsSeen={onResetHighlightedFields}
						alwaysHighlightUpdatedRows
					/>
				</Box>
			)}
		</>
	);
};

const listWrapperStyles = xcss({
	height: '100%',
});

const loaderContainerStyles = xcss({
	height: '100%',
});
