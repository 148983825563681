import React, { memo, useState, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsGlobalCustomField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useFieldsSidebarConfig } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks';
import {
	usePinnedViewFields,
	usePinnedViewId,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { CreateFieldButton } from '@atlassian/jira-polaris-common/src/ui/common/create-field';
import { ManageFieldButtons } from '@atlassian/jira-polaris-common/src/ui/common/manage-field-buttons';
import { CreateNewField } from '@atlassian/jira-polaris-common/src/ui/config/fields/create-field';
import { DeleteFieldModal } from '@atlassian/jira-polaris-common/src/ui/config/fields/delete-field';
import { FieldItem } from '@atlassian/jira-polaris-common/src/ui/config/fields/field-item/main.tsx';
import { TOGGLE_PERMISSIONS } from '@atlassian/jira-polaris-common/src/ui/field-config/constants.tsx';
import { FieldsList as ManageableFieldsList } from '@atlassian/jira-polaris-common/src/ui/field-config/main.tsx';
import {
	useCanCreateFields,
	useCanPinIssueViewFields,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ClickableBanner } from '@atlassian/jira-polaris-lib-clickable-banner/src/ui/index.tsx';
import { N0 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { ignoreEscapePress } from '@atlassian/jira-polaris-lib-escape-keypress-utils';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { FieldsList } from './field/list/main.tsx';
import messages from './messages';

type Props = {
	isCompact?: boolean;
	portalElement?: HTMLElement;
};

export const Fields = memo<Props>(({ portalElement, isCompact = false }: Props) => {
	const { mode, fieldKey, optionId } = useFieldsSidebarConfig();
	const { setFieldSidebarMode, setFieldSidebarFieldKey, setFieldSidebarOptionId } =
		useIdeaActions();

	const { formatMessage } = useIntl();
	const [fieldKeyForDelete, setFieldKeyForDelete] = useState<undefined>(undefined);
	const { setViewColumns } = useViewActions();
	const pinnedViewId = usePinnedViewId();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const canPinIssueViewFields = useCanPinIssueViewFields();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
	const canCreateFields = useCanCreateFields();

	const selectedFieldKeys: FieldKey[] = usePinnedViewFields();
	const allFields = useFieldsForViewControls();

	const selectedFields = useMemo(
		() => selectedFieldKeys.map((key) => allFields[key]),
		[allFields, selectedFieldKeys],
	);

	const availableFields = useMemo(
		() =>
			Object.keys(allFields)
				.filter((key) => !selectedFieldKeys.includes(key))
				.map((key) => allFields[key])
				.sort((a, b) => a.label.localeCompare(b.label)),
		[selectedFieldKeys, allFields],
	);

	const onSaveColumns = useCallback(
		(columnFields: Field[]) => {
			setViewColumns(columnFields, ({ id }) => id === pinnedViewId);
		},
		[pinnedViewId, setViewColumns],
	);

	const onFieldsSort = useCallback(
		(fk: FieldKey, movedToKey: FieldKey) => {
			const newFieldsOrder = [...selectedFields];
			const oldIndex = selectedFields.findIndex((f) => f.key === fk);
			const newIndex = selectedFields.findIndex((f) => f.key === movedToKey);

			newFieldsOrder.splice(oldIndex, 1);
			newFieldsOrder.splice(newIndex, 0, allFields[fk]);

			setViewColumns(newFieldsOrder, ({ id }) => id === pinnedViewId);
		},
		[allFields, pinnedViewId, selectedFields, setViewColumns],
	);

	const onFieldToggle = useCallback(
		(fk: FieldKey, isChecked: boolean) => {
			const newFieldsOrder = [...selectedFields];

			// Add the field to the view if it's not already there
			if (isChecked) {
				newFieldsOrder.push(allFields[fk]);
				return setViewColumns(newFieldsOrder, ({ id }) => id === pinnedViewId);
			}

			const oldIndex = selectedFields.findIndex((f) => f.key === fk);
			newFieldsOrder.splice(oldIndex, 1);
			return setViewColumns(newFieldsOrder, ({ id }) => id === pinnedViewId);
		},
		[allFields, pinnedViewId, selectedFields, setViewColumns],
	);

	const addField = useCallback(
		(field: Field) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'field added', 'config-fields');

			const index = selectedFields.findIndex((f) => f.key === field.key);
			if (index >= 0) {
				const updatedFields = [...selectedFields];
				updatedFields.splice(index, 0, field);
				onSaveColumns(updatedFields);
			} else {
				const updatedFields = [...selectedFields, field];
				onSaveColumns(updatedFields);
			}
		},
		[createAnalyticsEvent, onSaveColumns, selectedFields],
	);

	const onManageField = useCallback(
		(key: string, optId?: string) => {
			setFieldSidebarFieldKey(key);
			setFieldSidebarMode('FIELD');
			if (optId) {
				setFieldSidebarOptionId(optId);
			}
		},
		[setFieldSidebarFieldKey, setFieldSidebarMode, setFieldSidebarOptionId],
	);

	const onManagePinned = useCallback(() => {
		setFieldSidebarMode('LIST');
	}, [setFieldSidebarMode]);

	const onCreate = useCallback(() => {
		setFieldSidebarMode('CREATE');
	}, [setFieldSidebarMode]);

	const onShowValues = useCallback(() => {
		setFieldSidebarMode('VALUES');
	}, [setFieldSidebarMode]);

	useCloseOnEscapePress({
		onClose: (event) => {
			// Two cases we're handling here:
			// - Field settings/list being opened on top of the idea view sidebar
			// - Idea view being opened in fullscreen, where we don't want ESC to have an effect
			const modesOpenedOnTop = ['FIELD', 'LIST'];
			if (ignoreEscapePress(event) || !modesOpenedOnTop.includes(mode) || !isCompact) {
				return;
			}
			onShowValues();
		},
	});

	const onOpenOption = useCallback(
		(optId: string | null) => {
			setFieldSidebarOptionId(optId ?? undefined);
		},
		[setFieldSidebarOptionId],
	);

	const handlePinField = useCallback(
		(pinnedFieldsKeys: FieldKey[]) => {
			const pinnedFields = pinnedFieldsKeys.map((key) => allFields[key]);
			onSaveColumns(pinnedFields);
		},
		[onSaveColumns, allFields],
	);

	if (mode === 'VALUES') {
		return (
			<FieldsValuesContainer isCompact={isCompact} isIdeaArchived={isIdeaArchived}>
				<FieldsList
					onManageField={onManageField}
					onManagePinned={onManagePinned}
					portalElement={portalElement}
					onPinField={handlePinField}
				/>
			</FieldsValuesContainer>
		);
	}

	const backButtonText = formatMessage(
		isCompact ? messages.backToIdeaDetails : messages.backToFieldValues,
	);

	if (mode === 'LIST') {
		return (
			<FieldsContainer isCompact={isCompact}>
				<BackButton
					appearance="subtle-link"
					onClick={onShowValues}
					iconBefore={<ArrowLeftCircleIcon label="back" />}
				>
					{backButtonText}
				</BackButton>
				<FieldsListContainer>
					<FieldsConfigContainer>
						<ManageableFieldsList
							selectedFields={selectedFields}
							availableFields={availableFields}
							togglePermissionType={TOGGLE_PERMISSIONS.PIN_FIELDS}
							onFieldClick={onManageField}
							onFieldToggle={canPinIssueViewFields ? onFieldToggle : noop}
							onFieldsSort={onFieldsSort}
						/>
					</FieldsConfigContainer>
					{canCreateFields && (
						<CreateFieldButtonContainer id="polaris-sidebar.fields.create-new">
							<CreateFieldButton shouldFitContainer appearance="primary" onCreate={onCreate} />
						</CreateFieldButtonContainer>
					)}
				</FieldsListContainer>
			</FieldsContainer>
		);
	}

	if (mode === 'FIELD') {
		return (
			<FieldsContainer isCompact={isCompact}>
				{fieldKeyForDelete !== undefined && (
					<DeleteFieldModal
						fieldKey={fieldKeyForDelete}
						onClose={() => {
							setFieldKeyForDelete(undefined);
						}}
					/>
				)}
				<BackButton
					appearance="subtle-link"
					onClick={onShowValues}
					iconBefore={<ArrowLeftCircleIcon label="back" />}
				>
					{backButtonText}
				</BackButton>
				{isGlobalCustomField && (
					<Box xcss={bannerContainerStyles}>
						<ClickableBanner
							icon={<GlobalFieldMarker color={token('color.icon')} hideTooltip noMargins />}
							message={
								<Text size="small">
									{formatMessage(messages.globalFieldWarning, {
										b: (text: React.ReactNode) => (
											<Text size="small" weight="bold">
												{text}
											</Text>
										),
									})}
								</Text>
							}
							type="information"
							spacing="medium"
						/>
					</Box>
				)}
				<FieldItemContainer>
					<FieldItem
						fieldKey={fieldKey || ''}
						onOpenRightSidebarOption={onOpenOption}
						openSidebarOption={optionId !== undefined ? optionId : null}
					/>
				</FieldItemContainer>
				{fieldKey !== undefined && (
					<ManageFieldButtons fieldKey={fieldKey} onDeleteConfirmed={onShowValues} />
				)}
			</FieldsContainer>
		);
	}

	if (mode === 'CREATE') {
		return (
			<FieldsContainer isCompact={isCompact}>
				<CreateNewField
					onFieldCreate={canPinIssueViewFields ? addField : noop}
					onOpenFieldConfig={(key) => {
						setFieldSidebarFieldKey(key);
						setFieldSidebarMode('FIELD');
					}}
					onCancel={() => setFieldSidebarMode('LIST')}
				/>
			</FieldsContainer>
		);
	}

	return null;
});

const bannerContainerStyles = xcss({
	marginBlockStart: 'space.025',
	marginBlockEnd: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateFieldButtonContainer = styled.div({
	borderTop: '1px solid #ebecf0',
	padding: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsListContainer = styled.div({
	height: 'calc(100% - 32px)',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsConfigContainer = styled.div({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div<{ isCompact: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	// Linked issues in idea view have z-index 103, so we need to be above that in order to have this container above
	zIndex: 104,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	right: ({ isCompact }) => isCompact && 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	bottom: ({ isCompact }) => isCompact && 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ isCompact }) => isCompact && 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ isCompact }) => isCompact && 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: ({ isCompact }) => isCompact && 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ isCompact }) => (isCompact ? token('elevation.surface', N0) : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ isCompact }) => isCompact && token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isCompact }) => (isCompact ? 'auto' : '100%'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsValuesContainer = styled.div<{ isCompact: boolean; isIdeaArchived: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact, isIdeaArchived }) => {
		const compact = `${token('space.400', '32px')} 0`;
		const compactArchived = `30px 0 ${token('space.400', '32px')} 0`;
		// eslint-disable-next-line no-nested-ternary
		return !isCompact ? '0' : isIdeaArchived ? compactArchived : compact;
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldItemContainer = styled.div({
	flex: '1 1 auto',
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackButton = styled(Button)({
	alignSelf: 'start',
	marginBottom: token('space.100', '8px'),
	marginLeft: token('space.050', '4px'),
});
