import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_UpdateIdeaCommentsLastViewedTimestamp as UpdateIdea,
	jira_polaris_UpdateIdeaCommentsLastViewedTimestampVariables as UpdateIdeaVariables,
} from './__generated_apollo__/jira_polaris_UpdateIdeaCommentsLastViewedTimestamp';
import type { UpdateIdeaRequest, UpdateSharingIdeaRequest } from './types';

export const UPDATE_IDEA_COMMENTS_TIMESTAMP = gqlTagPolaris`
mutation jira_polaris_UpdateIdeaCommentsLastViewedTimestamp(
    $projectAri: ID!
    $issueAri: ID!
    $lastCommentsViewedTimestamp: String
  ) {
    updatePolarisIdea(
      project: $projectAri
      idea: $issueAri
      update: {
        lastCommentsViewedTimestamp: $lastCommentsViewedTimestamp
      }
    ) {
      success
      errors {
        message
      }
      node {
        id
      }
    }
  }
`;

export const updateIssueCommentsLastViewed = async (
	apolloClient: PolarisApolloClient,
	request: UpdateIdeaRequest,
): Promise<boolean> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId: request.cloudId,
		resourceType: 'project',
		resourceId: request.projectId,
	});
	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId: request.cloudId,
		resourceType: 'issue',
		resourceId: request.ideaId,
	});

	const result = await apolloClient.mutate<UpdateIdea, UpdateIdeaVariables>({
		mutation: UPDATE_IDEA_COMMENTS_TIMESTAMP,
		variables: {
			projectAri,
			issueAri,
			lastCommentsViewedTimestamp: request.lastCommentsViewedTimestamp,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.update-idea-comments-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
	return true;
};

export const updateSharingIssueCommentsLastViewed = async ({
	cloudId,
	projectId,
	ideaId,
	lastCommentsViewedTimestamp,
}: UpdateSharingIdeaRequest): Promise<boolean> => {
	if (projectId === undefined) {
		return false;
	}

	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});
	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: ideaId,
	});
	const operationName = 'jira_polaris_UpdateIdeaCommentsLastViewedTimestamp';

	const response = await fetch('/gateway/api/graphql', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-ExperimentalApi': 'polaris-v0',
		},
		body: JSON.stringify({
			operationName,
			query: UPDATE_IDEA_COMMENTS_TIMESTAMP.loc?.source.body,
			variables: {
				projectAri,
				issueAri,
				lastCommentsViewedTimestamp,
			},
		}),
	});
	const result = await response.json();

	if (!response.ok && result.errors) {
		throw new Error(
			`project-config.update-idea-comments-error:${result.errors
				.map(({ message }: { message: string }) => message)
				.join(', ')}`,
		);
	}
	return true;
};
