import memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import { ff } from '@atlassian/jira-feature-flagging';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import { createHook } from '@atlassian/react-sweet-state';
import { ListStore } from '../index';
import type { State } from '../types';

const isCreateHigherLevelHookMultiParameterSelectorCreatorCacheEnabled = () =>
	// NOTE - this is to enable this FF in storybooks
	// it's not patchable with storybook decorators because it executes on script load

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	process.env.NODE_ENV === 'development' && window?.STORYBOOK_ENV
		? true
		: ff('polaris.create-higher-level-hook-multi-parameter-selector-creator-cache');
/**
 * reselect selector
 */
export type Selector<TValue> = (arg1: State) => TValue;

/**
 * reselect selector creator
 */
export type SelectorCreator<TValue, SelectorCreatorArgs extends ReadonlyArray<unknown>> = (
	...rest: SelectorCreatorArgs
) => Selector<TValue>;

/**
 * Helper to create a proper react-sweet-state hook from a reselect selector function
 */
export const createSelectorHook = <TValue,>(selector: Selector<TValue>): (() => TValue) => {
	const innerHook = createHook(ListStore, {
		selector: (state) => selector(state),
	});

	return () => {
		const [val] = innerHook();
		return val;
	};
};

/**
 * Helper to create a proper react-sweet-state hook from a reselect selector creator
 */
export const createHigherLevelHook = <TValue, SelectorCreatorArgs extends ReadonlyArray<unknown>>(
	selectorCreator: SelectorCreator<TValue, SelectorCreatorArgs>,
): ((...rest: SelectorCreatorArgs) => TValue) => {
	// TODO POL-2195 memoize one is not suitable for this task, as it will only memoize the last invocation. while this
	//               improves subsequent calls with the same argument set, it does not improve calls with different args
	//               this case should be solved with a generic memoization lib, e.g. reselect (4.1+) or moize
	//               discussion: https://hello.atlassian.net/wiki/spaces/TGR/pages/1416371989/Dependency+review+moize
	const memoizedSelectorCreator = isCreateHigherLevelHookMultiParameterSelectorCreatorCacheEnabled()
		? cacheSelectorCreator(selectorCreator)
		: memoizeOne(selectorCreator);
	// TODO POL-2195 in the meantime, we can use lodash/memoize to at least solve the 1-ary case,
	//               which should be 99% of our selector creators anyway
	const monadicSelectorCreatorMemoized = memoize(selectorCreator);

	const rssHook = createHook(ListStore, {
		selector: (state, args) => {
			// @ts-expect-error - TS2339 - Property 'length' does not exist on type 'void'.
			if (args.length <= 1) {
				// @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'SelectorCreatorArgs'. | TS2488 - Type 'void' must have a '[Symbol.iterator]()' method that returns an iterator.
				return monadicSelectorCreatorMemoized(...args)(state);
			}
			// @ts-expect-error - TS2684 - The 'this' context of type 'void' is not assignable to method's 'this' of type 'ThisParameterType<SelectorCreator<TValue, SelectorCreatorArgs>>'. | TS2488 - Type 'void' must have a '[Symbol.iterator]()' method that returns an iterator.
			return memoizedSelectorCreator(...args)(state);
		},
	});

	return (...args: SelectorCreatorArgs) => {
		// @ts-expect-error - TS2345 - Argument of type 'SelectorCreatorArgs' is not assignable to parameter of type 'void'.
		const [val] = rssHook(args);
		return val;
	};
};
