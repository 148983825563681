import React, { useCallback, useEffect, useState, useRef, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import MediaServicesScaleLargeIcon from '@atlaskit/icon/glyph/media-services/scale-large';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewTitle,
	useCurrentViewKind,
	useIsReadyToExport,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { DN0, N0 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { DATETIME_FORMAT } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ExportViewAsProps } from '../types';
import messages from './messages';

const viewContentCss = `
    [data-ds--page-layout--slot="main"],
    #polaris-ideas\\.ui\\.view-content-container {
        width: fit-content !important;
        height: fit-content !important;
    }
`;

const matrixCss = `
    #polaris-ideas\\.ui\\.ideas-bucket-button {
        display: none !important;
    }
    [data-component-selector="matrix-y-axis"] {
        overflow: visible !important;
    }
`;

const listCss = `
    #list-view-table .table__table {
        position: static !important;
    }

    #list-view-table,
    #list-view-table > div,
    #list-view-table .table,
    #list-view-table .table__header,
    #list-view-table .table__header > div,
    #list-view-table .table__body {
        width: unset !important;
        height: unset !important;
    }

    ${viewContentCss}
`;

const getExportCss = (viewKind?: ViewKind) => {
	switch (viewKind) {
		case VIEW_KIND_MATRIX:
			return matrixCss;
		case VIEW_KIND_TABLE:
			return listCss;
		default:
			return viewContentCss;
	}
};

export const ExportViewAsImage = ({ isLoading, onExporting, containerName }: ExportViewAsProps) => {
	const { formatMessage } = useIntl();
	const title = useCurrentViewTitle();
	const viewKind = useCurrentViewKind();
	const { setIsExporting, setIsReadyToExport } = useViewActions();
	const [isExportingImage, setIsExportingImage] = useState(false);
	const readyToExportImage = useIsReadyToExport();
	const { colorMode } = useThemeObserver();
	const [isFullscreen, { setIsFullscreen: setIsFullScreenLayout }] = useIsFullscreen();
	const isFullScreenRef = useRef(isFullscreen);

	const exportAsImageConfig = useCallback(
		(container: HTMLElement) => {
			let currentContainer = container;
			const viewPort = container.getBoundingClientRect();
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
			const columns = document.getElementsByClassName(
				'view-board-column',
			) as HTMLCollectionOf<HTMLElement>;

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const swimlanesContainer = document.getElementById('swimlanes-container');
			if (swimlanesContainer) {
				currentContainer = swimlanesContainer;
			}
			let viewHeight = currentContainer.scrollHeight + viewPort.top;

			// if it'a board
			if (columns.length > 0) {
				const columnsArray = Array.from(columns);

				const maxColumnHeight = Math.max(
					...columnsArray.map((col) => {
						const colElement = col;
						colElement.scrollTop = 0;
						return col.scrollHeight;
					}),
				);

				const diff = maxColumnHeight - columns[0].offsetHeight;

				viewHeight += diff;
			}

			return {
				logging: false,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				scale: window.devicePixelRatio || 2,
				useCORS: true,
				windowHeight: viewHeight,
				windowWidth: currentContainer.scrollWidth + viewPort.left,
				ignoreElements: (element: HTMLElement) => element.classList.contains('hide-from-export'),
				removeContainer: true, // Useful for debugging, set it to false to inspect the iframe.
				onclone: () => {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					const styleTag = document.createElement('style');
					styleTag.textContent = getExportCss(viewKind);
					const html2canvasIframes =
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						document.querySelectorAll<HTMLIFrameElement>('.html2canvas-container');

					html2canvasIframes.forEach((iframe) => {
						const headElement = iframe.contentWindow?.document.head;
						headElement?.appendChild(styleTag);
					});
				},
				backgroundColor: colorMode === 'dark' ? DN0 : N0,
			};
		},
		[colorMode, viewKind],
	);

	const exportAsImage = useCallback(async () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.getElementById('polaris-ideas.ui.view-content-container');
		if (!container) return;
		if (title === null || title === undefined) return;
		if (containerName === undefined) return;

		const canvas = await html2canvas(container, exportAsImageConfig(container));

		canvas.toBlob(
			(blob) => {
				if (blob) {
					saveAs(blob, `${title} - ${containerName} ${format(new Date(), DATETIME_FORMAT)}.jpg`);
				}

				setIsReadyToExport(false);
				setIsExporting(false);
				setIsExportingImage(false);
				onExporting(false);
				if (getWillShowNav4() && !isFullScreenRef.current) {
					setIsFullScreenLayout(false);
				}
			},
			'image/jpeg',
			0.9,
		);
	}, [
		title,
		containerName,
		exportAsImageConfig,
		setIsExporting,
		setIsReadyToExport,
		onExporting,
		setIsFullScreenLayout,
	]);

	useEffect(() => {
		if (readyToExportImage && isExportingImage) {
			exportAsImage();
		}
	}, [readyToExportImage, isExportingImage, exportAsImage]);

	return (
		<LoadingButtonContainer
			id="pendo.export-modal.export-as-image-button"
			testId="polaris-ideas.ui.view-header.export-dialog.export-dialog-content.export-as-image.button"
			iconBefore={
				<MediaServicesScaleLargeIcon label={formatMessage(messages.label)} size="medium" />
			}
			appearance="subtle"
			onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'downloadPNGButton');
				if (getWillShowNav4() && !isFullScreenRef.current) {
					setIsFullScreenLayout(true);
					setTimeout(() => {
						setIsExporting(true);
						setIsExportingImage(true);
						onExporting(true);
					}, 0);
				} else {
					setIsExporting(true);
					setIsExportingImage(true);
					onExporting(true);
				}
			}}
			isDisabled={isLoading && !isExportingImage}
			isLoading={isLoading && isExportingImage}
		>
			<MessageContainer>{formatMessage(messages.button)}</MessageContainer>
		</LoadingButtonContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingButtonContainer = styled(LoadingButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '40px !important',
	padding: `0 ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageContainer = styled.div({
	textAlign: 'start',
	lineHeight: '40px',
	fontWeight: 400,
	margin: `0 0 0 ${token('space.100', '8px')}`,
});
