import React, { type SyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { autoScroller } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import Spinner from '@atlaskit/spinner';
import { N0, N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_START,
	PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_END,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project';
import {
	getTimelineMinColumnWidth,
	getTimelineMaxColumnWidth,
} from '@atlassian/jira-polaris-common/src/common/utils/timeline';
import {
	useIsSharedView,
	useIsCollectionView,
} from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useIsSorted } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks';
import {
	useTimelineDuration,
	useTimelineItems,
	useTimelineItemsOld,
	useTimelineLength,
} from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline';
import { useOpenRightSidebarTimelineFieldConfig } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import {
	useRightSidebarShowing,
	useIsRightSidebarOpen,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingTimelineConfig } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { useIsIssueOpenInSidebar } from '@atlassian/jira-polaris-common/src/controllers/route';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewUUID,
	useCurrentViewConfigured,
	useCurrentViewHideEmptyGroups,
	useCurrentViewId,
	useCurrentViewLayoutType,
	useCurrentViewTimelineMode,
	useHasSharedViewConfigError,
	useCurrentViewTimelineMarkers,
	useCanManageCurrentView,
	useCurrentViewAccessLevel,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { IdeaCard } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/main.tsx';
import {
	IdeaCardContextProvider,
	useCardHeight,
} from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils';
import {
	useHasNoProjectPermissions,
	useCanEditIssues,
	useCanManageViews,
	useCanModifyReporter,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	CREATOR_FIELDKEY,
	REPORTER_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { NO_GROUPING_GROUP_ID } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import {
	fireCompoundAnalyticsEvent,
	useViewOpenedAnalytics,
} from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once';
import Timeline from '@atlassian/jira-polaris-lib-timeline/src/async';
import type { Components } from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import { PublishedViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/publish-view/index.tsx';
import { TimelineViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/timeline-view/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { IdeasBucketButton } from '../common/ideas-bucket-button';
import { useCurrentViewSuccessExperience } from '../common/utils/experience';
import { useRowGrouping } from '../common/utils/group-options';
import { ExternalDateDragDisabledHint } from './external-date-drag-disabled-hint';
import { ExternalDateResizeDisabledHint } from './external-date-resize-disabled-hint';
import { ExternalDragItemWrapper } from './external-drag-item-wrapper';
import { RowGroup } from './row-group';
import { RowGroupPreview } from './row-group-preview';
import {
	useHeaders,
	useLocalArrangementInformation,
	useOnArrangementUpdate,
	useUpdateItemOld,
	useOnGroupOrderChanged,
	useUpdateItem,
} from './utils/hooks';

const IdeaTimelineInternalOld = () => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_START);
	});
	const items = useTimelineItemsOld();
	const headers = useHeaders();
	const updateItem = useUpdateItemOld();
	const [verticalGroupByField, , rowGroups, groupedIds] = useRowGrouping();
	const isSorted = useIsSorted();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const [canEditIssues] = useCanEditIssues();
	const onGroupOrderChanged = useOnGroupOrderChanged();
	const onArrangementUpdated = useOnArrangementUpdate();
	const viewId = useCurrentViewId();
	const viewLayoutType = useCurrentViewLayoutType();
	const isCompact = viewLayoutType === ViewLayoutType.COMPACT;
	const isSummary = viewLayoutType === ViewLayoutType.SUMMARY;
	const cardHeight = useCardHeight(isCompact, isSummary);
	const localArrangement = useLocalArrangementInformation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCurrentViewConfigured = useCurrentViewConfigured();
	const timelineContainerRef = useRef<HTMLDivElement | null>(null);
	const isSharedView = useIsSharedView();
	const timelineLength = useTimelineLength();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const currentViewTimelineMode = useCurrentViewTimelineMode();
	const markers = useCurrentViewTimelineMarkers();
	const { startDate, endDate } = useTimelineDuration();
	const { createViewMarker, updateViewMarker, deleteViewMarker } = useViewActions();
	const viewUUID = useCurrentViewUUID();
	const isCollectionView = useIsCollectionView();
	const viewAccessLevel = useCurrentViewAccessLevel();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();

	const [hasModifyReporterPermission] = useCanModifyReporter();
	const isMoveBetweenGroupsDisabled =
		(verticalGroupByField?.key === REPORTER_FIELDKEY && !hasModifyReporterPermission) ||
		verticalGroupByField?.key === CREATOR_FIELDKEY ||
		(ff('polaris.timeline.vertical-reordering') && !canEditIssues);

	const isGroupByFieldEditable = useMemo(() => {
		if (!verticalGroupByField || verticalGroupByField.type === FIELD_TYPES.REACTIONS) {
			return false;
		}
		return verticalGroupByField.editable;
	}, [verticalGroupByField]);

	const components: Components = useMemo(
		() => ({
			Item: ({ id, isDraggable, isResizing, isFillFullWidth }) => (
				<IdeaCard
					id={id}
					viewLayoutType={viewLayoutType}
					hasHoverState={isDraggable}
					hoverBackgroundColor={
						isDraggable ? token('elevation.surface.hovered', N20) : token('elevation.surface', N0)
					}
					isResizing={isResizing}
					isFillFullWidth={isFillFullWidth}
					hasFullscreenButton={false}
				/>
			),
			RowGroup,
			RowGroupPreview,
			ExternalDateDragDisabledHint,
			ExternalDateResizeDisabledHint,
			ExternalDragItemWrapper,
		}),
		[viewLayoutType],
	);

	const timelineItems = useMemo(
		() => (localArrangement || isSorted ? items : []),
		[localArrangement, isSorted, items],
	);

	useCurrentViewSuccessExperience();

	const analyticsCallback = useCallback(
		(accessLevel: string | undefined) => {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_END);

			fireCompoundAnalyticsEvent.TimelineView.opened(
				createAnalyticsEvent({}),
				items.length,
				timelineLength,
				accessLevel,
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[createAnalyticsEvent, isCurrentViewConfigured],
	);

	useViewOpenedAnalytics({
		viewId,
		viewAccessLevel,
		isViewConfigured: isCurrentViewConfigured,
		isViewPermissionsEnabled,
		analyticsCallback,
	});

	useEffect(() => {
		const container = timelineContainerRef.current;

		if (!container) {
			return undefined;
		}

		const cleanupDragAndDrop = monitorForElements({
			onDragStart: ({ location }) => {
				autoScroller.start({
					input: location.current.input,
				});
			},
			onDrag: ({ location }) => {
				autoScroller.updateInput({
					input: location.current.input,
				});
			},
			onDrop: () => autoScroller.stop(),
		});

		return cleanupDragAndDrop;
	}, []);

	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const openTimelineFieldConfig = useOpenRightSidebarTimelineFieldConfig();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const [sidebarShowing] = useRightSidebarShowing();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isIdeaSidebarOpen = useIsIssueOpenInSidebar();

	const handleOpenTimelineConfig = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'timelineSettings', { isEmptyView: true });
			openTimelineFieldConfig();
		},
		[openTimelineFieldConfig],
	);

	if (isSharedView && hasSharedViewConfigError) {
		return (
			<PublishedViewEmptyState
				permissionsLoaded={permissionsLoaded}
				hasProjectPermissions={!hasNoProjectPermissions}
			/>
		);
	}

	if (!isCurrentViewConfigured || !startDate || !endDate) {
		return (
			<TimelineViewEmptyState
				onButtonClick={
					(ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews)
						? handleOpenTimelineConfig
						: undefined
				}
				isHintHidden={sidebarShowing.mode === RightSidebarShowingTimelineConfig}
				isCollectionView={isCollectionView}
			/>
		);
	}

	const isReadOnly = !canEditIssues;
	const isLoadingArrangement = !localArrangement && !isSorted;

	const minColumnWidth = getTimelineMinColumnWidth(currentViewTimelineMode);
	const maxColumnWidth = getTimelineMaxColumnWidth(currentViewTimelineMode);

	return (
		<ExperienceFailErrorBoundary
			experience={experience.timelineView.pageSegmentLoad}
			metadata={{ isSharedView }}
		>
			<TimelineViewContainer ref={timelineContainerRef} isLoading={isLoadingArrangement}>
				{isLoadingArrangement && (
					<SpinnerWrapper>
						<Spinner delay={200} size="large" />
					</SpinnerWrapper>
				)}
				<Timeline
					onGroupOrderChanged={onGroupOrderChanged}
					rowGroups={rowGroups}
					groupedIds={groupedIds}
					isGroupByFieldEditable={isGroupByFieldEditable}
					itemsOld={timelineItems}
					components={components}
					headers={headers}
					hideEmptyGroups={hideEmptyGroups}
					isReadOnly={isReadOnly}
					isSorted={isSorted}
					itemHeight={cardHeight}
					onItemChangeOld={updateItem}
					onArrangementUpdated={onArrangementUpdated}
					isMoveBetweenGroupsDisabled={isMoveBetweenGroupsDisabled}
					isVerticalCardReorderingEnabled={
						ff('polaris.timeline.vertical-reordering') && isCollectionView
							? canManageViews
							: canEditIssues
					}
					itemArrangement={
						localArrangement
							? localArrangement[verticalGroupByField?.key || NO_GROUPING_GROUP_ID]
							: undefined
					}
					minColumnWidth={minColumnWidth}
					maxColumnWidth={maxColumnWidth}
					scale={currentViewTimelineMode}
					startDate={startDate}
					endDate={endDate}
					containerRef={timelineContainerRef}
					hasOffset={isRightSidebarOpen || isIdeaSidebarOpen}
					markers={markers}
					isMarkerReadOnly={
						isSharedView ||
						(ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews)
					}
					onMarkerCreate={(createdMarker) => viewUUID && createViewMarker(viewUUID, createdMarker)}
					onMarkerUpdate={(updatedMarker) => viewUUID && updateViewMarker(viewUUID, updatedMarker)}
					onMarkerDelete={(id) => viewUUID && deleteViewMarker(viewUUID, id)}
				/>
			</TimelineViewContainer>
			{!isReadOnly && (
				<IdeasBucketButtonWrapper>
					<IdeasBucketButton isHighlighted={timelineItems.length === 0} />
				</IdeasBucketButtonWrapper>
			)}
		</ExperienceFailErrorBoundary>
	);
};

const IdeaTimelineInternalNew = () => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_START);
	});
	const items = useTimelineItems();
	const headers = useHeaders();
	const updateItem = useUpdateItem();
	const [verticalGroupByField, , rowGroups, groupedIds] = useRowGrouping();
	const isSorted = useIsSorted();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const [canEditIssues] = useCanEditIssues();
	const onGroupOrderChanged = useOnGroupOrderChanged();
	const onArrangementUpdated = useOnArrangementUpdate();
	const viewId = useCurrentViewId();
	const viewLayoutType = useCurrentViewLayoutType();
	const isCompact = viewLayoutType === ViewLayoutType.COMPACT;
	const isSummary = viewLayoutType === ViewLayoutType.SUMMARY;
	const cardHeight = useCardHeight(isCompact, isSummary);
	const localArrangement = useLocalArrangementInformation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCurrentViewConfigured = useCurrentViewConfigured();
	const timelineContainerRef = useRef<HTMLDivElement | null>(null);
	const isSharedView = useIsSharedView();
	const timelineLength = useTimelineLength();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const currentViewTimelineMode = useCurrentViewTimelineMode();
	const markers = useCurrentViewTimelineMarkers();
	const { startDate, endDate } = useTimelineDuration();
	const { createViewMarker, updateViewMarker, deleteViewMarker } = useViewActions();
	const viewUUID = useCurrentViewUUID();
	const isCollectionView = useIsCollectionView();
	const viewAccessLevel = useCurrentViewAccessLevel();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();

	const [hasModifyReporterPermission] = useCanModifyReporter();
	const isMoveBetweenGroupsDisabled =
		(verticalGroupByField?.key === REPORTER_FIELDKEY && !hasModifyReporterPermission) ||
		verticalGroupByField?.key === CREATOR_FIELDKEY ||
		(ff('polaris.timeline.vertical-reordering') && !canEditIssues);

	const isGroupByFieldEditable = useMemo(() => {
		if (!verticalGroupByField || verticalGroupByField.type === FIELD_TYPES.REACTIONS) {
			return false;
		}
		return verticalGroupByField.editable;
	}, [verticalGroupByField]);

	const components: Components = useMemo(
		() => ({
			Item: ({ id, isDraggable, isResizing, isFillFullWidth }) => (
				<IdeaCard
					id={id}
					viewLayoutType={viewLayoutType}
					hasHoverState={isDraggable}
					hoverBackgroundColor={
						isDraggable ? token('elevation.surface.hovered', N20) : token('elevation.surface', N0)
					}
					isResizing={isResizing}
					isFillFullWidth={isFillFullWidth}
					hasFullscreenButton={false}
				/>
			),
			RowGroup,
			RowGroupPreview,
			ExternalDateDragDisabledHint,
			ExternalDateResizeDisabledHint,
			ExternalDragItemWrapper,
		}),
		[viewLayoutType],
	);

	const timelineItems = useMemo(
		() => (localArrangement || isSorted ? items : []),
		[localArrangement, isSorted, items],
	);

	useCurrentViewSuccessExperience();

	const analyticsCallback = useCallback(
		(accessLevel: string | undefined) => {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_END);

			fireCompoundAnalyticsEvent.TimelineView.opened(
				createAnalyticsEvent({}),
				items.length,
				timelineLength,
				accessLevel,
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[createAnalyticsEvent, isCurrentViewConfigured],
	);

	useViewOpenedAnalytics({
		viewId,
		viewAccessLevel,
		isViewPermissionsEnabled,
		isViewConfigured: isCurrentViewConfigured,
		analyticsCallback,
	});

	useEffect(() => {
		const container = timelineContainerRef.current;

		if (!container) {
			return undefined;
		}

		const cleanupDragAndDrop = monitorForElements({
			onDragStart: ({ location }) => {
				autoScroller.start({
					input: location.current.input,
				});
			},
			onDrag: ({ location }) => {
				autoScroller.updateInput({
					input: location.current.input,
				});
			},
			onDrop: () => autoScroller.stop(),
		});

		return cleanupDragAndDrop;
	}, []);

	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const openTimelineFieldConfig = useOpenRightSidebarTimelineFieldConfig();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const [sidebarShowing] = useRightSidebarShowing();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isIdeaSidebarOpen = useIsIssueOpenInSidebar();

	const handleOpenTimelineConfig = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'timelineSettings', { isEmptyView: true });
			openTimelineFieldConfig();
		},
		[openTimelineFieldConfig],
	);

	if (isSharedView && hasSharedViewConfigError) {
		return (
			<PublishedViewEmptyState
				permissionsLoaded={permissionsLoaded}
				hasProjectPermissions={!hasNoProjectPermissions}
			/>
		);
	}

	if (!isCurrentViewConfigured || !startDate || !endDate) {
		return (
			<TimelineViewEmptyState
				onButtonClick={
					(ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews)
						? handleOpenTimelineConfig
						: undefined
				}
				isHintHidden={sidebarShowing.mode === RightSidebarShowingTimelineConfig}
				isCollectionView={isCollectionView}
			/>
		);
	}

	const isReadOnly = !canEditIssues;
	const isLoadingArrangement = !localArrangement && !isSorted;

	const minColumnWidth = getTimelineMinColumnWidth(currentViewTimelineMode);
	const maxColumnWidth = getTimelineMaxColumnWidth(currentViewTimelineMode);

	return (
		<ExperienceFailErrorBoundary
			experience={experience.timelineView.pageSegmentLoad}
			metadata={{ isSharedView }}
		>
			<TimelineViewContainer ref={timelineContainerRef} isLoading={isLoadingArrangement}>
				{isLoadingArrangement && (
					<SpinnerWrapper>
						<Spinner delay={200} size="large" />
					</SpinnerWrapper>
				)}
				<Timeline
					onGroupOrderChanged={onGroupOrderChanged}
					rowGroups={rowGroups}
					groupedIds={groupedIds}
					isGroupByFieldEditable={isGroupByFieldEditable}
					items={timelineItems}
					components={components}
					headers={headers}
					hideEmptyGroups={hideEmptyGroups}
					isReadOnly={isReadOnly}
					isSorted={isSorted}
					itemHeight={cardHeight}
					onItemChange={updateItem}
					onArrangementUpdated={onArrangementUpdated}
					isMoveBetweenGroupsDisabled={isMoveBetweenGroupsDisabled}
					isVerticalCardReorderingEnabled={
						ff('polaris.timeline.vertical-reordering') && isCollectionView
							? canManageViews
							: canEditIssues
					}
					itemArrangement={
						localArrangement
							? localArrangement[verticalGroupByField?.key || NO_GROUPING_GROUP_ID]
							: undefined
					}
					minColumnWidth={minColumnWidth}
					maxColumnWidth={maxColumnWidth}
					scale={currentViewTimelineMode}
					startDate={startDate}
					endDate={endDate}
					containerRef={timelineContainerRef}
					hasOffset={isRightSidebarOpen || isIdeaSidebarOpen}
					markers={markers}
					isMarkerReadOnly={
						isSharedView ||
						(ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews)
					}
					onMarkerCreate={(createdMarker) => viewUUID && createViewMarker(viewUUID, createdMarker)}
					onMarkerUpdate={(updatedMarker) => viewUUID && updateViewMarker(viewUUID, updatedMarker)}
					onMarkerDelete={(id) => viewUUID && deleteViewMarker(viewUUID, id)}
				/>
			</TimelineViewContainer>
			{!isReadOnly && (
				<IdeasBucketButtonWrapper>
					<IdeasBucketButton isHighlighted={timelineItems.length === 0} />
				</IdeasBucketButtonWrapper>
			)}
		</ExperienceFailErrorBoundary>
	);
};

const IdeaTimelineInternal = componentWithFG(
	'polaris-use-dates-inside-timeline-lib',
	IdeaTimelineInternalNew,
	IdeaTimelineInternalOld,
);

export const IdeaTimeline = () => (
	<IdeaCardContextProvider>
		<IdeaTimelineInternal />
	</IdeaCardContextProvider>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimelineViewContainer = styled.div<{ isLoading: boolean }>({
	position: 'relative',
	width: '100%',
	height: '100%',
	overflow: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isLoading }) => (isLoading ? 'none' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	position: 'absolute',
	zIndex: 1,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeasBucketButtonWrapper = styled.div({
	position: 'absolute',
	right: '28px',
	bottom: '28px',
	zIndex: 5,
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useTimelineIdeaCount } from './utils/hooks';
