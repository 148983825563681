import React, { useCallback, useEffect, useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';
import ButtonOld from '@atlaskit/button';
import { Stack } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldActions } from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import {
	useFieldLabel,
	useIsGlobalCustomField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useGlobalFieldKeysOfType } from '@atlassian/jira-polaris-common/src/controllers/global-fields/selectors/global-fields-hooks';
import { useDependencyAddedNotification } from '@atlassian/jira-polaris-component-field-configuration/src/ui/configuration/formula/dependency-info/notification';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { LinkPressable } from '@atlassian/jira-polaris-lib-link-pressable/src/ui';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useFieldsTableActions } from '../../../../../../../controllers/fields-table';
import {
	useFieldsScopeFilter,
	useSearchQueryFilter,
} from '../../../../../../../controllers/fields-table/selectors/fields-hooks';
import messages from './messages';

export type NewFieldsAdded = {
	fieldName: string;
	fieldKeys: string[];
	global: boolean;
};

type NewFieldNotificationDescriptionProps = {
	onShowAddedField: () => void;
	description: React.ReactNode;
};

const NewFieldNotificationDescription = ({
	onShowAddedField,
	description,
}: NewFieldNotificationDescriptionProps) => {
	const { formatMessage } = useIntl();

	return (
		<Stack alignInline="start" space="space.100">
			<p>{description}</p>
			{fg('polaris_global_fields_button_migration') ? (
				<LinkPressable
					testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.notifications.action"
					onClick={onShowAddedField}
					label={formatMessage(messages.showMe)}
				/>
			) : (
				<ButtonOld
					testId="polaris-ideas.ui.settings.fields.project-fields-screen.ui.fields-list.notifications.action"
					appearance="link"
					spacing="none"
					onClick={onShowAddedField}
				>
					{formatMessage(messages.showMe)}
				</ButtonOld>
			)}
		</Stack>
	);
};

const useFieldAddedNotification = () => {
	const { formatMessage } = useIntl();
	const { resetHighlightedFields, setHighlightedFields } = useFieldActions();
	const { setFieldScopeFilter, setSearchQueryFilter } = useFieldsTableActions();
	const fieldScopeFilter = useFieldsScopeFilter();
	const searchQueryFilter = useSearchQueryFilter();
	const { success } = useNotifications();

	const showAddedField = useCallback(
		(fieldKeys: string[], scope: typeof fieldScopeFilter, onClose: () => void) => {
			setFieldScopeFilter(scope);
			setSearchQueryFilter('');
			setHighlightedFields(fieldKeys);

			onClose();
		},
		[setHighlightedFields, setFieldScopeFilter, setSearchQueryFilter],
	);

	return useCallback(
		(fieldEvent: NewFieldsAdded) => {
			const singleFieldAdded = fieldEvent.fieldKeys.length === 1;

			const title = singleFieldAdded
				? formatMessage(
						fieldEvent.global ? messages.addFieldSuccessTitle : messages.createFieldSuccessTitle,
						{
							field: fieldEvent.fieldName,
						},
					)
				: formatMessage(
						fieldEvent.global
							? messages.addSeveralGlobalFieldsSuccessTitle
							: messages.addSeveralFieldsSuccessTitle,
						{
							count: fieldEvent.fieldKeys.length,
						},
					);

			if (fieldScopeFilter === 'project' && fieldEvent.global) {
				resetHighlightedFields();

				const closeNotification = success({
					title,
					description: (
						<NewFieldNotificationDescription
							description={formatMessage(
								singleFieldAdded
									? messages.notVisibleGlobalFieldAddedDescription
									: messages.notVisibleGlobalFieldsAddedDescription,
								{
									i: (text: React.ReactNode) => <i>{text}</i>,
								},
							)}
							onShowAddedField={() => {
								showAddedField(fieldEvent.fieldKeys, 'global', closeNotification);
							}}
						/>
					),
				});
			} else if (fieldScopeFilter === 'global' && !fieldEvent.global) {
				resetHighlightedFields();

				const closeNotification = success({
					title,
					description: (
						<NewFieldNotificationDescription
							description={formatMessage(
								singleFieldAdded
									? messages.notVisibleProjectFieldAddedDescription
									: messages.notVisibleProjectFieldsAddedDescription,
								{
									i: (text: React.ReactNode) => <i>{text}</i>,
								},
							)}
							onShowAddedField={() => {
								showAddedField(fieldEvent.fieldKeys, 'project', closeNotification);
							}}
						/>
					),
				});
			} else if (
				searchQueryFilter &&
				!fieldEvent.fieldName.toLocaleLowerCase().includes(searchQueryFilter.toLocaleLowerCase())
			) {
				resetHighlightedFields();

				const closeNotification = success({
					title,
					description: (
						<NewFieldNotificationDescription
							description={formatMessage(
								singleFieldAdded
									? messages.notVisibleFieldAddedDescription
									: messages.notVisibleFieldsAddedDescription,
							)}
							onShowAddedField={() => {
								showAddedField(
									fieldEvent.fieldKeys,
									fieldEvent.global ? 'global' : 'project',

									closeNotification,
								);
							}}
						/>
					),
				});
			} else if (!singleFieldAdded) {
				success({
					title,
				});
			}
		},
		[
			fieldScopeFilter,
			searchQueryFilter,
			success,
			formatMessage,
			showAddedField,
			resetHighlightedFields,
		],
	);
};

type NewFieldsNotificationsProps = {
	newFields: string[];
};

// the component shows a corresponding notification about new fields if new fields array is not empty and contains changed fieldKeys
export const NewFieldsNotifications: React.FC<NewFieldsNotificationsProps> = memo(
	({ newFields }) => {
		const showNewFieldNotification = useFieldAddedNotification();
		const showDependencyAddedNotification = useDependencyAddedNotification();

		const fieldKey = newFields[0] || '';
		const label = useFieldLabel(fieldKey) || '';
		const isGlobalCustomField = useIsGlobalCustomField(fieldKey);

		const globalFormulaKeys = useGlobalFieldKeysOfType(FIELD_TYPES.FORMULA);
		const hasAddedGlobalFormula = newFields.some((key) => globalFormulaKeys.has(key));

		const fieldAdded = useMemo<NewFieldsAdded | undefined>(() => {
			if (newFields.length === 0) {
				return undefined;
			}

			return {
				fieldName: label,
				fieldKeys: newFields,
				global: isGlobalCustomField,
			};
		}, [label, newFields, isGlobalCustomField]);

		useEffect(() => {
			if (!fieldAdded) {
				return;
			}
			if (hasAddedGlobalFormula) {
				showDependencyAddedNotification();
				return;
			}
			showNewFieldNotification(fieldAdded);
		}, [
			fieldAdded,
			hasAddedGlobalFormula,
			showDependencyAddedNotification,
			showNewFieldNotification,
		]);

		return null;
	},
	(prevPros, nextProps) => isEqual(prevPros.newFields, nextProps.newFields),
);
