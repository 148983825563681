import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { UpdateArrangementInfoRequest } from './types';

const UPDATE_GQL = gqlTagPolaris`
mutation jira_polaris_UpdatePolarisViewArrangementInfo($viewId: ID!, $input: JSON!) {
    updatePolarisViewArrangementInfo(id: $viewId, input: $input) {
        success
        errors {
          message
        }
      }
    }
`;

export const updateArrangementInfo = (
	apolloClient: PolarisApolloClient,
	{ viewId, arrangement }: UpdateArrangementInfoRequest,
): Promise<void> =>
	apolloClient
		.mutate({
			mutation: UPDATE_GQL,
			variables: {
				viewId,
				input: arrangement,
			},
		})
		.then((result) => {
			if (result.errors) {
				throw new Error(
					`polaris-ideas.put-arrangement-error:${result.errors.map((e) => e.message).join(', ')}`,
				);
			}
		});
