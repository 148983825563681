import React, { memo } from 'react';
import { ButtonItem, type CSSFn } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { SnippetProviderProperty } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import {
	COUNT_ROLLUP,
	POLARIS_OAUTH_CLIENT_ID,
	PROPERTY_ROLLUP,
} from '../../../../../common/constants';
import { messages as commonMessages } from '../../../../../common/messages';
import type { CountRollupField, PropertyRollupField } from '../../../../../common/types';
import { TooltipComponent } from '../../../../../common/ui/rollup-tooltip';
import { getAppName } from '../../../../../common/utils/formula/app-name/index.tsx';
import { messages } from './messages';
import type { DataOptionsProps, PropertyItemProps } from './types';

const cssFn: CSSFn = (currentStyles) => ({
	...currentStyles,
	padding: `${token('space.050', '4px')} ${token('space.200', '16px')}`,
	'& [data-item-elem-before]': {
		span: {
			transform: 'scale(1.5)',
		},
	},
});

export const toPropertyRollupField = (
	property: SnippetProviderProperty,
	appName: string,
	oauthClientId: string,
	avatarUrl: string,
): PropertyRollupField => ({
	kind: PROPERTY_ROLLUP,
	value: {
		...property,
		appName,
		oauthClientId,
		avatarUrl,
	},
});

export const toNumDataPointsRollupField = (
	numDataPointsLabel: string,
	appName: string,
	oauthClientId: string,
	avatarUrl: string,
): CountRollupField => ({
	kind: COUNT_ROLLUP,
	value: {
		id: `${oauthClientId}-num-data-points`,
		label: numDataPointsLabel,
		appName,
		avatarUrl,
		oauthClientId,
	},
});

export const FieldRollupType = ({ kind }: { kind: string }) => {
	const { formatMessage } = useIntl();
	let label;
	switch (kind.toLowerCase()) {
		case 'num_data_points':
			label = formatMessage(commonMessages.sumType);
			break;
		case 'rating':
			label = formatMessage(commonMessages.sumType);
			break;
		case 'number':
			label = formatMessage(commonMessages.valueType);
			break;
		default:
			label = formatMessage(commonMessages.valueType);
			break;
	}
	return <Box xcss={labelStyles}>{label}</Box>;
};

export const PropertyItemIcon = ({
	label,
	appName,
	avatarUrl,
}: {
	label: string;
	appName: string;
	avatarUrl: string;
}) => (
	<Box xcss={iconContainerStyles}>
		{appName !== '' ? (
			<img src={avatarUrl} alt={appName} height="16" width="16" />
		) : (
			<PolarisIcon name={PolarisIconType.Datapoint} size="small" label={label} />
		)}
	</Box>
);

const PropertyItem = ({
	snippetProvider,
	isGlobalField,
	onPropertySelect,
	isFiltered,
}: PropertyItemProps) => {
	const { formatMessage } = useIntl();
	if (!isGlobalField && !Array.isArray(snippetProvider.properties)) {
		return null;
	}

	const avatarUrl = isGlobalField ? '' : snippetProvider.app.avatarUrl;
	const oauthClientId = isGlobalField ? POLARIS_OAUTH_CLIENT_ID : snippetProvider.app.oauthClientId;
	const appName = isGlobalField ? '' : getAppName(oauthClientId, snippetProvider.app.name);

	const numDataPointsLabel = formatMessage(messages.numInsightsLabel);

	return (
		<Box>
			{isFiltered(numDataPointsLabel) && (
				<TooltipComponent
					key={`${oauthClientId}-num-data-points`}
					content={
						appName !== ''
							? formatMessage(messages.appsNumInsightsHelpText, { appName })
							: formatMessage(messages.numInsightsHelpText)
					}
					header={
						<>
							<PropertyItemIcon
								appName={appName}
								avatarUrl={avatarUrl}
								label={numDataPointsLabel}
							/>{' '}
							{numDataPointsLabel}
						</>
					}
				>
					<ButtonItem
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
						cssFn={cssFn}
						testId="polaris-component-field-configuration.ui.configuration.formula.rollup.data-options.option"
						key={`${oauthClientId}-num-data-points`}
						onClick={() =>
							onPropertySelect(
								toNumDataPointsRollupField(numDataPointsLabel, appName, oauthClientId, avatarUrl),
							)
						}
						iconBefore={
							<PropertyItemIcon
								appName={appName}
								avatarUrl={avatarUrl}
								label={numDataPointsLabel}
							/>
						}
						iconAfter={<FieldRollupType kind="num_data_points" />}
					>
						{numDataPointsLabel}
					</ButtonItem>
				</TooltipComponent>
			)}
			{!isGlobalField &&
				snippetProvider.properties
					.filter(
						(property) =>
							['rating'].includes(property.kind.toLowerCase()) && isFiltered(property.label),
					)
					.map((property) => (
						<TooltipComponent
							key={property.id}
							header={
								<>
									<PropertyItemIcon
										appName={appName}
										avatarUrl={avatarUrl}
										label={property.label}
									/>{' '}
									{property.label}
								</>
							}
							content={formatMessage(messages.impactTypeHelpText)}
						>
							<ButtonItem
								// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
								cssFn={cssFn}
								testId="polaris-component-field-configuration.ui.configuration.formula.rollup.data-options.option"
								onClick={() =>
									onPropertySelect(
										toPropertyRollupField(property, appName, oauthClientId, avatarUrl),
									)
								}
								iconBefore={
									<PropertyItemIcon
										appName={appName}
										avatarUrl={avatarUrl}
										label={property.label}
									/>
								}
								iconAfter={<FieldRollupType kind={property.kind} />}
							>
								{property.label}
							</ButtonItem>
						</TooltipComponent>
					))}
			{!isGlobalField &&
				snippetProvider?.properties
					.filter(
						(property) =>
							['number'].includes(property.kind.toLowerCase()) && isFiltered(property.label),
					)
					.map((property) => (
						<TooltipComponent
							key={property.id}
							header={
								<>
									<PropertyItemIcon
										appName={appName}
										avatarUrl={avatarUrl}
										label={property.label}
									/>{' '}
									{property.label}
								</>
							}
							content={formatMessage(messages.insightValueTypeHelpText)}
						>
							<ButtonItem
								// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
								cssFn={cssFn}
								testId="polaris-component-field-configuration.ui.configuration.formula.rollup.data-options.option"
								onClick={() =>
									onPropertySelect(
										toPropertyRollupField(property, appName, oauthClientId, avatarUrl),
									)
								}
								iconBefore={
									<PropertyItemIcon
										appName={appName}
										avatarUrl={avatarUrl}
										label={property.label}
									/>
								}
								iconAfter={<FieldRollupType kind={property.kind} />}
							>
								{property.label}
							</ButtonItem>
						</TooltipComponent>
					))}
		</Box>
	);
};

export const DataOptions = memo<DataOptionsProps>(
	({ snippetProviders, isGlobalField, onClick, isFiltered }: DataOptionsProps) => {
		if (!isGlobalField && snippetProviders.length === 0) {
			return <></>;
		}
		const polarisProviderIdx = snippetProviders.findIndex(
			(sp) => sp.app.oauthClientId === POLARIS_OAUTH_CLIENT_ID,
		);
		// show polaris properties first, and then the other apps
		let sortedSnippetProviders = snippetProviders;
		if (polarisProviderIdx > 0) {
			sortedSnippetProviders = [...snippetProviders];
			sortedSnippetProviders.splice(polarisProviderIdx, 1);
			sortedSnippetProviders = [snippetProviders[polarisProviderIdx], ...sortedSnippetProviders];
		}

		return (
			<>
				{sortedSnippetProviders.map((snippetProvider) => (
					<PropertyItem
						key={snippetProvider.id}
						isFiltered={isFiltered}
						snippetProvider={snippetProvider}
						onPropertySelect={(rollupField) => onClick(rollupField)}
					/>
				))}
				{isGlobalField && (
					<PropertyItem
						isGlobalField
						isFiltered={isFiltered}
						onPropertySelect={(rollupField) => onClick(rollupField)}
					/>
				)}
			</>
		);
	},
);

const iconContainerStyles = xcss({
	marginRight: 'space.100',
});

const labelStyles = xcss({
	color: 'color.text.subtlest',
	fontSize: 'font.body',
	textTransform: 'uppercase',
	fontWeight: '600',
});
