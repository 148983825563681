import { useCallback } from 'react';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import { usePermissionActions } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import { usePolarisRouter } from '../route';
import { useAllIdeasViewId, useViewSlug } from '../views/selectors/view-hooks';

export const useOpenPreviewMode = () => {
	const projectKey = useProjectKeyUnsafe();
	const allIdeasViewId = useAllIdeasViewId();
	const allIdeasViewSlug = useViewSlug(allIdeasViewId);

	return useCallback(
		(previewRoleName: string) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(
				`/jira/polaris/projects/${projectKey}/ideas/view/${allIdeasViewSlug}?previewRole=${previewRoleName}`,
				'_blank',
			);
		},
		[allIdeasViewSlug, projectKey],
	);
};

export const useExitPreviewMode = () => {
	const { switchPreviewContributorMode } = usePermissionActions();
	const { setQuery } = usePolarisRouter();
	return useCallback(() => {
		setQuery({ previewRole: undefined }, 'replace');
		switchPreviewContributorMode(false);
	}, [setQuery, switchPreviewContributorMode]);
};
