/** @jsx jsx */
import React, { type MouseEvent, type KeyboardEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages';
import type { Props } from './types';

const stopPropagation = (e: MouseEvent<Element> | KeyboardEvent<Element>) => {
	e.stopPropagation();
};

const ProjectTagInternal = ({ projectName, projectAvatarUrl, isLink, maxWidth }: Props) => (
	// eslint-disable-next-line jira/react/no-style-attribute
	<div css={wrapperStyles} style={{ maxWidth: maxWidth || '100%' }}>
		<Inline
			space="space.050"
			alignBlock="center"
			xcss={isLink ? tagContainerInteractiveStyles : tagContainerStyles}
		>
			<img css={projectIconStyles} src={projectAvatarUrl} alt={`${projectName} icon`} />
			<span css={tagTextStyles}>{projectName}</span>
		</Inline>
	</div>
);

export const ProjectTag = (props: Props) => {
	const { projectName, projectKey, isLink } = props;
	const { formatMessage } = useIntl();

	if (!isLink) {
		return <ProjectTagInternal {...props} />;
	}

	return (
		<Tooltip
			content={formatMessage(messages.openProjectTooltip, {
				projectName,
			})}
		>
			{(tooltipProps) => (
				<span {...tooltipProps} css={linkWrapperStyles}>
					<Link href={`/browse/${projectKey}`} target="_blank" onClick={stopPropagation}>
						<ProjectTagInternal {...props} />
					</Link>
				</span>
			)}
		</Tooltip>
	);
};

const wrapperStyles = css({
	width: 'fit-content',
});

const tagContainerStyles = xcss({
	paddingInline: 'space.050',
	height: '22px',
	borderRadius: '4px',
	backgroundColor: 'color.background.neutral',
});

const tagContainerInteractiveStyles = xcss({
	paddingInline: 'space.050',
	height: '22px',
	borderRadius: '4px',
	backgroundColor: 'color.background.neutral',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
});

const projectIconStyles = css({
	width: token('space.200'),
	height: token('space.200'),
	borderRadius: '4px',
});

const tagTextStyles = css({
	font: token('font.heading.xxsmall'),
	color: token('color.text'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// User need to understand it'll behave like a link (open in new tab...)
const linkWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'*, *:hover, *:active': {
		cursor: 'pointer',
		textDecoration: 'none',
	},
});
