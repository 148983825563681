import React, { useState, useCallback } from 'react';
import Button from '@atlaskit/button/new';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	isOpen: boolean;
	optionLabel: string | undefined;
	onCancel: () => void;
	onConfirm: () => Promise<void>;
};

export const DeleteOptionModal = (props: Props) => {
	const { isOpen, optionLabel, onCancel, onConfirm } = props;
	const { formatMessage } = useIntl();

	const [isDeleting, setIsDeleting] = useState(false);

	const onClickConfirm = useCallback(() => {
		setIsDeleting(true);
		onConfirm();
	}, [onConfirm]);

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog onClose={onCancel}>
						<ModalHeader>
							<ModalTitle appearance="danger">
								{optionLabel !== undefined
									? formatMessage(messages.deleteHeader, { optionLabel })
									: formatMessage(messages.deleteIndeterminateHeader)}
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<Text as="p">{formatMessage(messages.description)}</Text>
						</ModalBody>
						<ModalFooter>
							<Button onClick={onCancel}>{formatMessage(messages.cancel)}</Button>
							<Button
								testId="polaris-lib-option-utils.ui.delete-option.delete-modal.delete-option-modal-confirm-button"
								isLoading={isDeleting}
								onClick={onClickConfirm}
								appearance="danger"
							>
								{formatMessage(messages.delete)}
							</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};
