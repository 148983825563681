import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnyAri } from '@atlassian/ari/any-ari';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection';
import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { toProjectAri } from '@atlassian/jira-polaris-domain-project/src/utils.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { createCollectionView } from '../../services/jpd-views-service/create-collection-view';
import { deleteCollectionView } from '../../services/jpd-views-service/delete-collection-view';
import { deleteViewsServiceView } from '../../services/jpd-views-service/delete-view';
import {
	fetchAllCollectionViews,
	fetchCollectionView,
} from '../../services/jpd-views-service/fetch-collection-view';
import { updateCollectionView } from '../../services/jpd-views-service/update-collection-view';
import { getViewARI } from '../../services/jpd-views-service/utils';
import type { ViewConfigFetcher } from '../../services/jpd-views-service/view-config/types';
import { createView } from '../../services/polaris-api/create-view';
import { deleteView } from '../../services/polaris-api/delete-view';
import { updateView } from '../../services/polaris-api/update-view';
import { fetchViewAsViewSet } from './jpd-view-service';
import { getRootViewSet, transformViewConfig } from './jpd-view-service/transform/index.tsx';
import { fetchAndTransformViews, fetchAndTransformView } from './polaris-api';
import type { ViewEntityRemote } from './types';

export const createViewEntityRemote = (
	cloudId: CloudId,
	projectId: ProjectId,
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
): ViewEntityRemote => {
	const projectAri = toProjectAri(projectId, cloudId);

	return {
		fetchAll: (fields, currentViewSlug, checkIsViewCollapsed) =>
			fetchAndTransformViews(
				projectAri,
				apolloClient,
				createAnalyticsEvent,
				fields,
				currentViewSlug,
				checkIsViewCollapsed,
			),
		fetchView: (viewId, fields) => fetchAndTransformView(apolloClient, viewId, fields),
		createView: (props) => createView(apolloClient, props),
		updateView: (props) => updateView(apolloClient, props),
		deleteView: (viewId) => deleteView(apolloClient, viewId),
		deletePublishedView: deleteViewsServiceView,
	};
};

export const createNotImplementedViewEntityRemote = (): ViewEntityRemote => ({
	createView: notImplementedError('createView'),
	deleteView: notImplementedError('deleteView'),
	fetchView: notImplementedError('fetchView'),
	updateView: notImplementedError('updateView'),
	fetchAll: notImplementedError('fetchAll'),
	deletePublishedView: notImplementedError('deletePublishedView'),
});

export const createSharingViewEntityRemote = (
	cloudId: CloudId,
	viewId: string,
	viewConfigFetcher: ViewConfigFetcher,
): ViewEntityRemote => ({
	...createNotImplementedViewEntityRemote(),
	fetchAll: (fields) =>
		fetchViewAsViewSet(viewConfigFetcher, cloudId, viewId, fields).catch((error) => {
			if (error instanceof FetchError && (error.statusCode === 403 || error.statusCode === 404)) {
				return [];
			}

			throw error;
		}),
});

const getIsAutosaveEnabled = (view: ViewResponse) => {
	if (fg('polaris_just-for-you')) {
		return true;
	}

	return view.enabledAutoSave ?? false;
};

const transformCollectionView =
	(collectionUUID: string, cloudId: CloudId, fields: FieldMap) =>
	(view: ViewResponse, index?: number) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		({
			uuid: view.uuid,
			viewId: getViewARI(cloudId, view.uuid),
			kind: view.visualizationType,
			title: view.name,
			emoji: view.emoji,
			projectId: undefined,
			viewLegacyId: undefined,
			editable: false,
			...(index === undefined ? {} : { rank: index + 1 }),
			isAutosaveEnabled: getIsAutosaveEnabled(view),
			layoutType: view.layoutType ?? null,
			createdAtTimestamp: undefined,
			updatedAtTimestamp: undefined,
			rankFieldKey: undefined,
			configError: undefined,
			statusCategories: undefined,
			viewSetId: getViewARI(cloudId, collectionUUID),
			...transformViewConfig(view, fields),
		}) as unknown as View;

export const createCollectionViewEntityRemote = (
	cloudId: CloudId,
	collectionUUID: string,
): ViewEntityRemote => ({
	...createNotImplementedViewEntityRemote(),
	deleteView: (viewId) => {
		const { resourceId } = AnyAri.parse(viewId);
		return deleteCollectionView(collectionUUID, resourceId);
	},
	createView: (props) => createCollectionView(cloudId, collectionUUID, props),
	updateView: (props) => updateCollectionView(collectionUUID, props),
	fetchAll: (fields) =>
		fetchAllCollectionViews(collectionUUID).then((views) => [
			{
				...getRootViewSet(views.map(transformCollectionView(collectionUUID, cloudId, fields))),
				id: getViewARI(cloudId, collectionUUID),
			},
		]),
	fetchView: (viewId, fields) =>
		fetchCollectionView(collectionUUID, viewId).then(
			transformCollectionView(collectionUUID, cloudId, fields),
		),
});
