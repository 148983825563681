import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	bulk_operation_progress as BulkOperationProgress,
	bulk_operation_progressVariables as BulkOperationProgressVariables,
	bulk_operation_progress_jira_bulkOperationProgress as JiraIssueBulkOperationProgress,
} from './__generated_apollo__/bulk_operation_progress';
import type {
	jira_polaris_UpdateIdeasBulk as UpdateIdeasBulk,
	jira_polaris_UpdateIdeasBulkVariables as UpdateIdeasBulkVariables,
	jira_polaris_UpdateIdeasBulk_jira_submitBulkOperation_progress as JiraSubmitBulkOperationProgress,
} from './__generated_apollo__/jira_polaris_UpdateIdeasBulk';
import type { JiraSubmitBulkOperationInput } from './types';

const BULK_IDEAS_UPDATE = gqlTagPolaris`
  mutation jira_polaris_UpdateIdeasBulk($cloudId: ID!, $input:JiraSubmitBulkOperationInput!) {
    jira @optIn(to: "JiraBulkEditSubmitSpike") {
      submitBulkOperation(cloudId:$cloudId, input: $input) {
        progress {
          taskId
          id
          submittedTime
        }
      }
    }
  }
`;

const BULK_IDEAS_UPDATE_PROGRESS = gqlTagPolaris`
query bulk_operation_progress($cloudId: ID!, $taskId: ID!) {
  jira @optIn(to: "JiraIssueBulkOperationProgress"){
    bulkOperationProgress(cloudId:$cloudId, taskId: $taskId){
      taskId,
      progress
      status
      startTime
      successfulIssues {
        edges {
          node {
            issueId
          }
        }
      }
      bulkOperationFailures {
        edges {
          node {
            issue {
              issueId
            }
            failureReasons
          }
        }
      }
    }
  }
}
`;

export const updateIssueFieldsBulk = async (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
	input: JiraSubmitBulkOperationInput,
): Promise<JiraSubmitBulkOperationProgress | null> => {
	const result = await apolloClient.mutate<UpdateIdeasBulk, UpdateIdeasBulkVariables>({
		mutation: BULK_IDEAS_UPDATE,
		variables: {
			cloudId,
			input,
		},
	});

	if (result.errors) {
		throw new Error(
			`project-config.update-ideas-bulk-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	return result.data?.jira?.submitBulkOperation?.progress ?? null;
};

export const getUpdateIssueFieldsBulkProgress = async (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
	taskId: string,
): Promise<JiraIssueBulkOperationProgress | null> => {
	const result = await apolloClient.query<BulkOperationProgress, BulkOperationProgressVariables>({
		query: BULK_IDEAS_UPDATE_PROGRESS,
		fetchPolicy: 'network-only',
		variables: {
			cloudId,
			taskId,
		},
	});

	if (result.errors) {
		throw new Error(
			`project-config.get-update-ideas-bulk-progress-error:${result.errors
				.map((e) => e.message)
				.join(', ')}`,
		);
	}

	return result.data.jira?.bulkOperationProgress ?? null;
};
