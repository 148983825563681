import gqlTagPolaris from 'graphql-tag';
import { PLAY_CONTRIBUTION_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/play/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { TransformablePolarisPlayContribution } from '../fetch/types.tsx';
import type {
	CreatePlayContribution,
	CreatePlayContributionVariables,
	CreatePolarisPlayContribution,
} from './types';

const CREATE_PLAY_CONTRIBUTION_MUTATION = gqlTagPolaris`
mutation jira_polaris_CreatePlayContribution($input: CreatePolarisPlayContribution!, $skipComment: Boolean!) {
  createPolarisPlayContribution(input: $input) {
    success
    errors {
      message
    }
    node {
      ...PlayContribution
    }
  }
}
${PLAY_CONTRIBUTION_FRAGMENT}
`;

export const createPlayContribution = (
	client: PolarisApolloClient,
	input: CreatePolarisPlayContribution,
): Promise<TransformablePolarisPlayContribution> => {
	const variables: CreatePlayContributionVariables = {
		input,
		skipComment: false,
	};
	return client
		.mutate<CreatePlayContribution>({
			mutation: CREATE_PLAY_CONTRIBUTION_MUTATION,
			variables,
		})
		.then((result) => {
			if (!result.data?.createPolarisPlayContribution?.node) {
				throw new Error('project-config.create-play-contribution-error: no data or no data node');
			}

			return {
				...result.data.createPolarisPlayContribution.node,
			};
		});
};
