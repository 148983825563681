import React, { useMemo } from 'react';
import AvatarGroup from '@atlaskit/avatar-group';
import { Box, xcss } from '@atlaskit/primitives';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldLabel, useFieldType } from '../../../../controllers/field/selectors/field-hooks';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks';
import { usePeople, useUser } from '../../../../controllers/issue/selectors/properties/hooks';
import { UserTags } from '../../../fields/users/user-tags';
import { EmptyField, EmptyUserField } from '../empty';
import { FieldLabel, FieldValue } from '../field/styled';

type UserFieldProps = {
	issueId: string;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
};

type UserFieldInternalProps = UserFieldProps & {
	label: string | undefined;
};

const UserFieldInternal = ({
	isCompact,
	isSummary,
	label,
	fieldKey,
	issueId,
	hideEmpty,
}: UserFieldInternalProps) => {
	const user = useUser(fieldKey, issueId);
	const data = useMemo(
		() =>
			user
				? [
						{
							name: user.displayName || '',
							src: user.avatarUrls?.['32x32'],
						},
					]
				: undefined,
		[user],
	);

	const userTagsData = useMemo(() => {
		if (!data || !isSummary) return [];
		return data.map(({ name, src }) => ({
			id: name,
			name,
			avatarUrl: src,
		}));
	}, [data, isSummary]);

	if (!data && hideEmpty) {
		return null;
	}

	let content: React.JSX.Element | null;

	if (data === undefined) {
		content = null;
	} else if (isSummary) {
		content = <UserTags users={userTagsData} />;
	} else {
		content = <AvatarGroup appearance="stack" data={data} maxCount={9} size="small" />;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>
				{data !== undefined ? (
					<Box xcss={avatarGroupStyles}>{content}</Box>
				) : (
					isSummary && <EmptyUserField />
				)}
			</FieldValue>
		</>
	);
};

const UsersFieldInternal = ({
	isCompact,
	isSummary,
	label,
	fieldKey,
	issueId,
	hideEmpty,
}: UserFieldInternalProps) => {
	const people = usePeople(fieldKey, issueId);
	const field = useField(fieldKey);
	const showFullName = field?.configuration?.displayMode === 'fullNameWithAvatar';
	const data = useMemo(
		() =>
			people?.map((user) => ({
				name: user.displayName || '',
				src: user.avatarUrls?.['32x32'],
			})),
		[people],
	);
	const userTagsData = useMemo(() => {
		if (!data || !showFullName) return [];
		return data.map((user) => ({
			id: user.name,
			name: user.name,
			avatarUrl: user.src,
		}));
	}, [data, showFullName]);

	if ((!data || data.length === 0) && hideEmpty) {
		return null;
	}

	let content: React.JSX.Element | null;

	if (data === undefined) {
		content = null;
	} else if (showFullName) {
		content = <UserTags users={userTagsData} verticalLayout={isSummary} />;
	} else {
		content = <AvatarGroup appearance="stack" data={data} maxCount={9} size="small" />;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>
				{data !== undefined && data.length ? (
					<Box xcss={avatarGroupStyles}>{content}</Box>
				) : (
					isSummary && <EmptyField />
				)}
			</FieldValue>
		</>
	);
};

export const UserField = (props: UserFieldProps) => {
	const type = useFieldType(props.fieldKey);
	const label = useFieldLabel(props.fieldKey);

	switch (type) {
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.CREATOR:
			return <UserFieldInternal label={label} {...props} />;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UsersFieldInternal label={label} {...props} />;
		default:
			return null;
	}
};

const avatarGroupStyles = xcss({
	marginLeft: 'space.050',
});
