import React from 'react';
import { useIssueLinkingEnabled } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useFieldFormula,
	useIsDeliveryFieldType,
} from '../../controllers/field/selectors/field-hooks';
import { DeliveryDataRestrictedMarker } from './delivery-data-restricted';
import { FormulaFieldMarker } from './formula';
import { IssueLinkingDisabledMarker } from './issue-linking-disabled';

type Props = {
	fieldKey: FieldKey;
};

export const FieldInfoMarker = ({ fieldKey }: Props) => {
	const formula = useFieldFormula(fieldKey);
	const delivery = useIsDeliveryFieldType(fieldKey);
	const isIssueLinkingEnabled = useIssueLinkingEnabled();

	if (delivery && !isIssueLinkingEnabled) {
		return <IssueLinkingDisabledMarker />;
	}

	if (formula) {
		return <FormulaFieldMarker fieldKey={fieldKey} />;
	}

	if (delivery) {
		return <DeliveryDataRestrictedMarker fieldKey={fieldKey} marginRight={6} />;
	}

	return null;
};
