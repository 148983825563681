/** @jsx jsx */
import React, { type ReactNode, useState, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { EmojiWrapper } from '@atlassian/jira-polaris-common/src/ui/field-config/item/emoji-wrapper';
import type {
	NumericFieldFilterValue,
	IntervalFieldFilterOperator,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { NumDataPointsFilterContentContainer, getOperatorVisualisationType } from './content';
import messages from './messages';

const SelectionLabel = ({
	values,
}: {
	values: NumericFieldFilterValue[] | IntervalFieldFilterOperator[];
}) => {
	const { formatMessage } = useIntl();
	const vizType = getOperatorVisualisationType(values);

	switch (vizType) {
		case 'EQ':
			return <>{formatMessage(messages.opEmpty)}</>;
		case 'LT':
			return <>{formatMessage(messages.opLessThan, { value: values[0].numericValue })}</>;
		case 'GT':
			return <>{formatMessage(messages.opGreaterThan, { value: values[0].numericValue })}</>;
		case 'BETWEEN':
			return (
				<>
					{formatMessage(messages.opBetween, {
						value1: values[0].numericValue,
						value2: values[1].numericValue,
					})}
				</>
			);
		case 'START_BEFORE_NOW':
			return (
				<>
					{formatMessage(messages.opCreatedWithLastDays, {
						value: values[0].numericValue,
					})}
				</>
			);
		default:
			return null;
	}
};

const NumDataPointsFilterButtonLabel = ({
	label,
	values,
	fieldTypeIcon,
	emoji,
}: {
	label: string;
	values: NumericFieldFilterValue[] | IntervalFieldFilterOperator[];
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
}) => {
	if (values.length === 0) {
		return (
			<Flex alignItems="center">
				{emoji ? (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</EmojiWrapper>
				) : (
					<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
				)}
				<div css={labelStyles}>{label}</div>
			</Flex>
		);
	}

	return (
		<Flex alignItems="center">
			{emoji ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
			)}
			<div css={labelStyles}>{label}</div>
			<Box xcss={filterLabelValueStyles}>
				<SelectionLabel values={values} />
			</Box>
		</Flex>
	);
};

type NumDataPointsFilterComponentProps = {
	isLastFilter: boolean;
	label: string;
	values: NumericFieldFilterValue[];
	intervalValues: IntervalFieldFilterOperator[];
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
	defaultOpen?: boolean;
	onChange: (value: NumericFieldFilterValue[]) => void;
	onChangeIntervalValue: (value: IntervalFieldFilterOperator[]) => void;
	isUsingCustomColor?: boolean;
	isDisabled?: boolean;
};

export const NumDataPointsFilterComponent = ({
	label,
	values,
	intervalValues,
	onChange,
	onChangeIntervalValue,
	fieldTypeIcon,
	emoji,
	defaultOpen,
	isLastFilter,
	isUsingCustomColor = false,
	isDisabled = false,
}: NumDataPointsFilterComponentProps) => {
	const [dialogOpen, setDialogOpen] = useState(
		defaultOpen || (isLastFilter && !values.length && !intervalValues.length),
	);

	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={() => {
				setDialogOpen(false);
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={
				<NumDataPointsFilterContentContainer
					values={values}
					intervalValues={intervalValues}
					onChange={onChange}
					onChangeIntervalValue={onChangeIntervalValue}
				/>
			}
		>
			<div css={[isUsingCustomColor && buttonWrapperCustomColorStyles]}>
				<Button
					data-component-selector="polaris-ideas.ui.view-controls.config-filters.filter-component.num-data-points.button-16d0"
					isSelected={dialogOpen}
					onClick={toggleDialog}
					iconAfter={(iconProps) => <ChevronDownIcon {...iconProps} />}
					isDisabled={isDisabled}
				>
					<NumDataPointsFilterButtonLabel
						values={values.length ? values : intervalValues}
						label={label}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
					/>
				</Button>
			</div>
		</PolarisInlineDialog>
	);
};

const buttonWrapperCustomColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="polaris-ideas.ui.view-controls.config-filters.filter-component.num-data-points.button-16d0"]':
		{
			background: token('color.background.warning'),
			color: token('color.text.accent.yellow.bolder'),
		},
});

const filterLabelValueStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const iconContainerStyles = xcss({
	paddingInlineEnd: 'space.100',
	alignItems: 'center',
});

const labelStyles = css({
	fontWeight: token('font.weight.bold'),
});
