import React from 'react';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	useCurrentViewContainsArchived,
	useCurrentViewKind,
	useCurrentViewSortMode,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { ConfigSort } from '../../view-controls/config-sort';
import { Header } from '../header';
import { AdvancedSortingPanel } from './advanced';
import { messages } from './messages';

type Props = {
	onClose: () => void;
};

export const SortPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	const viewKind = useCurrentViewKind();
	const containsArchived = useCurrentViewContainsArchived();
	const isSharedView = useIsSharedView();
	const currentSortMode = useCurrentViewSortMode();

	if (currentSortMode === undefined) {
		return null;
	}

	const displayAdvancedSortingPanel =
		viewKind !== VIEW_KIND_TIMELINE && !isSharedView && !containsArchived;

	return (
		<Stack spread="space-between" xcss={containerStyles}>
			<Box>
				<Header title={formatMessage(messages.sort)} onClose={onClose} />
				<ConfigSort />
			</Box>
			{!!displayAdvancedSortingPanel && <AdvancedSortingPanel />}
		</Stack>
	);
};

const containerStyles = xcss({
	minHeight: '100%',
});
