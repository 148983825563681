/** @jsx jsx */
import React, { type MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { Flex, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useHasViewTemporaryData,
	useCurrentViewId,
	useCanManageCurrentView,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SaveViewButton as SaveViewButtonLegacy } from './legacy';
import { messages } from './messages';

export const SaveViewButton = componentWithFG(
	'polaris_just-for-you',
	() => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { formatMessage } = useIntl();
		const { applyViewTemporaryData, saveCurrentViewWithTemporaryDataAsNew } = useViewActions();
		const hasViewTemporaryData = useHasViewTemporaryData();
		const currentViewId = useCurrentViewId();
		const canManageCurrentView = useCanManageCurrentView();
		const canManageViews = useCanManageViews();
		const canManageView = ff('polaris.view-permissions_plaoi')
			? canManageCurrentView
			: canManageViews;
		const [isSaved, setIsSaved] = useState(false);
		const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

		const handleSave = useCallback(() => {
			applyViewTemporaryData();
			setIsSaved(true);
			timeoutRef.current = setTimeout(() => {
				setIsSaved(false);
			}, 3000);
			fireCompoundAnalyticsEvent.View.unsavedChangesSave(createAnalyticsEvent({}));
		}, [applyViewTemporaryData, createAnalyticsEvent]);

		const handleSaveAsNew = useCallback(
			(e: MouseEvent) => {
				e.stopPropagation();
				saveCurrentViewWithTemporaryDataAsNew();
				fireCompoundAnalyticsEvent.View.unsavedChangesSaveAsNew(createAnalyticsEvent({}));
			},
			[saveCurrentViewWithTemporaryDataAsNew, createAnalyticsEvent],
		);

		useEffect(() => {
			if (hasViewTemporaryData) {
				clearTimeout(timeoutRef.current);
				setIsSaved(false);
			}
		}, [hasViewTemporaryData]);

		useEffect(() => {
			clearTimeout(timeoutRef.current);
			setIsSaved(false);
		}, [currentViewId]);

		useEffect(() => () => clearTimeout(timeoutRef.current), []);

		if ((!hasViewTemporaryData && !isSaved) || !canManageViews) {
			return null;
		}

		if (isSaved) {
			return (
				<Flex alignItems="center">
					<Text color="color.text.success">
						<CheckIcon label="" />
					</Text>
					<Text color="color.text.success">{formatMessage(messages.configSaved)}</Text>
				</Flex>
			);
		}

		if (canManageView) {
			return (
				<div css={buttonWrapperStyles}>
					<Button
						id="polaris-ideas.ui.view-controls.unsaved-changes.save-for-everyone"
						onClick={handleSave}
					>
						{formatMessage(messages.save)}
					</Button>
				</div>
			);
		}

		return (
			<div css={buttonWrapperStyles}>
				<Button
					id="polaris-ideas.ui.view-controls.unsaved-changes.save-as-new-view"
					onClick={handleSaveAsNew}
				>
					{formatMessage(messages.saveAsNew)}
				</Button>
			</div>
		);
	},
	SaveViewButtonLegacy,
);

const buttonWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		background: token('color.background.warning'),
		color: token('color.text.accent.yellow.bolder'),
	},
});
