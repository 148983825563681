import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { useApolloClient } from '@apollo/react-hooks';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { Section } from '@atlaskit/menu';
import Spinner from '@atlaskit/spinner';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { DEFAULT_TEMPLATES } from '@atlassian/jira-polaris-common/src/common/types/idea-templates/default';
import type {
	IdeaTemplate,
	IdeaTemplateData,
} from '@atlassian/jira-polaris-common/src/common/types/idea-templates/types';
import { useSelectedIssueProjectAri } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useCloseRightSidebar } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useIsIssueOpenInFullscreen } from '@atlassian/jira-polaris-common/src/controllers/route';
import {
	createIdeaTemplate,
	updateIdeaTemplate,
	getIdeaTemplates,
	deleteIdeaTemplate,
} from '@atlassian/jira-polaris-common/src/services/polaris-api/idea-templates';
import { useProjectAriUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import { useCanManageIdeaTemplates } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';
import { IdeaTemplateComponent, AddIdeaTemplateComponent } from './template';
import { CreateTemplateForm } from './template-form';

type Props = {
	inSidebar?: boolean;
	onClose?: () => void;
	onApplyTemplate: (template: ADF | undefined, id?: string, shouldApply?: boolean) => void;
};

const usePreviousState = <T,>(value: T): T | undefined => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export const IdeaTemplatesPanel = ({ onClose, onApplyTemplate, inSidebar = true }: Props) => {
	const { formatMessage } = useIntl();
	const canManageIdeaTemplates = useCanManageIdeaTemplates();
	const closeRightSidebar = useCloseRightSidebar();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [templates, setTemplates] = useState<IdeaTemplate[]>([]);
	const [isLoading, setLoading] = useState(true);
	const { generalDataUpdateFailedError, generalDataLoadingFailedError } = useErrorHandlers();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const apolloClient = useApolloClient();
	const unsafeProjectAri = useProjectAriUnsafe();
	const projectAri = useSelectedIssueProjectAri() || unsafeProjectAri;
	const prevIsIssueOpenInFullscreen = usePreviousState(isIssueOpenInFullscreen);

	useEffect(() => {
		if (prevIsIssueOpenInFullscreen && !isIssueOpenInFullscreen) {
			closeRightSidebar();
		}
	}, [isIssueOpenInFullscreen, prevIsIssueOpenInFullscreen, closeRightSidebar]);

	useEffect(() => {
		getIdeaTemplates(apolloClient, projectAri)
			.then(setTemplates)
			.catch(generalDataLoadingFailedError)
			.finally(() => setLoading(false));
	}, [apolloClient, generalDataLoadingFailedError, projectAri]);

	const addTemplate = useCallback(
		(newTemplate: IdeaTemplateData) => {
			createIdeaTemplate(apolloClient, {
				project: projectAri,
				...newTemplate,
			})
				.then((createdId) => {
					setTemplates(templates.concat({ ...newTemplate, id: createdId || '' }));
				})
				.catch(generalDataUpdateFailedError);

			fireCompoundAnalyticsEvent.IdeaDescriptionTemplateCreateClicked(createAnalyticsEvent({}));
		},
		[apolloClient, createAnalyticsEvent, generalDataUpdateFailedError, projectAri, templates],
	);

	const updateTemplate = useCallback(
		(templateData: IdeaTemplate) => {
			updateIdeaTemplate(apolloClient, {
				project: projectAri,
				...templateData,
			}).catch(generalDataUpdateFailedError);

			fireCompoundAnalyticsEvent.IdeaDescriptionTemplateUpdateClicked(createAnalyticsEvent({}));

			return setTemplates(
				templates.map((template) => (template.id === templateData.id ? templateData : template)),
			);
		},
		[apolloClient, createAnalyticsEvent, generalDataUpdateFailedError, projectAri, templates],
	);

	const deleteTemplate = useCallback(
		(id: string) => {
			setTemplates(templates.filter((template) => template.id !== id));
			deleteIdeaTemplate(apolloClient, {
				project: projectAri,
				id,
			}).catch(generalDataUpdateFailedError);

			fireCompoundAnalyticsEvent.IdeaDescriptionTemplateDeleteClicked(createAnalyticsEvent({}));
		},
		[apolloClient, createAnalyticsEvent, generalDataUpdateFailedError, projectAri, templates],
	);

	const openModal = useCallback(() => setDialogOpen(true), []);
	const closeModal = useCallback(() => setDialogOpen(false), []);

	templates.sort((a, b) => a.title.localeCompare(b.title));

	const renderTemplates = () => {
		if (isLoading) {
			return (
				<CenteredSpinnerWrapper>
					<Spinner />
				</CenteredSpinnerWrapper>
			);
		}
		if (templates.length === 0) {
			return (
				<>
					{canManageIdeaTemplates && inSidebar && (
						<AddIdeaTemplateComponent onCreateClick={openModal} />
					)}
					{DEFAULT_TEMPLATES.length !== 0 && (
						<Section title={formatMessage(messages.defaultTemplates)}>
							{DEFAULT_TEMPLATES.map((template) => (
								<IdeaTemplateComponent
									onApplyTemplate={(appliedTemplate) => {
										fireCompoundAnalyticsEvent.IdeaDescriptionTemplateApplied(
											createAnalyticsEvent({}),
											template?.id,
										);
										onApplyTemplate(appliedTemplate, template?.id);
									}}
									onUpdate={undefined}
									onDelete={undefined}
									inSidebar={inSidebar}
									key={template.id}
									data={template}
								/>
							))}
						</Section>
					)}
				</>
			);
		}
		return (
			<>
				<Section title={formatMessage(messages.projectTemplates)}>
					{templates.map((template) => (
						<IdeaTemplateComponent
							onApplyTemplate={(appliedTemplate, applyAfterUpdate) =>
								onApplyTemplate(appliedTemplate, template?.id, applyAfterUpdate)
							}
							onUpdate={updateTemplate}
							onDelete={deleteTemplate}
							inSidebar={inSidebar}
							key={template.id}
							data={template}
						/>
					))}
				</Section>
				{DEFAULT_TEMPLATES.length !== 0 && (
					<Section title={formatMessage(messages.defaultTemplates)}>
						{DEFAULT_TEMPLATES.map((template) => (
							<IdeaTemplateComponent
								onApplyTemplate={(appliedTemplate) => {
									fireCompoundAnalyticsEvent.IdeaDescriptionTemplateApplied(
										createAnalyticsEvent({}),
										template?.id,
									);
									onApplyTemplate(appliedTemplate, template?.id);
								}}
								onUpdate={undefined}
								onDelete={undefined}
								inSidebar={inSidebar}
								key={template.id}
								data={template}
							/>
						))}
					</Section>
				)}
			</>
		);
	};

	return (
		<>
			{isDialogOpen && <CreateTemplateForm onCreate={addTemplate} onClose={closeModal} />}
			<Container id="polaris.ideas.idea-description-template.list" inSidebar={inSidebar}>
				{onClose && (
					<TemplatesHeader>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<span>{formatMessage(messages.ideaTemplates)}</span>
						<Button
							id="polaris-ideas.ui.right-sidebar.idea-templates.close"
							testId="polaris-ideas.ui.right-sidebar.idea-templates.close"
							appearance="subtle"
							iconBefore={<CrossIcon label="close" />}
							onClick={onClose}
						/>
					</TemplatesHeader>
				)}
				<TemplatesSection isScrollable inSidebar={inSidebar}>
					{renderTemplates()}
				</TemplatesSection>
				<Section hasSeparator>
					<FullButtonContainer>
						<ButtonWithTooltip
							appearance="primary"
							id="polaris-ideas.ui.right-sidebar.idea-templates.create-template"
							testId="polaris-ideas.ui.right-sidebar.idea-templates.create-template"
							shouldFitContainer
							onClick={openModal}
							isDisabled={!canManageIdeaTemplates}
							tooltipProps={{
								content: canManageIdeaTemplates
									? null
									: formatMessage(messages.noManagePermissions),
							}}
						>
							<ButtonContent>
								<EditorAddIcon label="create" />
								{formatMessage(messages.newTemplate)}
							</ButtonContent>
						</ButtonWithTooltip>
					</FullButtonContainer>
				</Section>
			</Container>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ inSidebar: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ inSidebar }) =>
		inSidebar
			? 'calc(100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)))'
			: 'auto',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplatesHeader = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: `0px ${token('space.100', '8px')} 0px ${token('space.200', '16px')}`,
	boxShadow: 'inset 0px -2px 0px rgba(9, 30, 66, 0.08)',
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '16px',
	height: '48px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N600),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullButtonContainer = styled.div({
	padding: `14px ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContent = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplatesSection = styled(Section)<{ inSidebar: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ inSidebar }) =>
		inSidebar
			? 'calc( 100vh - (var(--topNavigationHeight, 0px) + var(--bannerHeight, 0px)) - 48px ) !important'
			: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenteredSpinnerWrapper = styled.div({
	margin: '100px auto',
	display: 'flex',
	justifyContent: 'center',
});
