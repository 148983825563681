import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	insightsPlaceholder: {
		id: 'polaris-component-field-configuration.common.insights-placeholder',
		defaultMessage: 'Insights: {label}',
		description: 'Placeholder for insights label',
	},
	dataTypePlaceholder: {
		id: 'polaris-component-field-configuration.common.data-type-placeholder',
		defaultMessage: 'Select the data',
		description: 'Placeholder for dropdown to select type of data to be created',
	},
	createField: {
		id: 'polaris-component-field-configuration.common.create-field',
		defaultMessage: 'Create',
		description: 'Label on button that submits the data to create field',
	},
	cancel: {
		id: 'polaris-component-field-configuration.common.cancel',
		defaultMessage: 'Cancel',
		description: 'Label on button that cancels create field operation',
	},
	dateFieldType: {
		id: 'polaris-component-field-configuration.common.date-field-type',
		defaultMessage: 'Date',
		description: 'Message to describe the field type option as a date field type',
	},
	datetimeFieldType: {
		id: 'polaris-component-field-configuration.common.datetime-field-type',
		defaultMessage: 'Time stamp',
		description: 'Message to describe the field type option as a time stamp field type',
	},
	dropdownFieldType: {
		id: 'polaris-component-field-configuration.common.dropdown-field-type',
		defaultMessage: 'Select',
		description: 'Message to describe the field type option as a dropdown field type',
	},
	labelsFieldType: {
		id: 'polaris-component-field-configuration.common.labels-field-type',
		defaultMessage: 'Labels',
		description: 'Message to describe the field type option as a labels field type',
	},
	atlasGoalFieldType: {
		id: 'polaris-component-field-configuration.common.atlas-goal-field-type',
		defaultMessage: 'Atlas goals',
		description: 'Message to describe the field type option as a Atlas goals field type',
	},
	atlasProjectFieldType: {
		id: 'polaris-component-field-configuration.common.atlas-project-field-type',
		defaultMessage: 'Atlas project',
		description: 'Message to describe the field type option as a Atlas project field type',
	},
	atlasProjectStatusFieldType: {
		id: 'polaris-component-field-configuration.common.atlas-project-status-field-type',
		defaultMessage: 'Atlas project status',
		description: 'Message to describe the field type option as a Atlas project status field type',
	},
	paragraphFieldType: {
		id: 'polaris-component-field-configuration.common.paragraph-field-type',
		defaultMessage: 'Paragraph',
		description: 'Message to describe the field type option as a paragraph field type',
	},
	numberFieldType: {
		id: 'polaris-component-field-configuration.common.number-field-type',
		defaultMessage: 'Number',
		description: 'Message to describe the field type option as a number field type',
	},
	shortTextFieldType: {
		id: 'polaris-component-field-configuration.common.short-text-field-type',
		defaultMessage: 'Short text',
		description: 'Message to describe the field type option as a short text field type',
	},
	checkboxFieldType: {
		id: 'polaris-component-field-configuration.common.checkbox-field-type',
		defaultMessage: 'Multi-select',
		description: 'Message to describe the field type option as a checkbox field type',
	},
	userPickerFieldType: {
		id: 'polaris-component-field-configuration.common.user-picker-field-type',
		defaultMessage: 'People',
		description: 'Message to describe the field type option as a people field type',
	},
	playFieldType: {
		id: 'polaris-component-field-configuration.common.play-field-type',
		defaultMessage: 'Vote',
		description: 'Message to describe the field type option as a vote field type',
	},
	checkboxPresentationFieldType: {
		id: 'polaris-component-field-configuration.common.checkbox-presentation-field-type',
		defaultMessage: 'Checkbox',
		description: 'Message to describe the field type option as a checkbox presentation field type',
	},
	sliderFieldType: {
		id: 'polaris-component-field-configuration.common.slider-field-type',
		defaultMessage: 'Slider',
		description: 'Message to describe the field type option as a slider field type',
	},
	ratingFieldType: {
		id: 'polaris-component-field-configuration.common.rating-field-type',
		defaultMessage: 'Rating',
		description: 'Message to describe the field type option as a rating field type',
	},
	hyperlinkFieldType: {
		id: 'polaris-component-field-configuration.common.hyperlink-field-type',
		defaultMessage: 'Hyperlink',
		description: 'Message to describe the field type option as a hyperlink field type',
	},
	deliveryProgressFieldType: {
		id: 'polaris-component-field-configuration.common.delivery-progress-field-type',
		defaultMessage: 'Delivery progress',
		description: 'Message to describe the field type option as a delivery progress field type',
	},
	deliveryStatusFieldType: {
		id: 'polaris-component-field-configuration.common.delivery-status-field-type',
		defaultMessage: 'Delivery status',
		description: 'Message to describe the field type option as a delivery status field type',
	},
	statusFieldType: {
		id: 'polaris-component-field-configuration.common.status-field-type',
		defaultMessage: 'Status',
		description: 'Message to describe the field type option as a status field type',
	},
	commentsFieldType: {
		id: 'polaris-component-field-configuration.common.comments-field-type',
		defaultMessage: 'Comments',
		description: 'Message to describe the field type option as a comments field type',
	},
	polarisCalculatedFieldType: {
		id: 'polaris-component-field-configuration.common.polaris-calculated-field-type',
		defaultMessage: 'Custom formula',
		description: 'Message to describe the field type option as a polaris calculated field type',
	},
	customFormulaRollupOption: {
		id: 'polaris-component-field-configuration.common.custom-formula-rollup-option',
		defaultMessage: 'Roll-up',
		description: 'Message to describe Roll-up custom formula option type',
	},
	customFormulaWeightedScoreOption: {
		id: 'polaris-component-field-configuration.common.custom-formula-weighted-score-option',
		defaultMessage: 'Weighted score',
		description: 'Message to describe weighted score custom formula option type',
	},
	customFormulaExpressionOption: {
		id: 'polaris-component-field-configuration.common.custom-formula-expression-option',
		defaultMessage: 'Write your own',
		description: 'Menu option describing the ability to write a formula expression as text',
	},
	fieldNameRequiredError: {
		id: 'polaris-component-field-configuration.common.field-name-required-error',
		defaultMessage: 'Field name is required',
		description: 'Message to describe error that field name is required',
	},
	fieldNameMaxLengthError: {
		id: 'polaris-component-field-configuration.common.field-name-max-length-error',
		defaultMessage: 'This field has a 255 characters limit',
		description: 'Message to describe error that field name exceeds max length',
	},
	fieldNameInUseError: {
		id: 'polaris-component-field-configuration.common.field-name-in-use-error',
		defaultMessage: 'This name is already in use, try another.',
		description: 'Message to describe error that field name is in use already',
	},
	fieldTypeRequiredError: {
		id: 'polaris-component-field-configuration.common.field-type-required-error',
		defaultMessage: 'Field type is required',
		description: 'Message to describe error that field type is required',
	},
	fieldGenericError: {
		id: 'polaris-component-field-configuration.common.field-generic-error',
		defaultMessage: 'Failed to create field.',
		description: 'Message to describe error that field creation has failed.',
	},
	dataRequiredError: {
		id: 'polaris-component-field-configuration.common.data-required-error',
		defaultMessage: 'Data is required',
		description: 'Message to describe error that data is required',
	},
	fieldNameTooLongError: {
		id: 'polaris-component-field-configuration.common.field-name-too-long-error',
		defaultMessage: 'Field names are limited to 255 characters',
		description: 'Error message for too long field name',
	},
	customFormulaInputError: {
		id: 'polaris-component-field-configuration.common.custom-formula-input-error',
		defaultMessage: 'This field needs at least one input',
		description: 'Message to describe error that input is missing for custom formula field',
	},
	fieldIsIntegerError: {
		id: 'polaris-component-field-configuration.common.field-is-integer-error',
		defaultMessage: "Field's value must be an integer",
		description: 'Message to describe error that input is missing for custom formula field',
	},
	customFormulaTypeLabel: {
		id: 'polaris-component-field-configuration.common.custom-formula-type-label',
		defaultMessage: 'Custom formula',
		description: 'Message to describe error that input is missing for custom formula field',
	},
	customFormulaDecimalError: {
		id: 'polaris-component-field-configuration.common.custom-formula-decimal-error',
		defaultMessage: "Numbers can't start with dot",
		description:
			'Message to describe the error for a formula containing decimal numbers starting with dot',
	},
	dataTypeLabel: {
		id: 'polaris-component-field-configuration.common.data-type-label',
		defaultMessage: 'Data',
		description: 'Message to describe data type field',
	},
	fieldTypeLabel: {
		id: 'polaris-component-field-configuration.common.field-type-label',
		defaultMessage: '{fieldTypeName} field',
		description: 'Message to describe field type label',
	},
	voteHeader: {
		id: 'polaris-component-field-configuration.common.vote-header',
		defaultMessage: 'Number of votes',
		description: 'Message to describe the settings of a field in the vote type',
	},
	settingsMaxSpendPerUser: {
		id: 'polaris-component-field-configuration.common.settings-max-spend-per-user',
		defaultMessage: 'Votes per user',
		description: 'Label for vote settings field',
	},
	votesMaxNumberTypeError: {
		id: 'polaris-component-field-configuration.common.votes-max-number-type-error',
		defaultMessage: 'You’ve reached the maximum number of votes',
		description: 'Error message for too many votes',
	},
	settingsSave: {
		id: 'polaris-component-field-configuration.common.settings-save',
		defaultMessage: 'Save',
		description: 'Label for settings save button',
	},
	standardFieldGroupType: {
		id: 'polaris-component-field-configuration.common.standard-field-group-type',
		defaultMessage: 'Standard fields',
		description: 'Message to describe the standart fields group',
	},
	dynamicFieldGroupType: {
		id: 'polaris-component-field-configuration.common.dynamic-field-group-type',
		defaultMessage: 'Dynamic fields',
		description: 'Message to describe the dynamic fields group',
	},
	dataFieldGroupType: {
		id: 'polaris-component-field-configuration.common.data-field-group-type',
		defaultMessage: 'Insights',
		description: 'Message to describe the insights fields group',
	},
	intervalFieldType: {
		id: 'polaris-component-field-configuration.common.interval-field-type',
		defaultMessage: 'Date interval',
		description: 'Message to describe the field type option as a date interval field type',
	},
	reactionsFieldType: {
		id: 'polaris-component-field-configuration.common.reactions-field-type',
		defaultMessage: 'Reactions',
		description: 'Message to describe the field type option as reactions field type',
	},
	summaryFieldType: {
		id: 'polaris-component-field-configuration.common.summary-field-type',
		defaultMessage: 'Summary',
		description: 'Message to describe the field type option as summary field type',
	},
	issueKeyFieldType: {
		id: 'polaris-component-field-configuration.common.issue-key-field-type',
		defaultMessage: 'Key',
		description: 'Message to describe the field type option as key field type',
	},
	projectFieldType: {
		id: 'polaris-component-field-configuration.common.project-field-type',
		defaultMessage: 'Project',
		description: 'Message to describe the field type project field type',
	},
	sumType: {
		id: 'polaris-component-field-configuration.common.sum-type',
		defaultMessage: 'Sum',
		description: 'Sum formula type',
	},
	valueType: {
		id: 'polaris-component-field-configuration.common.value-type',
		defaultMessage: 'Value',
		description: 'Value formula type',
	},
	connectionFieldType: {
		id: 'polaris-component-field-configuration.common.connection-field-type',
		defaultMessage: 'Connection',
		description: 'Message to describe the connection field type',
	},
});
