import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';

type RestrictedFieldIndicatorProps = {
	fieldId: string;
};

export const RestrictedFieldIndicator = ({ fieldId }: RestrictedFieldIndicatorProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const openJiraFieldContext = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'fieldContext');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(
				`/secure/admin/ConfigureCustomField!default.jspa?customFieldId=${fieldId}`,
				'_blank',
			);
		},
		[fieldId],
	);

	return (
		<PolarisTooltip
			content={
				<Stack xcss={tooltipContentStyles}>
					<Text>{formatMessage(messages.restrictedContextCantSee)}</Text>
					<Text>
						{formatMessage(messages.restrictedContextAddOrEdit, {
							button: (text: React.ReactNode) => (
								<Button
									appearance="link"
									spacing="none"
									onClick={() =>
										openJiraFieldContext(
											createAnalyticsEvent({
												action: 'clicked',
												actionSubject: 'button',
											}),
										)
									}
								>
									{text}
									<Box aria-hidden as="span" xcss={shortcutIconContainerStyles}>
										<ShortcutIcon size="small" label="" />
									</Box>
								</Button>
							),
						})}
					</Text>
				</Stack>
			}
		>
			{(tooltipProps) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Box {...tooltipProps} xcss={warningIconContainerStyles}>
					<Box xcss={warningIconStyles}>
						<WarningIcon
							testId="polaris-lib-restricted-field.ui.restricted-field-indicator.warning-icon"
							primaryColor={token('color.icon.warning')}
							label={formatMessage(messages.restrictedContextCantSee)}
						/>
					</Box>
				</Box>
			)}
		</PolarisTooltip>
	);
};

const tooltipContentStyles = xcss({
	paddingInline: 'space.100',
});

const shortcutIconContainerStyles = xcss({
	marginLeft: 'space.025',
	display: 'inline-flex',
	verticalAlign: 'text-bottom',
});

const warningIconContainerStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
	flexGrow: '1',
	flexShrink: '0',
});

const warningIconStyles = xcss({
	cursor: 'pointer',
});
