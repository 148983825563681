import gqlTagPolaris from 'graphql-tag';
import { createAri } from '@atlassian/jira-platform-ari';
import type {
	SnippetData,
	SnippetAction,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { InvokePolarisObject, InvokePolarisObjectResponse } from './types';

export const INVOKE_OBJECT_MUTATION = gqlTagPolaris`
mutation jira_polaris_InvokePolarisObject($input: InvokePolarisObjectInput!) {
  invokePolarisObject(input: $input) {
    success
    errors {
      message
    }
    response {
      statusCode
      body
        externalAuth {
          url
          key
          displayName
        }
       auth {
        type
        hint
      }
      oauthClientId
    }
  }
}
`;

export const invokeObject = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	issueId: string,
	resourceUrl: string,
	oauthClientId: string,
	data: SnippetData,
	action: SnippetAction,
	authToken?: string,
): Promise<InvokePolarisObjectResponse> => {
	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId,
	});

	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

	return client
		.mutate<InvokePolarisObject>({
			mutation: INVOKE_OBJECT_MUTATION,
			variables: {
				input: {
					issue: issueAri.toString(),
					project: projectAri.toString(),
					action,
					oauthClientId,
					data,
					resourceUrl,
					authToken,
				},
			},
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-insights.invoke-object-error:${result.errors.map((e) => e.message).join(', ')}`,
				);
			}

			if (
				!result.data ||
				!result.data.invokePolarisObject ||
				!result.data.invokePolarisObject.response
			) {
				throw new Error('polaris-insights.invoke-object-error: no data or no data node');
			}

			return result.data.invokePolarisObject.response;
		});
};
