import React, { useCallback } from 'react';
import ButtonOld from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { fg } from '@atlassian/jira-feature-gating';
import noResults from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { LinkPressable } from '@atlassian/jira-polaris-lib-link-pressable/src/ui/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useFieldsTableActions } from '../../../../../../../../controllers/fields-table';
import messages from './messages';

export const EmptySearchScreen = () => {
	const { formatMessage } = useIntl();
	const { clearFilters } = useFieldsTableActions();

	const handleClearFiltersClick = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'clearFilters');
			clearFilters();
		},
		[clearFilters],
	);

	return (
		<EmptyState
			header={formatMessage(messages.emptySearchHeader)}
			renderImage={getAkEmptyStateRenderImageFn(noResults)}
			maxImageWidth={120}
			description={formatMessage(messages.emptySearchDescription)}
			primaryAction={
				fg('polaris_global_fields_button_migration') ? (
					<LinkPressable
						onClick={handleClearFiltersClick}
						label={formatMessage(messages.clearFilters)}
					/>
				) : (
					<ButtonOld appearance="link" onClick={handleClearFiltersClick}>
						{formatMessage(messages.clearFilters)}
					</ButtonOld>
				)
			}
		/>
	);
};
