import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { CONTAINER } from '../../../../common/constants';
import type { RankViewRequest } from '../../../../common/types/remote';
import type { NavigationView, Props, State } from '../../types';
import { withRefreshBlocker } from '../refresh-blocker';
import { findView, updateProjectState } from '../utils';

type RankPayload = {
	id: string;
	parentId?: string;
	srcParentId?: string;
	index: number;
	isExpandedBeforeDrag: boolean;
};

export const rankView = (
	rankPayload: RankPayload,
	onActionSuccess?: () => void,
	onActionError?: (err?: Error) => void,
) =>
	withRefreshBlocker(
		async (
			{ getState, setState }: StoreActionApi<State>,
			{ projectId, cloudId, navigationRemote, onFailure, onSuccess }: Props,
		) => {
			if (
				!cloudId ||
				!projectId ||
				!navigationRemote ||
				getState().projects[projectId]?.isLoading ||
				!getState().projects[projectId]?.initialized
			) {
				return;
			}

			const { id, index, parentId } = rankPayload;

			if (!parentId) {
				return;
			}

			experience.navBar.changeViewRank.start();

			// Save the original views to revert the changes in case of failure
			const previousViewsState = getState().projects[projectId].views;

			try {
				const { view: viewToMove, parent: viewToMoveParent } = findView(
					getState().projects[projectId],
					id,
				);

				if (!viewToMove) {
					return;
				}

				const newViews = previousViewsState
					.filter((view) => view.localId !== id)
					.map((view) => {
						if (viewToMoveParent && viewToMoveParent.localId === view.localId) {
							return {
								...view,
								views: view.views?.filter((v) => v.localId !== id),
							};
						}
						return view;
					});

				const viewToInsert = { ...viewToMove, isExpanded: rankPayload.isExpandedBeforeDrag };

				let moreAfterView: NavigationView | undefined;
				let moreBeforeView;
				let moveInEmptySet = false;
				if (parentId === 'root') {
					moreAfterView = newViews[index - 1];
					moreBeforeView = index === 0 ? newViews[index] : undefined;
					newViews.splice(index, 0, viewToInsert);
				} else {
					newViews.map((view) => {
						if (view.localId === parentId) {
							if (!view.views?.length) {
								moveInEmptySet = true;
								if (view.views === undefined) {
									Object.assign(view, {
										views: [],
									});
								}
							} else {
								moreAfterView = view.views?.[index - 1];
								moreBeforeView = index === 0 ? view.views?.[index] : undefined;
							}
							view.views?.splice(index, 0, viewToInsert);
							return { ...view };
						}
						return view;
					});
				}

				setState(updateProjectState(getState(), projectId, { views: newViews, isLocked: true }));

				const parent =
					parentId === 'root'
						? projectId
						: newViews.find((view) => {
								if (view.localId === parentId) {
									return view;
								}
								return undefined;
							})?.id;

				if (!parent || (!moreAfterView && !moreBeforeView && !moveInEmptySet)) {
					return;
				}

				const rankViewRequest: RankViewRequest = {
					id: viewToMove.id,
					parentType: parentId === 'root' ? CONTAINER.PROJECT : CONTAINER.SECTION,
					parentId: parent,
					moveAfter: undefined,
					moveBefore: undefined,
				};

				if (moreAfterView) {
					rankViewRequest.moveAfter = {
						id: moreAfterView.id,
						viewType: moreAfterView.viewType,
					};
				} else {
					delete rankViewRequest.moveAfter;
				}

				if (moreBeforeView) {
					rankViewRequest.moveBefore = {
						id: moreBeforeView.id,
						viewType: moreBeforeView.viewType,
					};
				} else {
					delete rankViewRequest.moveBefore;
				}

				await navigationRemote?.rankView(rankViewRequest);

				setState(updateProjectState(getState(), projectId, { isLocked: false }));

				onSuccess?.('rankView');

				experience.navBar.changeViewRank.success();

				onActionSuccess?.();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				// Reverse the changes in case of failure
				setState(
					updateProjectState(getState(), projectId, { views: previousViewsState, isLocked: false }),
				);

				if (error instanceof Error) {
					onActionError?.(error);
				}

				onFailure?.(error, 'rankView');

				if (isClientFetchError(error)) {
					experience.navBar.changeViewRank.abort(error);
					return;
				}

				experience.navBar.changeViewRank.failure(error);
			}
		},
	);
