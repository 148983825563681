import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { useFieldEmoji } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks.tsx';
import {
	PolarisEnvironmentContainerTypes,
	useEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { Link } from '@atlassian/react-resource-router';

type LinkedIssueCardProps = {
	issue: {
		key: string;
		summary: string;
		issueType?: {
			iconUrl: string;
			name: string;
		};
	};
	fieldKey: string;
	isLink?: boolean;
};

export const LinkedIssueCard = ({ issue, isLink = true, fieldKey }: LinkedIssueCardProps) => {
	const container = useEnvironmentContainer();
	const currentProjectKey =
		container?.type === PolarisEnvironmentContainerTypes.PROJECT ? container.projectKey : undefined;
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	const card = (
		<Inline alignBlock="center" space="space.050" xcss={cardStyles}>
			{emoji ? (
				<Emoji emoji={emoji} fitToHeight={16} />
			) : (
				<img
					width={16}
					height={16}
					src={issue.issueType?.iconUrl || ''}
					alt={issue.issueType?.name || ''}
				/>
			)}
			<Box xcss={summaryStyles}>
				<Text color="color.text.selected">{issue.summary}</Text>
			</Box>
		</Inline>
	);

	if (!isLink) {
		return card;
	}

	const projectKey = issue.key.split('-')[0];
	const isSameProject = currentProjectKey && projectKey === currentProjectKey;
	const link = isSameProject
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.pathname}?selectedIssue=${issue.key}&issueViewLayout=sidebar&issueViewSection=overview`
		: `/browse/${issue.key}`;

	return (
		<Link href={link} target={isSameProject ? undefined : '_blank'}>
			{card}
		</Link>
	);
};

const cardStyles = xcss({
	width: 'fit-content',
	maxWidth: '100%',
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: '3px',
	paddingBlock: 'space.025',
	paddingInlineStart: 'space.050',
	paddingInlineEnd: 'space.100',
	backgroundColor: 'elevation.surface',
});

const summaryStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
