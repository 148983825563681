import React, { type ReactElement } from 'react';
import type { Appearance } from '@atlaskit/button';
import { ARTICLE_TYPE } from '@atlaskit/help';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
} from '@atlassian/jira-help-panel-common-constant/src/index.tsx';
import { useHelpPanelActions } from '@atlassian/jira-help-panel-services/src/components/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useRightSidebarController } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { LazyNonBlockingSpotlight } from '@atlassian/jira-polaris-lib-non-blocking-spotlight/src/async';
import type { NonBlockingSpotlightProps } from '@atlassian/jira-polaris-lib-non-blocking-spotlight/src/ui/spotlight/index.tsx';
import {
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

export type Props = {
	children: ReactElement;
	title: string;
	description: string;
	articleId?: string;
	placement?: NonBlockingSpotlightProps['placement'];
	targetRadius?: number;
	isActive: boolean;
	mode?: NonBlockingSpotlightProps['mode'];
	leftShift?: number;
	learnMoreText?: string;
	onClose: () => void;
};

const LearnMoreText = ({ text }: { text?: string }) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<span aria-hidden={!!text}>{formatMessage(messages.learnMoreButton)}</span>
			<ScreenReaderText>{text}</ScreenReaderText>
		</>
	);
};

export const Spotlight = ({
	title,
	description,
	children,
	articleId,
	placement,
	targetRadius = 3,
	isActive,
	learnMoreText,
	mode,
	leftShift,
	onClose,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { openRightSidebar } = useRightSidebarController();
	const [, { setNavigationData }] = useHelpPanelActions();

	const handleLearnMoreClick = () => {
		if (!articleId) {
			return;
		}

		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'spotlightLearnMore');

		openRightSidebar(HELP_PANEL_ID, HELP_PANEL_WIDTH, false, true);
		setNavigationData({
			articleId: {
				id: articleId,
				type: ARTICLE_TYPE.HELP_ARTICLE,
			},
		});

		onClose();
	};

	const handleGotItClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'spotlightGotIt');
		onClose();
	};

	const handleTargetClick = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'spotlightTarget');
		onClose();
	};

	if (!isActive) {
		return children;
	}

	return (
		<Placeholder name="spotlight" fallback={children}>
			<FireScreenAnalytics />
			<LazyNonBlockingSpotlight
				target={children}
				targetRadius={targetRadius}
				pulse
				width={350}
				heading={title}
				placement={placement}
				actions={[
					{
						text: formatMessage(messages.gotItButton),
						onClick: handleGotItClick,
					},
					...(articleId
						? [
								{
									text: <LearnMoreText text={learnMoreText} />,
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									appearance: 'subtle' as Appearance,
									onClick: handleLearnMoreClick,
								},
							]
						: []),
				]}
				onTargetClick={handleTargetClick}
				mode={mode}
				leftShift={leftShift}
			>
				{description}
			</LazyNonBlockingSpotlight>
		</Placeholder>
	);
};
