import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useLinkedIssueDataByLinkedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/linked-issues/hooks';
import { LinkedIssueCard } from '@atlassian/jira-polaris-common/src/ui/linked-issue-card';
import { NoValueContainer } from '../../../../common/board-header-column/styled';
import type {
	ColumnHeaderComponentProps,
	ColumnHeaderConfiguration,
	EmptyColumnHeaderComponentProps,
} from '../types';
import messages from './messages';

const Component = ({ groupIdentity, fieldKey }: ColumnHeaderComponentProps) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(parseInt(groupIdentity, 10));

	if (linkedIssue === undefined) {
		return null;
	}

	return (
		<Box padding="space.025">
			<LinkedIssueCard
				issue={{
					key: linkedIssue.issueKey,
					summary: linkedIssue.summary,
					issueType: {
						iconUrl: linkedIssue.issueType.iconUrl,
						name: linkedIssue.issueType.name,
					},
				}}
				fieldKey={fieldKey}
			/>
		</Box>
	);
};

const EmptyComponent = ({ isRow }: EmptyColumnHeaderComponentProps) => {
	const { formatMessage } = useIntl();

	return (
		<NoValueContainer isRow={isRow}>
			{formatMessage(messages.emptyColumnHeaderNonFinal)}
		</NoValueContainer>
	);
};

export const connectionColumnHeaderConfig: ColumnHeaderConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
	EmptyComponent,
};
