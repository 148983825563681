import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ArchivedFilter } from '../../../common/types';
import type { JpdJiraSearchApiPublicIssue } from '../types';
import { translateToPublicIssue } from '../util';
import type { JpdJiraSearchApiResponse, JpdJiraIssueApiResponse } from './types';

const POLARIS_SEARCH_URL = '/rest/polaris/issues/search';
const POLARIS_ISSUE_URL = '/rest/polaris/issues/get';

type GetJpdIssuesProps = {
	projectId: string;
	issueIdsOrKeys?: string[];
	fields?: string[];
	archivedFilter?: ArchivedFilter;
	maxResults?: number;
	startAt?: number;
};

export const getJpdIssues = ({
	projectId,
	issueIdsOrKeys,
	fields,
	archivedFilter,
	maxResults,
	startAt,
}: GetJpdIssuesProps): Promise<{
	total: number;
	issues: JpdJiraSearchApiPublicIssue[];
}> =>
	fetchJson(POLARIS_SEARCH_URL, {
		method: 'POST',
		body: JSON.stringify({
			projectId,
			fields: fg('jpd_issues_relationships')
				? [...new Set([...(fields || []), 'issuelinks'])]
				: fields,
			includeProjectData: true,
			archived: archivedFilter,
			maxResults,
			startAt,
			...(issueIdsOrKeys ? { issueIdsOrKeys } : {}),
			includeAllFields: fg('jpd_issues_relationships'),
		}),
	}).then((response: JpdJiraSearchApiResponse) => ({
		total: response.issues.length,
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
	}));

export const getJpdIssue = (
	projectId: string,
	issueIdOrKey: string,
	fields: string[],
): Promise<JpdJiraSearchApiPublicIssue> =>
	fetchJson(POLARIS_ISSUE_URL, {
		method: 'POST',
		body: JSON.stringify({
			projectId,
			issueIdOrKey,
			fields: [...new Set([...fields, 'description', 'comment', 'issuelinks', 'project'])],
			includeAllFields: fields.length === 0,
			includeProjectData: true,
		}),
	}).then((response: JpdJiraIssueApiResponse) => translateToPublicIssue(response, response.issue));
