import gqlTagPolaris from 'graphql-tag';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

export const CREATE_LIST_MUTATION = gqlTagPolaris`
mutation jira_polaris_CreateList(
    $listId: ID!,
    $items: [String!],
 ) {
  polaris {
    ranking {
      createList(
        input: {
          listId: $listId
          items: $items
        }
      ) {
        changed {
          id
          rank
        }
        added {
          id
          rank
        }
        deleted {
          id
          rank
        }
      }
    }
  }
}
`;

type CreateRankingProps = {
	listId: Ari;
	items: string[];
};

export const createCreateRanking =
	(client: PolarisApolloClient): (({ listId, items }: CreateRankingProps) => Promise<void>) =>
	({ listId, items }) => {
		if (fg('polaris_pol-11287-fix-reordering-error')) {
			if (items.length === 0) {
				return Promise.resolve();
			}
		}

		return client
			.mutate({
				mutation: CREATE_LIST_MUTATION,
				variables: {
					listId,
					items,
				},
				errorPolicy: 'all',
			})
			.then((result) => {
				if (result.errors !== undefined) {
					throw new Error(
						`ranking-service.create-list-error:${result.errors.map((e) => e.message).join(', ')}`,
					);
				}
			});
	};
