import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { PostCommentResponse } from '../types';
import { getIssueCommentApiUrl } from '../utils';

export const postCommentToJira = (
	issueIdOrKey: string,
	comment: ADF,
): Promise<PostCommentResponse> =>
	performPostRequest(getIssueCommentApiUrl(issueIdOrKey), {
		body: JSON.stringify({ body: comment }),
	});
