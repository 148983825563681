import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { isFocusOutsideJFE } from '@atlassian/jira-polaris-lib-outside-click-alerter';
import { POLARIS_SIDEBAR_TEST_ID } from '../sidebar';

export const shouldPreventClosing = (target?: EventTarget | null) => {
	if (target instanceof HTMLElement) {
		// checking if resizing/opening/closing area of the left sidebar is focused
		const isFocusOnTheRightSidebarResizableHandler =
			target?.dataset?.testid === `${POLARIS_SIDEBAR_TEST_ID}-grab-area` ||
			(target?.parentElement instanceof HTMLElement &&
				target.parentElement.dataset?.testid === `${POLARIS_SIDEBAR_TEST_ID}-resize-button`);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const isFocusInJiraRightSidebar = document
			.querySelector('[data-ds--page-layout--slot="right-sidebar"]')
			?.contains(target);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const isFocusInJiraTopNavigation = document
			.querySelector('[data-ds--page-layout--slot="top-navigation"]')
			?.contains(target);

		const isFocusInJiraNavV4 =
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			getWillShowNav4() && document.querySelector('nav[data-layout-slot="true"]')?.contains(target);

		const isFocusInJiraHeaderNavV4 =
			getWillShowNav4() &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.querySelector('header[data-layout-slot="true"]')?.contains(target);

		return (
			isFocusOutsideJFE(target) ||
			isFocusOnTheRightSidebarResizableHandler ||
			isFocusInJiraRightSidebar ||
			isFocusInJiraTopNavigation ||
			isFocusInJiraNavV4 ||
			isFocusInJiraHeaderNavV4
		);
	}
	return false;
};
