import mapValues from 'lodash/mapValues';
import { formatISO, setDate } from 'date-fns';
import type { RawItem } from '../../../../common/types/timeline/index.tsx';
import { getMiddleOfTheMonthDate } from '../../../../common/utils';
import {
	type GroupedItemArrangement,
	type GroupId,
	type GroupToItems,
	type ItemId,
	NO_VALUE_GROUP_ID,
} from '../../../../types';
import type { Item } from '../../../types';
import { updateRowArrangement, calculateItemArrangement } from '../../../utils/arrangement';

export type UpdatedItem = {
	id: ItemId;
	rowIndex: number;
	colIndex: number;
	size: number;
	groupId?: GroupId;
	isStartExternal?: boolean;
	isEndExternal?: boolean;
};

export const getUpdatedGroupToItems = (
	itemUpdate: UpdatedItem,
	oldGroupToItems: GroupToItems,
): GroupToItems => {
	if (
		!itemUpdate.groupId ||
		(oldGroupToItems[itemUpdate.groupId] !== undefined &&
			oldGroupToItems[itemUpdate.groupId].includes(itemUpdate.id))
	) {
		return oldGroupToItems;
	}
	return mapValues(oldGroupToItems, (itemIds, groupId) => {
		if (groupId === itemUpdate.groupId) {
			return [...itemIds, itemUpdate.id];
		}
		return itemIds.filter((id) => id !== itemUpdate.id);
	});
};

const toItem = (itemUpdate: UpdatedItem): RawItem => ({
	id: itemUpdate.id,
	start: itemUpdate.colIndex,
	size: itemUpdate.size || 1,
	isStartExternal: itemUpdate.isStartExternal,
	isEndExternal: itemUpdate.isEndExternal,
});

const getUpdatedItems = (items: RawItem[], itemUpdate: UpdatedItem): RawItem[] => {
	if (items.findIndex((item) => item.id === itemUpdate.id) >= 0) {
		return items.map((item) => (itemUpdate.id === item.id ? toItem(itemUpdate) : item));
	}
	return [...items, toItem(itemUpdate)];
};

export const calculateUpdatedItemArrangement = (
	itemUpdate: UpdatedItem,
	isInNewRow: boolean,
	oldItemArrangement: GroupedItemArrangement,
	oldItems: Item[],
	oldGroupToItems: GroupToItems | undefined,
): GroupedItemArrangement => {
	const updatedItems = getUpdatedItems(oldItems, itemUpdate);
	if (oldGroupToItems === undefined) {
		return {
			[NO_VALUE_GROUP_ID]: calculateItemArrangement(
				updatedItems,
				updateRowArrangement(
					oldItemArrangement[NO_VALUE_GROUP_ID] || {},
					itemUpdate.id,
					itemUpdate.rowIndex,
					isInNewRow,
				),
			),
		};
	}
	const updatedGroupToItems = getUpdatedGroupToItems(itemUpdate, oldGroupToItems);
	const newArrangement = mapValues(
		updatedGroupToItems,
		(_, groupId) => oldItemArrangement[groupId] || {},
	);
	return mapValues(newArrangement, (rowArrangement, groupId) => {
		const selectedItems = updatedItems.filter((item) =>
			(updatedGroupToItems[groupId] || []).includes(item.id),
		);
		return calculateItemArrangement(
			selectedItems,
			groupId === itemUpdate.groupId
				? updateRowArrangement(rowArrangement, itemUpdate.id, itemUpdate.rowIndex, isInNewRow)
				: rowArrangement,
		);
	});
};

export const middleOfMonthEnd = (date: Date) => setDate(date, getMiddleOfTheMonthDate(date)[1]);
export const middleOfMonthStart = (date: Date) => setDate(date, getMiddleOfTheMonthDate(date)[0]);

export const formatIsoLocalDate = (date: Date) => formatISO(date, { representation: 'date' });

export const transformToIntervalModel = (startDateValue: Date, endDateValue: Date) => ({
	start: formatIsoLocalDate(startDateValue),
	end: formatIsoLocalDate(endDateValue),
});
