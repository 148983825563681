import React, { type SyntheticEvent } from 'react';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import AddIcon from '@atlaskit/icon/utility/add';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';

const PlusIcon: IconButtonProps['icon'] = () => {
	const { formatMessage } = useIntl();

	return (
		<AddIcon
			label={formatMessage(messages.createIdea)}
			color="currentColor"
			LEGACY_size="medium"
			LEGACY_fallbackIcon={EditorAddIcon}
		/>
	);
};

export type SummaryAddIdeaProps = {
	onClick: () => void;
};

export const SummaryColumnAddIdea = ({ onClick }: SummaryAddIdeaProps) => {
	const { formatMessage } = useIntl();

	return (
		<IconButton
			icon={PlusIcon}
			isTooltipDisabled={false}
			label={formatMessage(messages.createIdea)}
			spacing="compact"
			onClick={(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'summaryAddIdea');

				sendPendoTrackEvent({
					actionSubjectAndAction: 'summaryAddIdea clicked',
					actionSubjectId: 'summaryAddIdea',
				});

				onClick();
			}}
		/>
	);
};
