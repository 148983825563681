/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import messages from './messages';

type AnyoneElseAccessListItemProps = {
	accessLevel: ViewAccessLevel;
};

export const AnyoneElseAccessListItem = ({ accessLevel }: AnyoneElseAccessListItemProps) => {
	const { formatMessage } = useIntl();

	return (
		<Flex
			gap="space.100"
			alignItems="center"
			testId="polaris-component-view-access.ui.access-screen.access-user-list.anyone-else-access-list-item.item-container"
		>
			<Flex alignItems="center" justifyContent="center" xcss={teamsIconWrapperStyles}>
				<TeamsIcon label="" color={token('color.icon')} />
			</Flex>
			<Stack grow="fill">
				<Text weight="medium">{formatMessage(messages.anyoneElseInThisProjectLabel)}</Text>
			</Stack>
			<Box paddingInline="space.100" xcss={roleContainerStyles}>
				{accessLevel === VIEW_ACCESS_LEVELS.LIMITED && formatMessage(messages.viewOnlyLabel)}
				{accessLevel === VIEW_ACCESS_LEVELS.RESTRICTED && formatMessage(messages.noAccessLabel)}
			</Box>
			<Box xcss={removePrincipalContainerStyles} />
		</Flex>
	);
};

const teamsIconWrapperStyles = xcss({
	width: '32px',
	height: '32px',
	borderRadius: '50%',
	backgroundColor: 'color.background.accent.gray.subtlest',
	flexShrink: 0,
	margin: 'space.025',
});

const roleContainerStyles = xcss({
	flexShrink: '0',
	width: '120px',
});

const removePrincipalContainerStyles = xcss({
	flexShrink: '0',
	width: '80px',
});
