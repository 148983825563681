import React, { type MouseEvent, type KeyboardEvent } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export type ReorderAction = 'MOVE_TO_TOP' | 'MOVE_UP' | 'MOVE_DOWN' | 'MOVE_TO_BOTTOM';

export type ItemPosition = 'FIRST' | 'LAST' | 'MIDDLE';

type DragHandleProps = {
	position: ItemPosition;
	onReorder: (reorderAction: ReorderAction) => void;
};

export const DragHandle = (props: DragHandleProps) => {
	const { position, onReorder } = props;
	const { formatMessage } = useIntl();

	const isMoveUpDisabled = position === 'FIRST';
	const isMoveDownDisabled = position === 'LAST';

	return (
		<Box
			onClick={(event: MouseEvent) => event.stopPropagation()}
			onKeyDown={(event: KeyboardEvent) => event.stopPropagation()}
		>
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, ...triggerProps }) => (
					<DragHandleButtonSmall
						appearance="subtle"
						ref={triggerRef}
						{...triggerProps}
						type="button"
						label={formatMessage(messages.reorderLabel)}
					/>
				)}
			>
				<DropdownItemGroup>
					<DropdownItemGroup>
						<DropdownItem isDisabled={isMoveUpDisabled} onClick={() => onReorder('MOVE_TO_TOP')}>
							{formatMessage(messages.moveToTop)}
						</DropdownItem>
						<DropdownItem isDisabled={isMoveUpDisabled} onClick={() => onReorder('MOVE_UP')}>
							{formatMessage(messages.moveUp)}
						</DropdownItem>
						<DropdownItem isDisabled={isMoveDownDisabled} onClick={() => onReorder('MOVE_DOWN')}>
							{formatMessage(messages.moveDown)}
						</DropdownItem>
						<DropdownItem
							isDisabled={isMoveDownDisabled}
							onClick={() => onReorder('MOVE_TO_BOTTOM')}
						>
							{formatMessage(messages.moveToBottom)}
						</DropdownItem>
					</DropdownItemGroup>
				</DropdownItemGroup>
			</DropdownMenu>
		</Box>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DragHandleMock } from './mocks';
