import React from 'react';
import { CollabContainer as CollabContainerClient } from './main';
import type { Props } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { usePresence } from './selectors/hooks';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useActions } from './main';

const NoOpCollabContainer = ({ children }: Props) => <>{children}</>;

// @atlaskit/collab-provider works only client-side and must never be used in a SSR environment
export const CollabContainer = __SERVER__ ? NoOpCollabContainer : CollabContainerClient;
