import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useLinkedIssueDataByLinkedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/linked-issues/hooks';
import { LinkedIssueCard } from '@atlassian/jira-polaris-common/src/ui/linked-issue-card';
import type { FieldValueComponentProps, FieldValueConfiguration } from '../types';
import messages from './messages';

const Component = ({ groupIdentity, fieldKey }: FieldValueComponentProps) => {
	const linkedIssue = useLinkedIssueDataByLinkedIssueId(parseInt(groupIdentity, 10));

	if (linkedIssue === undefined) {
		return null;
	}

	return (
		<Box padding="space.025">
			<LinkedIssueCard
				issue={{
					key: linkedIssue.issueKey,
					summary: linkedIssue.summary,
					issueType: {
						iconUrl: linkedIssue.issueType.iconUrl,
						name: linkedIssue.issueType.name,
					},
				}}
				fieldKey={fieldKey}
				isLink={false}
			/>
		</Box>
	);
};

const EmptyComponent = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingInlineStart="space.050">{formatMessage(messages.emptyColumnHeaderNonFinal)}</Box>
	);
};

export const connectionFieldValueConfig: FieldValueConfiguration = {
	isHideable: true,
	isDecoratable: false,
	Component,
	EmptyComponent,
};
