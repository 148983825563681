import React, { useCallback, useState, memo } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import AvatarGroup from '@atlaskit/avatar-group';
import Button from '@atlaskit/button';
import Popup from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FormattedDate } from '@atlassian/jira-polaris-lib-date-time/src/index.tsx';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { ProfileCard } from '@atlassian/jira-profilecard-next/src/main.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { messages } from './messages';
import { useVisitors } from './utils';

export const VisitorsInternal = (props: { testId?: string }) => {
	const { formatMessage } = useIntl();
	const [isOpen, setOpen] = useState(false);
	const [visitors, avatars] = useVisitors();

	const visitorPopupTrigger = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'triggerProps' implicitly has an 'any' type.
		(triggerProps) => (
			<ButtonWrapper>
				<Button
					onClick={() => setOpen(true)}
					{...triggerProps}
					testId={props.testId && `${props.testId}.button`}
					iconAfter={
						<AvatarGroupWrapper>
							<AvatarGroup
								size="small"
								maxCount={5}
								appearance="stack"
								// @ts-expect-error - TS2322 - Type 'Avatar[]' is not assignable to type 'AvatarProps[]'.
								data={avatars}
								isTooltipDisabled
								onMoreClick={noop}
								testId="polaris-ideas.ui.view-header.visitors.avatar-group"
							/>
						</AvatarGroupWrapper>
					}
				/>
			</ButtonWrapper>
		),
		[avatars, props.testId],
	);

	const visitorPopupContent = useCallback(
		() => (
			<VisitorPopupContainer>
				{/* eslint-disable-next-line @atlaskit/design-system/use-heading */}
				<h4>
					{formatMessage(messages.heading)} ({visitors.length})
				</h4>
				{visitors.map((visitor) => {
					const content = (
						<VisitorPopupItemWrapper>
							<Visitor>
								<Avatar src={visitor.avatar} size="small" presence={visitor.presence} />
								<span>{visitor.name ?? formatMessage(messages.anonymous)}</span>
							</Visitor>
							<VisitDate>
								<FormattedDate date={visitor.timestamp?.getTime() ?? Date.now()} />
							</VisitDate>
						</VisitorPopupItemWrapper>
					);

					return visitor.accountId == null || visitor.name == null ? (
						content
					) : (
						<ProfileCard
							key={visitor.accountId}
							actions={[VIEW_PROFILE_ACTION]}
							accountId={visitor.accountId}
							TenantContextSubscriber={TenantContextSubscriber}
						>
							{content}
						</ProfileCard>
					);
				})}
			</VisitorPopupContainer>
		),
		[formatMessage, visitors],
	);

	if (visitors.length === 0) {
		return <></>;
	}

	return (
		<Popup
			shouldUseCaptureOnOutsideClick
			placement="bottom-start"
			isOpen={isOpen}
			onClose={() => setOpen(false)}
			content={visitorPopupContent}
			trigger={visitorPopupTrigger}
		/>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Visitors = memo<Record<any, any>>(VisitorsInternal);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisitorPopupContainer = styled.div({
	display: 'flex',
	padding: `${token('space.250', '20px')} 0`,
	flexDirection: 'column',
	width: '352px',
	maxHeight: '340px',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > h4': {
		textTransform: 'uppercase',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N200),
		font: token('font.body.UNSAFE_small'),
		padding: `0 ${token('space.300', '24px')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisitorPopupItemWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginTop: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	padding: `0 ${token('space.300', '24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child': {
		marginTop: token('space.150', '12px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Visitor = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginLeft: '10px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisitDate = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	zIndex: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		background: token('elevation.surface', 'white'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarGroupWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a:hover': {
		'&:active': {
			transform: 'none',
		},
		'&::after': {
			backgroundColor: 'transparent',
		},
	},
});
