import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Ari } from '@atlassian/jira-platform-ari';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { createField } from '../../services/jira/create-field';
import { deleteField as deleteJiraField } from '../../services/jira/delete-field';
import { updatePolarisFieldConfiguration } from '../../services/jira/update-configuration';
import { updateField as updateFieldJira } from '../../services/jira/update-field';
import { deletePlay } from '../../services/polaris-api/delete-play';
import { updatePlayField } from '../../services/polaris-api/update-play-field';
import { SharedViewAuthenticationMissingError, SharedViewFieldsUnavailableError } from '../utils';
import { createVotesFieldWithPlay } from './jira/create/index.tsx';
import { fetchFields, fetchSelectedFields, fetchFieldsWithJql } from './jira/fetch/index.tsx';
import { transformResponse } from './jira/transform/index.tsx';
import { fetchFieldsFromViewsService } from './jpd-views-service';
import type { FieldCrudRemote } from './types';

export const createNotImplementedFieldCrudRemote = (): FieldCrudRemote => ({
	deleteField: notImplementedError('deleteField'),

	createField: notImplementedError('createField'),
	createCalculatedField: notImplementedError('createCalculatedField'),
	createPlayField: notImplementedError('createPlayField'),

	updateFieldConfiguration: notImplementedError('updateFieldConfiguration'),
	updateField: notImplementedError('updateField'),

	updatePlayField: notImplementedError('updatePlayField'),

	fetchAllFields: notImplementedError('fetchAllFields'),
	fetchFields: notImplementedError('fetchFields'),
});

export const createJiraFieldCrudRemote = (
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	projectId: ProjectId,
	projectAri: Ari,
): FieldCrudRemote => ({
	deleteField: async ({ fieldKey, deletePlayId }) => {
		await deleteJiraField(projectId, fieldKey);

		if (deletePlayId !== undefined) {
			await deletePlay(deletePlayId);
		}
	},
	createCalculatedField: ({ issueTypeId, ...request }) =>
		createField(projectId, issueTypeId, request.label, FIELD_TYPES.FORMULA, {
			polarisFormula: request.formula,
		}).then((jiraField) => transformResponse([jiraField], { [jiraField.key]: [issueTypeId] })),
	createPlayField: (request) => createVotesFieldWithPlay(apolloClient, request),
	createField: async ({ name, type, jiraIssueTypeId, configuration }) =>
		createField(projectId, jiraIssueTypeId, name, type, configuration).then((jiraField) =>
			transformResponse([jiraField], { [jiraField.key]: [jiraIssueTypeId] }),
		),
	updateFieldConfiguration: (request) =>
		updatePolarisFieldConfiguration(
			request.fieldKey,
			request.oldConfiguration,
			request.configuration,
			projectId,
			request.issueTypeId,
		),
	updateField: ({ fieldKey, issueTypeId, description, name }) =>
		updateFieldJira({ projectId, fieldKey, issueTypeId, description, name }).then(() => ({
			description,
			name,
		})),
	updatePlayField: (request) => updatePlayField(apolloClient, request),
	fetchFields: (fieldKeys) => fetchSelectedFields(projectId, fieldKeys),
	fetchAllFields: () => fetchFields(apolloClient, createAnalyticsEvent, projectId, projectAri),
});

export const createSharingFieldCrudRemote = (viewId: Ari): FieldCrudRemote => ({
	...createNotImplementedFieldCrudRemote(),
	fetchAllFields: () =>
		fetchFieldsFromViewsService(viewId).catch((error) => {
			if (error instanceof FetchError) {
				switch (error.statusCode) {
					case 404:
					case 403:
						throw new SharedViewFieldsUnavailableError(error.statusCode, error.message);
					case 401:
						throw new SharedViewAuthenticationMissingError();
					default:
				}
			}

			throw error;
		}),
});

export const createJqlFieldCrudRemote = (jql: string): FieldCrudRemote => ({
	...createNotImplementedFieldCrudRemote(),
	fetchAllFields: () => fetchFieldsWithJql(jql),
});
