import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import { B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { createRaf } from '../../../common/ui/create-request-animation-frame';

export type ResizeCallback = (width: number, delta: number, e: MouseEvent) => void;

export type OnStartCallback = (e: MouseEvent) => void;

const requestAnimationFrame = createRaf();

export const Resizer = ({
	minWidth,
	originalWidth,
	onResizeStart,
	onResize,
	onResizeStop,
}: {
	minWidth: number;
	originalWidth: number;
	onResizeStart?: OnStartCallback;
	onResize?: ResizeCallback;
	onResizeStop?: ResizeCallback;
}) => {
	const [isResizing, setIsResizing] = useState(false);
	const [width, setWidth] = useState(originalWidth);
	const [original, setOriginal] = useState({ width: originalWidth, x: 0 });

	const onMouseDown = useCallback(
		(e: MouseEvent) => {
			setIsResizing(true);

			onResizeStart && onResizeStart(e);

			const clientX = e.clientX ?? 0;
			setOriginal({ width: originalWidth, x: clientX });
		},
		[onResizeStart, originalWidth],
	);

	const onMouseMove = useCallback(
		(e: MouseEvent) => {
			if (!isResizing) {
				return;
			}

			const { clientX } = e;
			const currentWidth = original.width;
			const newWidth = Math.max(currentWidth + (clientX - original.x), minWidth);

			if (currentWidth !== newWidth) {
				requestAnimationFrame(() => {
					setWidth(newWidth);

					const delta = newWidth - original.width;

					onResize && onResize(newWidth, delta, e);
				});
			}
		},
		[isResizing, minWidth, onResize, original.width, original.x],
	);

	const onMouseUp = useCallback(
		(e: MouseEvent) => {
			const delta = width - original.width;

			onResizeStop && onResizeStop(width, delta, e);

			setIsResizing(false);
		},
		[onResizeStop, original.width, width],
	);

	const bindEvents = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mouseup', onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mouseleave', onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousemove', onMouseMove);
	}, [onMouseMove, onMouseUp]);

	const unbindEvents = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mouseup', onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mouseleave', onMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('mousemove', onMouseMove);
	}, [onMouseMove, onMouseUp]);

	useEffect(() => {
		// Unbind events if stopped resizing
		if (!isResizing) {
			return () => {
				unbindEvents();
			};
		}
		// Bind events on resize start
		bindEvents();
		// Clean up events after component unmount
		return () => {
			unbindEvents();
		};
	}, [bindEvents, isResizing, unbindEvents]);

	// @ts-expect-error react vs native events
	return <ResizerWrapper onMouseDown={onMouseDown} />;
};

const resizerColor = token('color.text.brand', B300);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizerWrapper = styled.div({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	width: '3px!important',
	top: 0,
	bottom: 0,
	right: token('space.negative.025', '-2px'),
	cursor: 'col-resize',
	zIndex: 190,
	backgroundColor: resizerColor,
	opacity: 0,
	transition: 'opacity 250ms',
	userSelect: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '',
		width: '0px',
		height: '0px',
		borderStyle: 'solid',
		borderWidth: '5px 5px 5px 0',
		borderColor: `transparent ${resizerColor} transparent transparent`,
		display: 'block',
		position: 'absolute',
		left: token('space.negative.100', '-8px'),
		top: '50%',
		transform: 'rotate(0deg) translateY(-50%)',
		pointerEvents: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		content: '',
		width: '0px',
		height: '0px',
		borderStyle: 'solid',
		borderWidth: '5px 0 5px 5px',
		borderColor: `transparent transparent transparent ${resizerColor}`,
		display: 'block',
		position: 'absolute',
		right: token('space.negative.100', '-8px'),
		top: '50%',
		transform: 'rotate(0deg) translateY(-50%)',
		pointerEvents: 'none',
	},
	'&:hover': {
		opacity: 1,
	},
});
