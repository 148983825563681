import React, { memo, useRef, useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { token } from '@atlaskit/tokens';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewComments,
	useHasReachedViewCommentsLimit,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/comments-hooks';
import { useCurrentViewAri } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { AddCommentComponent } from '@atlassian/jira-polaris-common/src/ui/comments/add-comment/index.tsx';
import { CommentComponent } from '@atlassian/jira-polaris-common/src/ui/comments/comment/index.tsx';
import {
	useCanAddAndEditOwnViewComments,
	useCanEditAllViewComments,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { ViewEmptyComments } from './empty-state';
import { CommentsLimitReachedBanner } from './limit-error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ViewCommentStream = memo<Record<any, any>>(({ onDirty }) => {
	const [isEditMode, setEditMode] = useState(false);

	const comments = useCurrentViewComments();
	const { createViewComment, updateViewComment, deleteViewComment } = useViewActions();
	const viewAri = useCurrentViewAri();
	const canAddAndEditOwnViewComments = useCanAddAndEditOwnViewComments();
	const canEditAllViewComments = useCanEditAllViewComments();
	const hasReachedViewCommentsLimit = useHasReachedViewCommentsLimit();

	const streamRef = useRef<HTMLDivElement | null>(null);

	const scrollToBottom = useCallback(() => {
		defer(() => {
			if (streamRef.current != null) {
				streamRef.current.scrollTo(0, streamRef.current.scrollHeight);
			}
		});
	}, []);

	const onEnterEditMode = useCallback(() => {
		setEditMode(true);
		scrollToBottom();
	}, [scrollToBottom]);

	const onCloseEditMode = useCallback(() => {
		setEditMode(false);
	}, []);

	const onSave = useCallback(
		(comment: ADF) => {
			if (viewAri === undefined) {
				return Promise.resolve(false);
			}
			return createViewComment(viewAri, comment).then(() => true);
		},
		[createViewComment, viewAri],
	);

	const onUpdate = useCallback(
		(commentId: string, comment: ADF) => updateViewComment(commentId, comment),
		[updateViewComment],
	);

	const onDeleteRequested = useCallback(
		(commentId: string) => {
			deleteViewComment(commentId);
		},
		[deleteViewComment],
	);

	useEffect(() => {
		scrollToBottom();
	}, [scrollToBottom]);

	return (
		<CommentsContainer>
			{hasReachedViewCommentsLimit && (
				<CommentsLimitReachedBanner limit={hasReachedViewCommentsLimit} />
			)}
			{!hasReachedViewCommentsLimit && canAddAndEditOwnViewComments && (
				<CommentsActionsContainer>
					<AddCommentComponent
						onSave={onSave}
						onEnterEditMode={onEnterEditMode}
						onCloseEditMode={onCloseEditMode}
						onDirty={onDirty}
					/>
				</CommentsActionsContainer>
			)}
			{!comments?.length && !isEditMode ? (
				<ViewEmptyComments />
			) : (
				<CommentsStreamContainer ref={streamRef}>
					{comments?.map((comment) => (
						<ContextualAnalyticsData key={comment.id} attributes={{ commentId: comment.id }}>
							<CommentComponent
								comment={comment}
								onDirty={onDirty}
								reactionContainerId={viewAri}
								reactionCommentAri={comment.id}
								canAdd={canAddAndEditOwnViewComments}
								canEditOwn={canAddAndEditOwnViewComments}
								canEditAll={canEditAllViewComments}
								onDeleteRequested={onDeleteRequested}
								onCommentUpdateRequested={onUpdate}
							/>
						</ContextualAnalyticsData>
					))}
				</CommentsStreamContainer>
			)}
		</CommentsContainer>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	paddingTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsStreamContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentsActionsContainer = styled.div({
	flex: '0 0 auto',
	boxSizing: 'border-box',
});
